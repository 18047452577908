.empty-image-login {
  display: block;
  margin: 0 auto;
  height: auto;
  max-width: 100%;
}

.logo-text {
  text-align: center;
  color: #fff;
  font-family: "Open Sans Condensed", "Arial Narrow", "Arial", serif;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 4.2em;
  display: block;
  line-height: 1;
  position: relative;
  top: -2rem;
}

.logo-text small {
  font-size: 1rem;
  display: block;
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.5);
}

.logo-text strong,
b {
  font-weight: bold;
}

.server-down-logo-text {
  font-family: Lato,sans-serif;
  font-size: 36px;
  color: #E7E7E7;
  text-align: center;
  font-weight: 200;
}

.server-down-logo-text small{
  font-size: 16px;
  line-height: 19px;
}

.login-wrapper-black {
  background: black;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-wrapper {
  background: linear-gradient(45deg, black 0%, #45484d 100%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-wrapper p{
  text-align: center;
}
/* body {
  background: linear-gradient(229.19deg, #333539 0%, #202123 100%);
} */
