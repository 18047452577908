.weekly-latest-root {
  height: 100%;
  margin: 20px 0px 20px 0;
}

.card {
  flex-direction: row;
  background-color: black;
  padding: 0px;
}

.task-row {
  margin: 0px;
}

.table-container {
  display: flex;
  flex: 1;
  margin: 10px;
}

.activity-indicator {
  padding: 0px;
  background-color: #191b1d;
  margin-right: 1px;
  margin-left: 10px;
  height: 265px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.latest-activity-root-col {
  background-color: black;
  padding: 0px;
  margin: 0px;
}

.activity-indicator-link {
  height: 100%;
  width: inherit;
  display: flex;
  justify-content: center;
}
/* .activity-indicator-link div {
  color: white;
} */
.activity-indicator-cont {
  background-color: #191b1d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.activity-indicator-cont:hover {
  border-top: 1px solid greenyellow;
  cursor: pointer;
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 10;
}

.fa-filter {
  margin-bottom: 25px;
}
.checkmark {
  margin-left: -18px;
  margin-top: 10px;
}
.filter-menu{
  max-width: 320px;
}
.filter-menuinput.form-control {
  border: 1px solid;
  border-color: #242527;
}
.filter-menulabel {
  white-space: nowrap;
}
/* .test{
    max-height: none;
  overflow: hidden;
  overflow-y: auto;
}
.test .dropdown-menu.show {
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
} */
