.last-section{
    margin: 1.2rem 0.2rem 0.5rem 1.5rem;
    font-size: 14px;
    color: rgba(255, 255, 255, .8);
    font-weight: 300;
  }

  .last-section-values{
    margin: 0 0 0 0
  }
  .card-community{
      display: flex;
      vertical-align: middle;

  }
  /* .card-community-button button{
    width: 2px;
    height: 2px;
    margin:0 !important
} */
.details-table-row{
  /* border:none !important; */
  border-bottom: none !important;
  width: 50%;
  white-space: normal !important;
  overflow-wrap: anywhere;
}
.details-table-header{
  font-size: 15px;
  color: white;
  background-color: red
}
.details-table thead tr th{
  background: none;
  border: none;
  font-size: 12px;
  font-weight: 300;
}
.table-dark.table-striped tbody tr:last-child {
  border-bottom: none !important;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  /* background-color: rgba(0,0,0,.2); */
  background: none;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}
.details-score-red{
  border-left: 1px solid #D0021B !important
}
.details-score-orange{
  border-left: 1px solid #FF7A00 !important
}
.details-score-green{
  border-left: 1px solid #92af00 !important
}
.last-section-dropdown-undefined{
  margin:0rem 0.5rem 0 0;
  align-content: center  
}
.last-section-dropdown-red{
  margin:0rem 0.5rem 0 0;
  border-left: 2px solid #D0021B !important
}
.last-section-dropdown-orange{
  margin:0rem 0.5rem 0 0;
  border-left: 2px solid #FF7A00 !important
}
.last-section-dropdown-green{
  margin:0rem 0.5rem 0 0;
  border-left: 2px solid #92af00 !important
}
.section-rating button.btn-secondary {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.section-rating button.btn-secondary[disabled] {
  opacity: 1;
}
.notes {
  white-space: pre-line !important;
}