.not_found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.code_status {
  font-size: 8.17em;
  font-weight: 400;
}

.page {

}
.msg {

}

.dash_btn {

}
