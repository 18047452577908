.side-drawer {
    height: 100%;
    box-shadow: 0 10px 30px black;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 60%;
    z-index: 2021;
    overflow: auto;
  }
  
  .side-drawer.open { 
    /* transition: width 2s ease; */
    width: 100%;
  }