.empty-message-wrapper {
 width: 100%;
 height: calc(100vh - 20vh);
 display: flex;
  justify-content: center;
  align-items: center;
}
.empty-message-inner {
  text-align: center;
  opacity: 0.8;
}
.empty-image {
  height: 160px;
}
.no-data-heading {  
margin: 2rem 0 1rem 0;
font-size: 24px;
font-weight: 400;
letter-spacing: 0.5px;
}
.content {

}
