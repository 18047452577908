.table-wrapper-dark {
  min-height: 530px;
  position: relative;
  padding-bottom: 3rem;
  border-radius: 4px;
  background-color: #191b1d;
  width: 100%;
}


.text-overdue {
  display: none;
  color: rgb(206, 13, 13);
  margin-left: 0.2rem;
  letter-spacing: 0.2px;
}
.text-overdue {
  display: inline-block;
}

tr.highlight {
  background: rgb(40, 44, 47);
}

tr.important-row{
  background: rgba(113, 44, 43, 0.28);
}

.table tbody tr:hover td .data-actions svg {
  color: #80b602;
}
