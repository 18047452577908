.archivePhone button{
    color: #888 !important;
}
.archivePhone:hover button:hover{
    color: #80b602 !important;
    cursor: pointer;
}
/* .table tr td a, .table tr td .td-link {
    color: inherit;
  }
  .table tr:hover td a, .table tr:hover td .td-link {
    color: #80b602;
    cursor: pointer;
  } */
  .archiveCardTitle
  {display: flex;
  flex-direction: column;
  justify-content:center
}
.archiveEmail a{
    color: #888 !important;
}
.archiveEmail:hover a:hover{
    color: #80b602 !important;
    cursor: pointer;
}
.archiveDetailsCard{
    font-family: "Roboto", sans-serif !important;
    border: none !important;
    background: none !important;
    padding: 0 !important;
    opacity: 1 !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    text-transform: none !important;
    color: #888;
}