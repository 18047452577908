@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
.access_denied {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.support-msg{
margin-top: 2%;
  
}
.code_status {
  font-size: 8.17em;
  font-weight: 400;
}

.page {

}
.msg {

}

.dash_btn {

}

.empty-image-login {
  display: block;
  margin: 0 auto;
  height: auto;
  max-width: 100%;
}

.logo-text {
  text-align: center;
  color: #fff;
  font-family: "Open Sans Condensed", "Arial Narrow", "Arial", serif;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 4.2em;
  display: block;
  line-height: 1;
  position: relative;
  top: -2rem;
}

.logo-text small {
  font-size: 1rem;
  display: block;
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.5);
}

.logo-text strong,
b {
  font-weight: bold;
}

.server-down-logo-text {
  font-family: Lato,sans-serif;
  font-size: 36px;
  color: #E7E7E7;
  text-align: center;
  font-weight: 200;
}

.server-down-logo-text small{
  font-size: 16px;
  line-height: 19px;
}

.login-wrapper-black {
  background: black;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-wrapper {
  background: linear-gradient(45deg, black 0%, #45484d 100%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-wrapper p{
  text-align: center;
}
/* body {
  background: linear-gradient(229.19deg, #333539 0%, #202123 100%);
} */

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.search-bar input.form-control {
    background: none;
}
.search-bar {
  margin: 0;
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 0 0 0 0.5rem;
  height: 36px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.4);
  min-width: auto;
}
.search-global {
  width: 380px;
  height: 40px;
}
.search-bar input {
  border: none;
  padding-left: 0 !important;
  font-size: 14px;
  margin-left: 0.5rem;
  background: none !important;
}
.search-bar .input-group-append button.btn {
  background: none;
  border: none;
  opacity: 0.5;
  font-size: 16px;
  font-weight: 300;
}
.search-bar .input-group-append button.btn:active {
  color: #80b602; 
  background: none;
}
.search-bar .icon-search {
  font-size: 12px;
  width: 16px;
  height: 16px;
  opacity: 0.4;
}
.dropdown .btn-secondary {
  border: none;
  box-shadow: none;
  background: #454548;
  /* height: 32px; */
}
.dropdown .btn-secondary:hover {
  color: black;
  background-color: #80b602;
  border: none;
}

.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    padding: 0 0.5rem 0.5rem 0.5rem;
    opacity: 0.8;
}
.position-absolute {
    position: absolute;
    bottom: 0;
    right: 0;
}
.pagination-wrapper button {
    background: none;
    color: inherit;
    border: 1px solid #444;
    padding: 0.5rem;
}
.pagination-wrapper button:hover {
    opacity: 1;
    color: #80B602;
    background: rgba(0, 0, 0, .9);
    cursor: pointer;
    border-radius: 4px;
}
.pagination-wrapper span {
    margin: 0 0.2rem;
    display: inline-block;
    white-space: nowrap;
}
.pagination-wrapper input, .pagination-wrapper select {
    background:rgba(0, 0, 0, .9);
    border-radius: 4px;
    color: inherit;
    text-align: center;
    border: 1px solid #444;
    padding: 5px;
}
.pagination-wrapper select {
    max-width: 120px;
    align-self: flex-end;
    border-radius: 0;
    padding: 0.5rem 1rem 0.5rem 0.3rem;
}
.table-wrapper-dark {
  min-height: 530px;
  position: relative;
  padding-bottom: 3rem;
  border-radius: 4px;
  background-color: #191b1d;
  width: 100%;
}


.text-overdue {
  display: none;
  color: rgb(206, 13, 13);
  margin-left: 0.2rem;
  letter-spacing: 0.2px;
}
.text-overdue {
  display: inline-block;
}

tr.highlight {
  background: rgb(40, 44, 47);
}

tr.important-row{
  background: rgba(113, 44, 43, 0.28);
}

.table tbody tr:hover td .data-actions svg {
  color: #80b602;
}

.cursor-pointer{
    cursor: pointer;
  }

.dialog, .modal{
    background: rgba(93, 93, 93, 0.5);
    z-index: 2023;
}
.modal-link {
    color: #222 !important;
}
.modal-body {
    position: initial;
    padding: 0.5rem;
    opacity: 0.85;
}
.modal-content {
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.75);
    background: #191b1d;
    padding: 0.5rem;
}
.modal-body p {
    line-height: 1.5;
    opacity: 0.8;
}
.modal-header, .modal-footer{
    border-color: rgba(255, 255, 255, 0.1);
    border: none;
}
.modal-title {
    font-size: 1rem;
}
.modal-header {
    /* background: #292929; */
    padding-bottom: 0;
}
.modal-footer {
    padding-top: 0;
}
.modal-header .close {
    color: white;
}

.footer-hr{
    margin-top: 7px;
}

.permanent-delete-body{
    padding: 0px 12px 12px
}
.modal-98w {
    width: 98%;
    position: relative;
    max-width: none!important;
}
.data-list-col-card {
  display: flex;
}
.data-list-col {
  background-color: rgb(18, 19, 21);
  width: unset !important;
  height: unset !important;
  padding: 0.5rem !important;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}
.data-list-col .popover {
  width: unset;
  height: unset;
}

/* .data-list-col div:first-child{
    background-color: rgb(18, 19, 21);
    color: #80B602;
    margin: 0rem 0px 0.5rem 0;
  } */

.data-list-col div {
  padding: 0.1rem 0.5rem;
  font-size: 12px;
}

.all-community-card {
  display: flex;
}
.details-all-community {
  background-color: rgb(18, 19, 21);
  width: unset !important;
  height: unset !important;
  padding: 0.5rem !important;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}
.details-all-community .popover {
  width: unset;
  height: unset;
}

.details-all-community div {
  /* padding: 0.1rem 0.5rem; */
  font-size: 12px;
}
.caption {
  margin-top: 0px;
}
.PhoneButton{
  font-family: "Roboto", sans-serif !important;
  border: none !important;
  background: none !important;
  padding: 0 !important;
  opacity: 1 !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  text-transform: none !important

}
.icon-img{
  height: 18px;
  width: 18px;
}
.icon-unsubscribe {
  width: 20px;
  height: 20px;
  opacity: 0.5;
  margin-right: 0.5rem;
}

/* simple popup list styles*/

.toolbarSelect {
  /* width: 200px; */
  color: white;
  padding: 0;
}
.list-card {
  display: flex;
}
.details-list {
  background-color: rgb(18, 19, 21);
  width: unset !important;
  height: unset !important;
  padding: 0.5rem !important;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}
.details-list .popover {
  width: unset;
  height: unset;
}

/* .details-list div:first-child{
  background-color: rgb(18, 19, 21);
  color: #80B602;
  margin: 0rem 0px 0.5rem 0;
} */

.details-list div {
  padding: 0.1rem 0.5rem;
  font-size: 12px;
}

/* .list-first-item {
  padding: 0 !important;
  display: flex;
} */

/* selection list styles*/

.list-popover {
  position: relative;
  background: black;
  border: 1px solid #444;
  margin-top: 5px;
  /*   margin-top: 0.9rem;
  margin-left: 0.2rem;*/
  z-index: 9999;
}
.list-popover .popover-body {
  padding: 0;
}
.list-group {
  max-height: 200px;
  overflow: auto;
}

.list-group-padding .list-item{
  padding-left: 1.5rem !important;
}

.list-item {
  background: black;
  padding: 0.3rem 0.8rem;
  color: #d8d8d8;
  font-size: 0.9em;
}

.list-item-label{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  opacity: 0.5;
}

.list-group .list-item:hover {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #80b602;
  cursor: pointer;
}
.label-selected {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 0.4rem;
}
.label-selected h3 {
  margin-left: 0;
  margin-right: 0.5rem;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0.3rem;
  opacity: 0.8;
}
.label-selected svg {
  font-size: 16px;
  position: relative;
  align-self: flex-end;
}

/* = checkbox field
  ---------------------- ------------------ */
.form-check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.form-check-input {
  margin-top: 0;
}

.form-check-label {
  opacity: 1;
}

.form-check-label-text {
  opacity: 0.5;
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: inherit;
}

/* = add-email-field
  ---------------------- ------------------ */
.secondary-email-wrapper {
  display: flex;
  align-items: center;
  font-weight: 300;
  padding-bottom: 0.2rem;
}

.secondary-email-wrapper .btn-close {
  border: none;
  background: none;
  padding: 0 0.2rem;
}

.secondary-email-wrapper .green {
  color: #80b602;
}

.btn-close:focus,
.btn-close:hover,
.btn-close:active {
  background: #222;
  box-shadow: none;
  outline: none;
  border: transparent;
}

.add-email-container {
  display: flex;
  flex-direction: row;
}

/* .add-email-container button {
  color: #000 !important;
  background-color: #80b602 !important;
  border-color: #80b602;
}

.add-email-container button:disabled {
  opacity: 0.4 !important;
  background: #80b602 !important;
} */

/* = Date picker field
  ---------------------- ------------------ */
.react-calendar {
  background: #080808 !important;
  border-color: #444 !important;
  box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.8) !important;
}

.react-calendar button {
  color: inherit !important;
}

.react-calendar__tile:disabled,
.react-calendar__navigation button[disabled] {
  background-color: #0e0e0e !important;
  opacity: 0.4 !important;
}

.react-calendar__tile--active {
  background: #80b602 !important;
  color: black !important;
}

.date {
  display: block !important;
  height: calc(2rem + 2px);
  border: 1px solid !important;
  border-radius: 0.25rem !important;
  border-color: #242527 !important;
  background: #121415 !important;
  /* margin: 0rem 0.5rem 1rem 0 !important; */
  width: 100% !important;
}

.date-required {
  display: block !important;
  height: calc(2.25rem + 2px) !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  border-color: #3c3a3a !important;
  background: none !important;
  color: #999 !important;
  width: 100% !important;
}

.date-required .react-date-picker--enabled {
  height: 36px !important;
  border-left: 2px solid #80b602 !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.date-required .react-date-picker {
  border-left: 2px solid red;
}

.react-calendar .react-calendar__tile:enabled:hover,
.react-calendar .react-calendar__tile:enabled:focus,
.react-calendar .react-calendar__tile--hasActive {
  background-color: #94d300 !important;
  color: black !important;
}

.date-required .react-date-picker input {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  color: inherit !important;
  height: 32px !important;
  font-size: 13px !important;
}

.date-required .react-date-picker .react-date-picker__inputGroup__leadingZero {
  margin-right: 2px !important;
}

.date-required .react-date-picker input.react-date-picker__inputGroup__month,
.date-required .react-date-picker input.react-date-picker__inputGroup__day {
  width: 18px !important;
  text-align: center !important;
  padding: 0 !important;
}

.date-required .react-date-picker__inputGroup__year {
  width: 34px !important;
  text-align: center !important;
  padding: 0 !important;
}

.date-required .react-date-picker span {
  font-size: 13px !important;
}

.date .react-date-picker input {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  color: inherit !important;
  height: 32px !important;
  font-size: 13px !important;
}

.date .react-date-picker .react-date-picker__inputGroup__leadingZero {
  margin-right: 2px !important;
  font-size: 13px;
}

.date .react-date-picker input.react-date-picker__inputGroup__month,
.date .react-date-picker__inputGroup__day {
  width: 18px !important;
  text-align: center !important;
  padding: 0 !important;
}

.date .react-date-picker__inputGroup__year {
  width: 34px !important;
  text-align: center !important;
  padding: 0 !important;
}

.react-date-picker__calendar {
  width: 300px !important;
}

@media screen and (max-height: 675px) {
  .react-calendar {
    line-height: 0.4em !important;
  }
}

.react-date-picker__wrapper {
  border: none !important;
  margin-left: 0.4rem !important;
}

.react-calendar button.react-calendar__tile--active {
  color: black !important;
}

.react-date-picker--disabled {
  background-color: inherit !important;
  opacity: 0.5;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: rgb(27, 27, 27) !important;
}

/* = select field
  ---------------------- ------------------ */
option {
  color: inherit !important;
}

select:disabled {
  opacity: 0.7 !important;
  /* background-color: blue ; */
}

/* .filter-select-field {
  background-color: #303438 !important;
  color: inherit !important;
  min-width: 150px;
  max-width: fit-content;
  line-height: 1;
} */
select option:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #80b602;
}

div.css-w8afj7-Input {
  color: inherit;
}

/* = Time Area Field
  ---------------------- ------------------ */
.time {
  display: block;
  height: calc(2.25rem + 2px);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-color: #3c3a3a;
  background: none !important;
  color: #999;
  padding: 0 0.5rem;
  /* border-left-color: red;
    border-left-width: 2px; */
  width: 100%;
}

.time .react_times_button {
  background: none !important;
  height: 100%;
  /* margin-left:1px;  */
}

.time .time_picker_container {
  background: none !important;
  /* margin-left:1px;  */
  height: 100%;
}

.time .time_picker_container {
  background: none !important;
  /* margin-left:1px;  */
  height: 100%;
}

.time .preview_container {
  position: relative;
  height: 100%;
  top: 0;
  color: #999;
  line-height: inherit;
  font-size: 13px;
  padding: 0;
  padding-top: 12px;
  padding-left: 12px;
}

.time .modal_container {
  top: 17px;
}

.time input {
  color: #999;
  font-size: 9px;
  /* top: 100%; */
}

.time span {
  color: #999;
  top: 100%;
}

.time.is-invalid,
.was-validated .time:invalid {
  border: 1px solid #dc3545 !important;
}

.invalid-time-feedback {
  white-space: nowrap;
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

/* = Score select field
  ---------------------- ------------------ */
/* .form-group.score-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
} */
.form-group select#score-select {
  padding: 0px;
  width: 64px;
  height: 28px;
  font-size: 12px;
  color: #bbb;
}

.rating-red {
  border-left: 3px solid red;
}

.rating-orange {
  border-left: 3px solid orange;
}

.rating-green {
  border-left: 3px solid green;
}

.rating-dropdown {
  border-radius: 4px;
  background: #121415;
}

.rating-dropdown a:hover {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
  cursor: pointer;
}

.rating-dropdown button {
  background: none !important;
  border-radius: 0 !important;
}

.rating-dropdown .dropdown-menu {
  min-width: 80px;
}

.dropdown-menu[aria-labelledby="filter-score-select"] {
  min-width: 80px;
}

.filter-rating-dropdown {
  background: #121415 !important;
  border-right: 1px solid #242527;
  border-top: 1px solid #242527;
  border-bottom: 1px solid #242527;
  border-radius: 4px;
}

.filter-rating-dropdown .btn-secondary {
  border: none;
  box-shadow: none;
  background: #121415 !important;
}

.filter-rating-dropdown .btn-secondary:hover {
  color: #80b602;
}

.time-toggle {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.time-dropdown-item {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-bottom: 1px solid #242527;
}

.time-dropdown-menu {
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  width: 80px !important;
  min-width: 80px !important;
}

.secondary-email-box {
  min-height: 80px;
  overflow-y: scroll;
  padding-top: 0.5em;
}

#associated_emails-form-group .secondary-email-box {
  min-height: 180px;
}

/* .time option {
  display: flex;
  justify-content: center;
  align-content: center;
  vertical-align: middle;
  border-bottom: 1px solid #242527;
}
.time.select::-ms-expand {
  display: none;
  -webkit-appearance: none; /*Removes default chrome and safari style*/
/* -moz-appearance: none; */
/* } */
/* .select::-ms-expand { */
/* display: none; */
/* -webkit-appearance: none; Removes default chrome and safari style */
/* -moz-appearance: none; */
/* } */
.position-fix .dropdown-menu {
  -webkit-transform: translate(0, 30px) !important;
          transform: translate(0, 30px) !important;
}
.backdrop{
    position: fixed;
    width:100%;
    height:100%;
    left:0;
    top:0;
    background: rgba(93, 93, 93, 0.5);
    z-index: 2020;
}
.backdrop-dark .loader-gif svg {
    width: auto !important;
    height: 48px !important;
}
.side-drawer {
    height: 100%;
    box-shadow: 0 10px 30px black;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 60%;
    z-index: 2021;
    overflow: auto;
  }
  
  .side-drawer.open { 
    /* transition: width 2s ease; */
    width: 100%;
  }
input[type="file"] {
  position: relative;
}
input[type="file"]::before {
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 16px;
  height: 20px;
  width: 20px;
  content: "";
  margin-top: 3px;
  color: #222;
}
input[type="file"]::after {
  margin-top: 5px;
  position: absolute;
  pointer-events: none;
  top: 11px;
  left: 30px;
  color: #222;
  font-weight: 600;
  content: "Upload File";
}
input[type="file"]::file-selector-button {
  width: 136px;
  height: 40px;
  margin-top: 5px;
  margin-right: 16px;
  padding: 0 16px;
  border-radius: 4px;
  color: transparent;
  border-color:#80b602;
  background-color: #80b602;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 200ms;
}
input[type="file"]::file-selector-button:hover {
  background-color: rgb(179, 255, 2);
}
input[type="file"]::file-selector-button:active {
  background-color: rgb(179, 255, 2);
}
.error-file-name {
  margin-top: 2px;
  margin-left: 8px;
  color: red;
  line-height: 17px;
}
.caption {
    font-size: 11px;
    text-transform: uppercase;
    opacity: 0.5;
    letter-spacing: 0.5px;
    display: inline-block;
}

  
.DateRangePicker.DateRangePicker_1 ,
.DateRangePickerInput.DateRangePickerInput_1,
.DateInput.DateInput_1,
.DateInput_input.DateInput_input_1,
.DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 {
  height: 24px;
  background-color: #000000;
  font-size: 15px;
  font-weight: 400;
  color: #dedede;
  border: none;
}

.DateRangePicker.DateRangePicker_1 {
  left: -10px;
} 

.DateInput.DateInput_1 {
  width: 7.265rem;
}

.DayPicker.DayPicker_1 {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.DateRangePickerInput_arrow_svg,
.DateRangePickerInput_clearDates_svg,
.DayPickerNavigation_svg__horizontal {
  fill: #dedede;
}

.DayPicker_weekHeaders,
.DayPicker_weekHeader,
.DayPicker_focusRegion,
.DayPickerNavigation,
.DayPickerNavigation_button.DayPickerNavigation_button_1,
.DayPicker_transitionContainer,
.CalendarMonthGrid.CalendarMonthGrid_1,
.CalendarMonth.CalendarMonth_1,
.CalendarMonth_caption.CalendarMonth_caption_1,
.CalendarDay.CalendarDay_1 {
  border: none;
  font-size: 14px ;
  background-color: #000000;
  color: #dedede;
}

.CalendarDay__today {
  border: none !important;
  color: #dedede !important;
  background-color: rgb(95, 136, 0, 0.5) !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__selected, 
.CalendarDay__selected:hover, 
.CalendarDay__selected_span, 
.CalendarDay__selected_span:hover,
.CalendarDay__default:hover,
.CalendarDay.CalendarDay_1:hover {
  border: none !important;
  color: #dedede !important;
  background-color: #80b602 !important;
}

.CalendarDay__selected, 
.CalendarDay__selected:hover {
  background-color: #8eca00 !important;
}


.date-range-select-options {
  background: black;
  font-size: small; 
  padding-left: 0.5em; 
  padding-bottom: 0.75em;
  padding-top: 0.75em;
  border-top: 2px #dedede solid;
}

.date-range-select-options p {
  display: inline;
}

.date-range-select-options > div:first-child {
  margin-bottom: 2px;
}

.date-range-select-options a {
  color: #dedede;
  padding: 0.5rem !important;
}

.date-range-select-options a:hover {
  cursor: pointer !important;
  text-decoration: underline !important;
  color: #80b602 !important;
}
.align_Select {
  align-items: flex-end;
}
.home-nav {
  white-space: nowrap;
  color: #80b602;
  font-weight: bold;
  font-size: 20px;
  margin: 0px 20px !important;
}

.page-container {
  background-color: black;
  color: white;
  height: auto
}

.main-con {
  margin-left: 0px;
  margin-right: 0px;
}

.home-panel {
  border-bottom: 1px solid #191b1d;
}
.root-row {
  padding: 0.6rem 0;
  margin-left: 0;
  margin-right: 0px;
}

.row-container {
  margin-left: 0;
  margin-right: 0;
}

.tasks-container {
  height: 50%;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
}
#bootstrap-overrides div {
  margin-right: 0px; /* priority score, yay! */
}


.btn-container {
  display: flex;
  flex :1 1;
  justify-content:flex-end;
}

button.btn-back {
  width: 34px;
    height: 32px;
    /* text-align: center; */
    /* display: flex; */
    background: rgba(255, 255, 255, .2) !important;
    padding: 0 !important;
    border-radius: 50px;
    margin-right: 0.5rem !important;
    border: none !important;
    box-shadow: none !important;
    margin-left: 0 !important;
}
button.btn-back svg {
  margin: 0 0 0 -2px !important;
}
.select-bar-back-btn{
  border-radius: 50%;
}
.activity-root {
  height: 100%;
  margin: 0.6rem 0px 0.6rem 0;
}
.filter-item button{
  background-color: black;
  line-height: 1;
  font-size: 0.7rem; 
  margin-left: -15px;
  text-align: left;
}
.table-upcoming-tasks td:first-child {
  width: 36px;
  padding-right: 0;
}
.table-upcoming-tasks td:nth-child(2) {
  width: 24px;
  padding-right: 0;
  padding-left: 0;
}
.table-upcoming-tasks th:nth-child(3),
.table-upcoming-tasks td:nth-child(3) {
  padding-left: 0.3rem;
}
.table-upcoming-tasks td:nth-child(5) {
  width: 36px;
}
.table-upcoming-tasks td:nth-child(6) {
  width: 260px;
}

.react-calendar__tile--now {
  background: #80b602;
}

.toggle-button {
  margin-bottom: 0.6rem;
}
.activity{
    /* padding: 0rem 3rem 1rem 3rem; */
    height: 100% ;
    /* overflow: scroll */
  }

   .appointment-button-active {
    background:none !important;
    margin: 0rem 0.5rem 1rem 0rem;
    color: rgb(201, 201, 201) !important;
    border-left:none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom:1px #80B602 solid!important;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
  }
  
   .appointment-button-active svg {
     background:none !important;
     color: rgb(201, 201, 201) !important;  
    }
   .appointment-button {
    background:none !important;
    margin: 0rem 0.5rem 1rem 0rem;
    color:  rgb(105, 105, 105) !important;
    border: none !important
  }
   .appointment-button svg {
     background:none !important;
     color: rgb(105, 105, 105) !important;
   }
.root-row {
  padding: 0.6rem 0;
  border-bottom: 2px solid #191b1d;
  margin-left: 0;
  margin-right: 0px;
}

.row-container {
  margin-left: 0;
  margin-right: 0;
}
.notes-list-row {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}
.past-activity-dropdown {
  margin: 0rem 0.5rem 0 0;
}

.communities-collection{
  display:flex;
  float:right;
  padding-right: 3rem;
  padding-top: 2px;
}
.popover-group-events-calendar {
  padding: 1rem;
  width: 400px;
}
@media screen and (max-width: 480px) {
.popover-group-events-calendar {
  max-width: 300px;
}
}
.root-row {
  padding: 0.6rem 0;
  border-bottom: 2px solid #191b1d;
  margin-left: 0;
  margin-right: 0px;
}

.row-container {
  margin-left: 0;
  margin-right: 0;
}
.card {
  border-color: transparent;
}
.note-row {
  padding: 0.6rem 0;
  margin-left: 0;
  margin-right: 0px;
}
.details-card {
  border-color: transparent;
  /* width: 40%;
    font-size: 10px;*/
  position: initial;
  z-index: 20;
}
.details-card h3 {
  margin-left: 0;
}
.root {
  height: 100%;
}
.edit-button {
  margin: 2px 2px 2px 2px;
  display: flex;
  justify-content: flex-end;
}
.card-section {
  margin: 1.2rem 0.2rem 1rem 0.7rem;
  /* font-size: 20px; */
}
.card-title {
  margin: 1.2rem 0.2rem 0rem 1.5rem;
  font-size: 25px;
}
.icon-btn.btn-icon {
  margin: 0rem 0rem 0rem 0.5rem;
  padding: 0 !important;
  border: none;
  margin-top: -2px !important;
  border-radius: 20px;
  cursor: pointer;
  background: rgba(0, 0, 05, 0.3);
  width: 16px;
  height: 16px;
  line-height: 12px;
  text-align: center;
  padding-top: 1px !important;
}
.icon-btn.btn-icon:hover {
  width: 16px !important;
  height: 16px !important;
}
.icon-btn.btn-icon:hover svg {
  /* color: black !important; */
  position: relative;
  top: -1px;
}
.icon-btn.btn-icon svg {
  margin: 0 !important;
}
.detail-view-side {
  height: 100%;
  /* margin: 0.6rem 0px 1rem 0.5rem; */
  /* z-index: 20; */
}
.details-community {
  background-color: #191b1d;
  width: unset !important;
  height: unset !important;
  padding: 0.5rem !important;
}
.details-community ul li:first-child {
  color: #80b602;
  margin: 0;
}
.details-community ul {
  margin-left: 1.2rem;
  margin-top: 0;
  margin-bottom: 0;
}
.details-community ul li {
  padding: 0.2rem;
  color: white;
  font-size: 12px;
  list-style: circle;
  padding-left: 0;
  padding-right: 0.5rem;
}

.past-activities-wrapper .caption {
  /* opacity: 0.6;
  color: inherit; */
  white-space: nowrap;
}
.past-activities-wrapper {
  max-height: 480px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 1rem;
  padding-right: 0;
  /* background: #222; */
  border-radius: 4px;
  margin-bottom: 0;
}
.details-side-responsive table tr th:first-child,
.details-side-responsive table tr td:first-child {
  padding-left: 0 !important;
}
/* = Advanced Filters
--------------------------------------- */
.filters-advanced .score-label {
  flex-direction: column !important;
}
.filters-advanced label {
  white-space: nowrap !important;
}
.filter-flag label {
  opacity: 1;
}

.csm-option {
  color: white !important;
}

.csm-optiongrp{
  background-color:#191b1d
}
.popover-group-events-calendar {
  padding: 1rem;
  width: 400px;
}
.details-form-check-label{
  color: #888 !important;
  letter-spacing: 0.2px;
  opacity: inherit;
}
@media screen and (max-width: 480px) {
.popover-group-events-calendar {
  max-width: 300px;
}
}

.email-message div table{
  width: -moz-fit-content !important
}

.loader-backdrop {
  min-height: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.loader-backdrop div {
  min-height: inherit;
  display: flex;
  justify-content: center;
  height: inherit;
}
.loader-backdrop div svg {
  position: relative;
  top: 40%;
  height: 80px;
  width: 80px;
}
.table-root {
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
}
.table-root table {
  margin-bottom: 0;
}
.table-root table tr td {
  padding: 0.5rem !important;
  border: none;
  line-height: 1.3;
  /* vertical-align: top; */
}
.table-row {
  line-height: 0.7rem;
}
.table-col {
  padding: 0.3rem !important;
  min-width: 7rem;
}
.table-activities tr td {
  border-top: 1px solid #272727 !important;
}
/* .table-activities table tr td {
  color: inherit;
} */
.list-header {
    font-size: 1.1rem;
    font-weight: bold;
}

.list-body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 10px;
}

.list-title {
    padding-left: 10px;
    font-size: 1.1em;
    font-weight: 500;
}

.list-text {
    padding-left: 1em;
    font-size: 1.1em;
}

.chip-body {
  display: inline-block;
  padding: 0 25px;
  margin: 0.5em;
  height: 30px;
  font-size: 11px;
  line-height: 30px;
  border-radius: 5px;
  background-color: #191b1d;
}

.chip-body:hover {
    background-color: #800000;
    cursor: pointer;
}

.chip-error-body {
    display: inline-block;
    padding: 0 0.5rem;
    height: 30px;
    font-size: 11px;
    line-height: 30px;
    width: 250px;
    text-align: left;
    border-radius: 5px;
    background-color: #800000;
  }

.chip-body .chip-text-align {
    display: flex;
    flex-direction: row;
    width: 250px;
}

.chip-title {
    margin-right: 1em;
    color: white;
}

.chip-subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
}
.schedule-card {
    margin-bottom: 1rem;
    min-height: 100px;
}
.schedule-card .table-root {
    overflow: visible;
    overflow-x: visible;
}
.schedule-card .table-root tr td {
    border: 1px solid #222 !important;
    vertical-align: top;
    padding: 0.1rem !important;
    white-space: normal;
}
.profile-img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.chip-body .chip-text-align {
    width: auto;
}
.schedule-card .table-root tr td:first-child {
    width: 20%;
    padding: 0.4rem !important;
}
.schedule-card .table-root tr td:last-child {
    text-align: left !important;
    width: 80%;
}
.schedule-card .chip-body {
    padding: 0.5rem;
    margin: 0.1rem 0;
    width: 99.6%;
    border-radius: 2px;
}
.chip-error-body {
    width: 99.6%;
    margin-top: 0.1rem;
}
.chip-body {
    height: auto;
    font-size: 11px;
    line-height: 1.1rem;
    margin: 3.5px !important;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.chip-body:hover {
    background-color: #80B602;
    cursor: pointer;
}

.chip-body:hover .chip-title {
    color: black;
    font-weight: bold;
}
.chip-title {
    margin-right: 1em;
    color: #f5f5f5;
    display: block;
    min-width: 110px;
    font-size: 11px;
}
.chip-subtitle {
    overflow: visible;
}

.title-container {
    justify-content: space-between;
    align-items: center;
}

.chip-body-selected { 
    background-color: #80B602;
}

.chip-body-selected .chip-title {
    color: black;
    font-weight: bold;
}

.chip-body-disabled {
    display: inline-block;
    padding: 0 25px;
    margin: 0.5em;
    height: 30px;
    font-size: 11px;
    line-height: 30px;
    border-radius: 5px;
    width: 100%;
    opacity: 0.7;
    background-color: #191b1d;
}

.chip-body-disabled .chip-text-align {
    display: flex;
    flex-direction: row;
    width: 250px;
}

.profile-img-container {
    margin-bottom: 10px;
    margin-left: 10px;
}
.rbc-toolbar-custom {
  justify-content: space-between;
  display: flex;
  margin: 1rem 0 0.8rem 0;
  flex-wrap: wrap;
}
.calendar-header-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: rgba(255, 255, 255, .75);
  flex-basis: 100%;
}
.calendar-header-controls .dropdown-toggle, .calendar-header-controls .section-today {
  margin-right: 0.5rem;
}
.calendar-month {
  position: absolute;
  margin-left: 42%;
}
.calendar-header-controls p {
  margin: 0 !important;
  margin-right: 0.5rem !important;
  /* width: 157px; 
  text-align: right;*/
  font-size: 13px;
  opacity: 0.75;
}
.section-today {
  padding: 0.1rem 0;
  border-radius: 4px;
  white-space: nowrap;
}
.arrowLeft, .arrowRight {
  border: none !important;
  width: auto !important;
  padding: 0 !important;
  background: none !important;
}
.arrowLeft svg, .arrowRight svg {
  font-size: 12px !important;
}
.nav-btn {
  border: none !important;
  font-size: x-large;
  color: #DEDEDE;
  background: none;
  outline: none;
  cursor: pointer;
}

.calendar-controls {
  position: absolute;
  right: 0px;
}
.rbc-off-range-bg {
  background: #151617;
}
.rbc-day-bg + .rbc-day-bg,
.rbc-header + .rbc-header,
.rbc-time-header-content,
.rbc-time-content > * + * > *,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #191b1d;
}

.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #191b1d;
  margin-right: 7px !important;
}
.rbc-month-row + .rbc-month-row,
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #191b1d;
}
.rbc-month-view,
.rbc-time-view,
.rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid #191b1d;
}
.rbc-agenda-view table.rbc-agenda-table {
  table-layout: fixed;
}
.rbc-agenda-view table.rbc-agenda-table tr > td:first-child,
.rbc-agenda-view table.rbc-agenda-table tr > th:first-child,
.rbc-agenda-view table.rbc-agenda-table tr > th:nth-child(2),
.rbc-agenda-view table.rbc-agenda-table tr > td:nth-child(2) {
  width: 120px !important;
}
.rbc-header,
.rbc-timeslot-group,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td,
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  border-bottom: 1px solid #2e2c2c;
  padding: 0.5rem 0.5rem 0.4rem 0.5rem;
  width: auto !important;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr {
  background: none !important;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  border-bottom: 2px solid #2e2c2c;
  color: #bbb;
  border: 1px solid #2e2c2c;
  
}
.rbc-show-more {
  color: inherit !important;
}
.rbc-time-content {
  border-top: 2px solid #191b1d;
}
.rbc-time-content,
.rbc-agenda-content {
  font-size: 12px;
  color: rgba(255, 255, 255, .75);
}
.rbc-event {
  padding: 4px 0 4px 0;
  background-color: rgb(102, 154, 152);
  font-size: 12px;
  color: white;
  border-radius: 0;
  margin-left: 1px;
  width: 99.8%;
}
.rbc-today {
  background-color: transparent;
}
.rbc-date-cell > a{
  color: inherit !important;
  text-decoration: none !important;
}
.rbc-date-cell > a:hover{
  color: #80b602 !important; 
}
.rbc-date-cell.rbc-now a,
.rbc-today a {
  color: #80B602 !important;
}
.rbc-date-cell.rbc-now a:hover,
.rbc-today a:hover {
  color: black;
}
.rbc-date-cell.rbc-now{
  color: #80B602
}
.rbc-date-cell {
  text-align: center;
  padding-top: 5px;
  font-size: 12px;
}
.rbc-month-header {
  display: flex;
  flex-direction: row;
  height: 2rem;
  line-height: 2rem;
}
.rbc-header {
  font-weight: 200;
  font-size: 90%;
  color: rgba(255, 255, 255, .4);
}
.rbc-overlay {
  border: none;
  background-color: #24272a;
  max-height: 280px;
  overflow-y: scroll;
  max-width:85%;
  z-index: 100;
}
.rbc-overlay-header {
  border-bottom: 1px solid #565555;
  padding: 0.5rem;
  font-size: 12px;
}
.rbc-event.rbc-selected {
  background-color: #80b602;
  outline: none;
}
.rbc-event:focus {
  outline: none;
}
.rbc-event-label {
  font-size: 90%;
}
.rbc-day-slot .rbc-event {
  border: 1px solid black;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
}
.rbc-show-more {
  background-color: transparent;
  font-weight: 300;
  line-height: normal;
  margin-left: 0.2rem;
  color: #666;
}
.calendar-legend {
  color: #666;
  font-size: 12px;
  margin-top: 5px;
}
#legend-appointments {
  margin-right: 1rem;
}
#legend-followups {
  margin-right: 1rem;
}
#legend-appointments i,
#legend-realtor-appointment i,
#legend-followups i,
#legend-realtor-followups i,
#legend-phonecall i,
#legend-email i,
#legend-massemail i,
#legend-ebomb i,
#legend-text i,
#legend-webform i,
#legend-walkins i {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 2px;
  margin-left: 10px;
}
#legend-appointments i { background: #92af00; }
#legend-realtor-appointment i {background:#d4ba2a;}
#legend-followups i { background: #4c7ef9; }
#legend-realtor-followups i { background: #A84264; }
#legend-phonecall i { background: #50E3C2; }
#legend-text i { background: #C48E48; }
#legend-email i { background: #FF7A00; }
#legend-massemail i { background: #9400ff; }
#legend-ebomb i { background: #D0021B; }
#legend-webform i { background: #FF5E5E;}
#legend-walkins i { background: #a84264;}

.popover{
  /* width: 30%; 
   height: 40%; */
   max-width: none;
}
.popover-body{
  height: 100%;
  padding: 0 !important;
  color: inherit;
}
.popover .arrow{
  display: none
}
.calendar-popover-button{
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-align: left;
  padding: 0;
  font-size: 10px;
  height: 15px;
  font-weight: 400 !important;
  letter-spacing: 0.3px;
}
.rbc-month-view, .rbc-time-content, .rbc-agenda-view {
  height: 530px;
  flex: 0 0 auto;
}
.rbc-agenda-view {
  flex: none;
}
.rbc-agenda-empty {
  display: flex;
    background: #111;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #666;
}
.rbc-time-content, .rbc-agenda-view {
  max-height: 530px;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
}
.rbc-header {
  padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rbc-agenda-view > .rbc-agenda-table {
  margin-left: 1px;
}
.rbc-agenda-view .rbc-header {
  display: table-cell;
  padding: 0.5rem !important;
}
.wrapper-content {
  background: none !important;
}
.email-message div{
  color: inherit !important;
}
.email-content .email-message * , .email-message * font, .email-message * div{
  /* color: #000 !important; */
  word-break: break-word;
  /* text-decoration: none;
  font-size: inherit !important;
  background: #212426; */
}
.gmail_quote * {
  background: none !important;
}
/* .email-content .email-message * .gmail_quote * div {
  color: #ddd !important;
} */
.email-message * p {
  margin-bottom: 1rem;
}
.email-message * hr {
  background: #444;
}
.email-message [style*="border"] {
  border-color: #444 !important;
  padding-top: 0.4rem !important;
}
.email-message * b {
  text-transform: uppercase !important;
  font-weight: normal !important;
  opacity: 0.5 !important;
  min-width: 60px !important;
  display: inline-block !important;
}
.email-message * a {
  color: #b9b9b9 !important;
  word-break: break-all;
}
.popover_Title {
   border-bottom: 1px solid #444;
    padding-bottom: 0.3rem;
    margin-bottom: 0.6rem;
}
.popover_Title strong {
  font-weight: 400;
}
/* = Event Details Popover 
----------------------------------- */
.event-details-popover {
  background: #191b1d;
  max-width: 400px ;
}
.group-events-calendar button{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 350px !important;
  border-radius: 0 !important;
  background: #222 !important;
}
.event-details-popover .popover-body {
  min-width: 300px;
}
.event-details-popover h2 {
  font-weight: 400;
  font-size: 22px;
  text-transform: capitalize;
}
.event-details-popover .popover_Title_small {
  /* opacity: 0.5; 
  margin-bottom: 0.2rem; 
  text-transform: uppercase; */
  font-size: 12px;
  display: flex;
  align-items: center;
}
.event-details-popover .popover_Title_small svg {
  margin-right: 0.3rem;
}
.popover-body-row{
  width: 100%;
  height: 100px;
  margin-right: 0; 
  margin-left: 0;
}

.popover-icon-btn{
  margin: 0rem 0rem 0rem 0.5rem;
  padding:0 !important;
  border: none !important;
  box-shadow: none !important
}
.popover-icon-btn svg{
  margin: 0 !important;
  color: rgb(167, 167, 167);
}
.popover-details-title-row{
  margin: 1rem 0rem 0rem 1.7rem;
  color: rgb(167, 167, 167);
}
.popover-details-value-row{
  margin: 0rem 0rem 1rem 1.7rem;
  color: rgb(221, 221, 221);
}
.event-details-list li {
  margin: 0.2rem 0;
  display: flex;
}
.event-details-list li label {
  opacity: 0.4;
  text-transform: uppercase;
  font-size: 11px;
  display: block;
  margin: 0;
  min-width: 94px;
  display: inline-block;
}
.event-details-list li > span, .event-details-list li a {
  display: inline-block;
  font-size: 12px;
  max-width: 500px;
  max-height: 250px;
  overflow: auto;
  opacity: 0.9;
  word-break: break-word;
}
.event-details-list li a {
  color: #80b602;
}
.event-details-list li > span svg {
  opacity: 0.4;
  margin-right: 0.2rem;
  margin-top: -2px;
}
.group-events-calendar {
  margin-bottom: 2px !important;
}
.group-events-calendar .dropdown-menu {
  padding: 0;
}

.email-preview-btn{
  display: flex;
    flex-direction: row-reverse;
}

.email-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.email-data-table{
  padding: 1rem 0;
}

.email-content *{
  /* background-color: white;
  padding: 1rem; */
  color: inherit !important;
}

.relative-position{
  position: relative;
}

.email-title{
  position: relative;
  padding-right: 2em; 
  font-size: 15px;
}

.email-title-small{
  font-size: 12px;
  display: flex;
  align-items: center;
}

.modal-dialog.modal-email-event {
  min-width: 300px;
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}
.modal-dialog.modal-email-event .modal-content
{
  padding: 0;
  background: 0;
  box-shadow: none;
  border: none;
}
.modal-dialog.modal-email-event .modal-body {
  padding: 0;
}
.dialog-email-details {
  width: auto;
  max-width: 96.5vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #191b1d;
  margin: 0 1vw 1vh 0;
  padding: 1rem;
  border-radius: 6px;
  /* box-shadow: 0 0 10px black;
  border: 1px solid #333; */
}
@media screen and (max-width: 480px) {
  .event-details-popover {
    max-width: 300px !important;
  }
  .group-events-calendar button{
  max-width: 295px !important;
}
.dialog-email-details {
  width: auto;
  margin: 0;
  position: relative;
  left: -0.5rem;
}
}

.activity-card-inner {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  color: inherit;
  /* margin-right: 0.5rem; */
  position: relative;
  border-radius: 4px;
  border-top: 2px solid transparent;
  transition: all 0.2s ease-in-out;
}
.activity-card-root:hover {
  border-top: 2px solid greenyellow;
  cursor: pointer;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 10;
}
.activity-card-inner:hover .counter,
.activity-indicator-cont:hover .counter {
  opacity: 1;
}
.activity-card-root {
  background-color: #191b1d;
  margin-right: 0.5rem;
  border-radius: 4px;

}
.activity-card-root:last-child .activity-card-inner {
  margin-right: 0;
}
.activity-card-root-selected, .activity-card-selected {
  border-top: 2px solid greenyellow;
  cursor: pointer;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 10;
  background: none;
  border-radius: 4px;
}
.activity-card-inner:hover .counter {
  color: inherit;
}
.count {
  font-size: 24px;
  font-weight: 500;
  color: #999;
}
.activity-card-root-selected .counter {
  color: inherit;
}
.activity-card-body {
  margin: 0px;
  /*   width:100%;
  height: 171px;
  overflow-y: auto */
}
.activity-card-body .table-root table tr td:first-child {
  opacity: 0.5;
  text-align: right;
  vertical-align: top;
  width: 24px;
}
.view-all-button {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0.5rem;
  margin: 0;
}
.counter {
  font-size: 0.95rem; /* Before: 0.82rem */
  font-weight: 400;
  margin-top: 0.4rem;
  opacity: 0.8;
  text-align: center;
  color: #80b602;
}
.comparator {
  font-size: 0.82rem;
  font-weight: 400;
  margin-top: 0.4rem;
  /* margin-left: 0.5rem; */
  opacity: 0.8;
  /* text-align: left; */
  color: #80b602;
}
.date-activity {
  margin-top: 4px;
  color: #908f8f;
  font-size: 0.7rem;
}
.hide {
  margin-top: 4px;
  color: #908f8f;
  font-size: 0.7rem;
  visibility: hidden;
}
.activity-header-cont-props {
  margin: 0 2px 0 1px;
  background-color: #292d2f;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.5rem;
}

.activity-header-cont-lead {
  margin: 0 2px 0 0px;
  background-color: #181a1d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.2rem;
}
.activity-header-cont-lead h3 {
  font-size: 14px;
  opacity: 0.5;
}
.title-container {
  margin: 0px;
  display: flex;
}

.date-container {
  margin: 0px;
  flex: 10 1;
  margin-right: 40px;
  align-items: flex-start;
}
.date-header {
  margin: 10px;
  color: inherit;
  opacity: 0.5;
  font-size: 12px;
  margin-top: 15px;
}
.card-web-form .card-inner {
  /* text-align: left;
  display: flex;
  align-items: flex-start !important;
  padding-left: 1rem; */
}
.btn.custom-btn.btn-small-secondary {
  padding: 0.2rem 0.5rem;
  font-size: 10px;
  letter-spacing: 0.5px;
  background: #111;
  border: none;
  color: #666;
}

.card-help {
  right: 0.5rem !important;
  top: 0.5rem;
  opacity: 0.3;
  font-size: 16px;
}

@media (max-width: 480px) {
  .comparator {
    padding-bottom: 0.5rem;
  }
}

.selectWrap {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectWrap > span {
  display: none;
}
.col-padding {
  padding: 0;
}
.row-padding {
  padding: 0;
}
.sb-navbar-top {
  border-bottom: 1px solid #191b1d;
  box-shadow: none;
  padding: 0;
  justify-content: space-between;
  /* z-index: 9000 !important; */
}
.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 37px);
  /* overflow-x: hidden;
  overflow-y: auto; */
}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 37px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  border-right: 1px solid #191b1d;
}

.align_Select {
  align-items: flex-end;
}

.home-nav {
  white-space: nowrap;
  color: #80b602;
  font-weight: bold;
  font-size: 20px;
  margin: 0px 20px !important;
}

.page-container {
  background-color: black;
  color: white;
  height: auto;
}

/* = Navbar Branding 
------------------------------- */
.navbar-brand {
  /* color: #80b602; */
  font-weight: 800;
}

.navbar-brand:hover,
.navbar-brand:active,
.navbar-brand:visited {
  opacity: 1;
  color: #94d300 !important;
}

.navbar-light span {
  color: #94d300;
}

.navbar-brand {
  border-right: 1px solid #191b1d;
  text-align: center;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.navbar-brand img {
  max-width: 80px;
  width: 100%;
}

/* = Sidebar 
---------------------------- */
.sb-navbar-user-profile li {
  border-left: 1px solid #191b1d;
}

.sb-navbar-user-profile li button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.1rem 1rem;
}

.sb-navbar-user-profile {
  /* position: absolute;
  right: 0;
  top: 0.1rem;
  height: 100%;
  line-height: 2rem; */
  display: flex;
  align-items: center;
}

.sb-navbar-user-profile svg {
  font-size: 1.1rem;
  opacity: 0.5;
}

[role="main"] {
  padding-top: 10px;
  height: 100%;
}

.custom-navitem {
  /*border-bottom: 1px solid #191b1d;	
   background-color: #191b1d;	
  box-shadow: inset 0 0 13px 0 #000000; */
  width: 100%;
}

.custom-navitem .nav-link {
  color: #b9b9b9;
}

.nav-link.active .text-muted {
  color: inherit !important;
}

.custom-navitem .nav-link.active {
  position: relative;
}

.custom-navitem .nav-link:hover,
.custom-navitem .nav-link.active:hover,
.custom-navitem .nav-link.active {
  color: #80b602;
  background: #191b1d;
}

.custom-navitem .nav-link:hover {
  background: none;
}

.custom-navitem .nav-link:hover svg {
  color: #80b602;
}

/* .custom-navitem .nav-link.active::after {
  content: ' ';
  display: block;
  position: absolute;
  right: -24px;
  top: 25%;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid transparent;
  border-left: 12px solid #191b1d;
  z-index: 9999;
} */
.main-con {
  margin-left: 0px;
  margin-right: 0px;
}

.home-panel {
  border-bottom: 1px solid #191b1d;
}

.sb-navbar-user-profile li {
  border-left: 1px solid #191b1d;
}

.sb-navbar-user-profile li button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.1rem 1rem;
}

.sb-navbar-user-profile {
  /* position: absolute;
  right: 0;
  top: 0.1rem; 
  height: 100%; 
  line-height: 2rem; */
}

.sb-navbar-user-profile svg {
  font-size: 1.1rem;
  opacity: 0.5;
}

[role="main"] {
  padding-top: 10px;
  height: 100%;
}

.form_SearchBar {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #323437;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.form_SearchBar .icon-search {
  width: 22px;
  height: 22px;
}

.form_SearchBar input {
  font-size: 18px;
  font-weight: 300 !important;
}

.view-as-warning {
  text-align: center;
  width: 100%;
  background-color: rgb(184, 119, 0);
  opacity: 0.9;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 2px;
}

.view-as-warning span {
  color: white;
  font-weight: 200;
  font-size: large;
  margin-left: 2px;
}

.view-as-btn {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.version {
  display: inline-block;
  font-size: 12px;
}

.custom-control label {
  opacity: 1 !important;
}

.custom-control-label::before {
  background-color: #4e4e4e;
  border: none;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #80b602;
}

.notification-icon {
  color: white;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.notification-icon svg {
  margin-right: 5px;
}

.notification-icon:hover {
  color: #94d300;
}

.notification-icon .badge {
  position: absolute;
  top: -10px;
  right: -7px;
  padding: 3px 6px;
  border-radius: 50%;
  background: #a90a0a;
  color: white;
}

.notification-drawer {
  width: 30% !important;
}

.notification-drawer .notification-bar-container {
  height: 100%;
  overflow-y: auto;
}

.notification-drawer .notification-bar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
  min-height: 90px;
  background: #111315;
  padding: 0.5rem 1.1rem 0 1.1rem;
  border-bottom: 1px solid #232427;
}

.notification-drawer .notification-bar-sub {
  display: flex;
  justify-content: center;
  padding: 10px 10px 10px 0;
  color: rgb(150, 150, 150);
  font-size: 12px;
  z-index: 10;
  cursor: pointer;
}

.notification-drawer .notification-bar-sub:hover {
  color: rgb(150, 150, 150);
  background-color: #222224;
}

.notification-drawer .notification-bar-subtext {
  color: rgb(150, 150, 150);
  font-size: 12px;
  margin: 0 0.5rem 0 0;
}

.notification-drawer .notification-bar-button {
  color: #94D300;
  font-size: 12px;
  margin: 0 0.5rem 0 0;
}

.notification-drawer .notification-bar-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.notification-drawer .notification-close-btn {
  display: none;
}

.notification {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  margin: 10px 10px 10px 5px;
}

.new-notification {
  border-left: 5px solid #94D300;
}

.notification:hover {
  background-color: #222224;
}

.notification span {
  /* first 3 lines might not work on IE */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: start;
  color: #cdcdcd;
  max-width: 90%;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.notification svg {
  margin-right: 0.5rem;
  color: #94d300;
}

.notification img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.notification .notification-img {
  margin: auto 10px auto 0;
}

.notification .notification-desc {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-items: center;
  line-height: 1.1rem;
  width: 100%;
}

.notification .notification-date {
  display: block;
  margin-top: 0.5rem;
  font-size: 10px;
  letter-spacing: 0.5px;
  color: #b7b4b4 !important;
}

@media screen and (max-width: 768px) {
  .notification-drawer .notification-close-btn {
    display: inline-block;
    cursor: pointer;
  }

  .notification .notification-date {
    margin-right: auto;
  }
}
.weekly-latest-root {
  height: 100%;
  margin: 20px 0px 20px 0;
}

.card {
  flex-direction: row;
  background-color: black;
  padding: 0px;
}

.task-row {
  margin: 0px;
}

.table-container {
  display: flex;
  flex: 1 1;
  margin: 10px;
}

.activity-indicator {
  padding: 0px;
  background-color: #191b1d;
  margin-right: 1px;
  margin-left: 10px;
  height: 265px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.latest-activity-root-col {
  background-color: black;
  padding: 0px;
  margin: 0px;
}

.activity-indicator-link {
  height: 100%;
  width: inherit;
  display: flex;
  justify-content: center;
}
/* .activity-indicator-link div {
  color: white;
} */
.activity-indicator-cont {
  background-color: #191b1d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.activity-indicator-cont:hover {
  border-top: 1px solid greenyellow;
  cursor: pointer;
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 10;
}

.fa-filter {
  margin-bottom: 25px;
}
.checkmark {
  margin-left: -18px;
  margin-top: 10px;
}
.filter-menu{
  max-width: 320px;
}
.filter-menuinput.form-control {
  border: 1px solid;
  border-color: #242527;
}
.filter-menulabel {
  white-space: nowrap;
}
/* .test{
    max-height: none;
  overflow: hidden;
  overflow-y: auto;
}
.test .dropdown-menu.show {
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
} */

.last-section{
    margin: 1.2rem 0.2rem 0.5rem 1.5rem;
    font-size: 14px;
    color: rgba(255, 255, 255, .8);
    font-weight: 300;
  }

  .last-section-values{
    margin: 0 0 0 0
  }
  .card-community{
      display: flex;
      vertical-align: middle;

  }
  /* .card-community-button button{
    width: 2px;
    height: 2px;
    margin:0 !important
} */
.details-table-row{
  /* border:none !important; */
  border-bottom: none !important;
  width: 50%;
  white-space: normal !important;
  overflow-wrap: anywhere;
}
.details-table-header{
  font-size: 15px;
  color: white;
  background-color: red
}
.details-table thead tr th{
  background: none;
  border: none;
  font-size: 12px;
  font-weight: 300;
}
.table-dark.table-striped tbody tr:last-child {
  border-bottom: none !important;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  /* background-color: rgba(0,0,0,.2); */
  background: none;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}
.details-score-red{
  border-left: 1px solid #D0021B !important
}
.details-score-orange{
  border-left: 1px solid #FF7A00 !important
}
.details-score-green{
  border-left: 1px solid #92af00 !important
}
.last-section-dropdown-undefined{
  margin:0rem 0.5rem 0 0;
  align-content: center  
}
.last-section-dropdown-red{
  margin:0rem 0.5rem 0 0;
  border-left: 2px solid #D0021B !important
}
.last-section-dropdown-orange{
  margin:0rem 0.5rem 0 0;
  border-left: 2px solid #FF7A00 !important
}
.last-section-dropdown-green{
  margin:0rem 0.5rem 0 0;
  border-left: 2px solid #92af00 !important
}
.section-rating button.btn-secondary {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.section-rating button.btn-secondary[disabled] {
  opacity: 1;
}
.notes {
  white-space: pre-line !important;
}
.root-row {
  padding: 0.6rem 0;
  border-bottom: 2px solid #191b1d;
  margin-left: 0;
  margin-right: 0px;
}

.row-container {
  margin-left: 0;
  margin-right: 0;
}

.tasks-container {
  height: 50%;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
}
#bootstrap-overrides div {
  margin-right: 0px; /* priority score, yay! */
}

button.btn-back {
  width: 34px;
    height: 32px;
    /* text-align: center; */
    /* display: flex; */
    background: rgba(255, 255, 255, .2) !important;
    padding: 0 !important;
    border-radius: 50px;
    margin-right: 0.5rem !important;
    border: none !important;
    box-shadow: none !important;
    margin-left: 0 !important;
}
button.btn-back svg {
  margin: 0 0 0 -2px !important;
  font-size: 14px;
}
.select-bar-back-btn{
  border-radius: 50%;
}
.table-prospects td:first-child {
  width: 36px;
  padding-right: 0;
}
.table-prospects tr td:nth-child(2) {
  width: 24px;
  padding-left: 0;
  padding-right: 0;
}
.table-prospects tr th:nth-child(3),
.table-prospects tr td:nth-child(3) {
  padding-left: 0.3;
}

.flag-icon {
  color: inherit;
}

.red-flag-icon {
  color: red;
}

.flag-btn {
  background-color: #19191b;
  border: none;
}

.form_TitleBig h2 {
  font-size: 20px;
  color: inherit;
  margin: 0;
}
.form_Title {
  color: rgb(150, 150, 150);
  font-size: 12px;
}
.form_body {
  padding: 1rem 3rem 2rem 3rem;
  height: 100%;
}

.padding-less {
  padding-left: 0px;
  padding-right: 0px;
}
.my-options {
  color: grey;
  background: #dcdcdc;
  opacity: 0.2;
}
.form-community {
  margin: 0 0 1rem 0;
  padding: 0 1rem 0 1rem;
  background: #101215;
  border-radius: 6px;
  position: relative;
  border: 1px solid #232427;
}
.form_deleteButtonRow {
  justify-content: flex-end;
}
.form_deleteButtonRow button.btn-close.btn.btn-primary {
  background: #333;
  padding: 0.3rem 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.7rem;
}
.form-horizontal div.form_TitleContainer {
  position: -webkit-sticky; 
  position: sticky;
  top: 0;
}

.detail-partition{
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #232427;
}

.detail-background{
  background-color: black;
}
.filter-select-fix {
  width: 150px;
}
.form-sub-heading {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  margin-bottom: 0.8rem;
}

.is-invalid {
  background-image: none;
}
.invalid-feedback {
  display: contents;
}
.filter-flag .form-control {
  font-size: 13px;
  padding: 0.5rem;
  line-height: 1.3;
}
.filter-flag-controls .btn {
  margin-left: 0.5rem;
}
.filter-flag-controls .btn:first-child {
  margin-left: 0;
}

.form {
  /* overflow: scroll */
}
.form_body {
  padding: 0rem 1.2rem 1rem 1.2rem;
  height: 100%;
  /* overflow: scroll */
}
.form_TitleContainer {
  min-height: 90px;
  background: #111315;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10;
  padding: 0;
  border-bottom: 1px solid #232427;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
}
.form_TitleBig h2 {
  font-size: 20px;
  color: inherit;
}
.form_TitleBig label {
  color: inherit;
}
.form_Title {
  color: rgb(150, 150, 150);
  font-size: 12px;
  margin: 0;
}
.form_RequiredInput {
  /* border-left: 3px solid red !important; */
  /* background: black */
  /* color:black */
}
/* .form_RequiredInput::before{
  display: block;
  content: '';
  height: 36px;
  width: 3px;
  background-color: red;
  left: -12px;
  position: relative;
  border-radius: 50px;
} */
.form_CellRequiredInput {
  width: 200px;
  /* border-left: 3px solid red !important; */
  /* background:black */
}
.form_CellInput {
  width: 200px;
  /* background:black */
}
.form_BiggerInput {
  height: 120px;
  /* background:black */
}
.form_MediumInput {
  height: 90px;
  /* background:black */
}

.form_addCommunityButton {
  width: auto !important
}
.form_ranking {
  background: "black";
}

.form-submit-button {
  color: #000 !important;
  background: #80b602 !important;
  border-color: #80b602 !important;
  padding: 0.4rem 0.8rem !important;

  border-radius: 0.25rem !important;
}

.lead-to-prospect-form {
  height: inherit;
}
.filters-my-saved button {
  border: 1px solid #242527 !important;
  background: #121415 !important;
}

.form_SubmitButton[disabled] {
  background: #80b602 !important;
  border-color: transparent !important;
}
.filter-error{
  border: solid;
  border-width: thin;
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.2)
}
.filter-error-text{
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  color: red;
  opacity: unset;
}

  
.form_body {
  padding: 0rem 1.2rem 1rem 1.2rem;
  height: 100%;
}
.form_TitleContainer {
  min-height: 90px;
  background: #111315;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10;
  padding: 0;
  border-bottom: 1px solid #232427;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
}
.form_TitleBig h2 {
  font-size: 20px;
  color: inherit;
}
.form_TitleBig label {
  color: inherit;
}
.form_Title {
  color: rgb(150, 150, 150);
  font-size: 12px;
  margin: 0;
}
.form_addCommunityButton {
  width: auto !important
}
.filters-my-saved button {
  border: 1px solid #242527 !important;
  background: #121415 !important;
}
.form_SubmitButton[disabled] {
  background: #80b602 !important;
  border-color: transparent !important;
}
.filter-error {
  border: solid;
  border-width: thin;
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.2)
}
.filter-error-text {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  color: red;
  opacity: unset;
}

.realtor-community-sales tr td:first-child {
    width: 36px;
}
.realtor-community-sales tr td:nth-child(2) {
    width: 200px;
}
.realtor-community-sales tr td:last-child, .realtor-community-sales tr th:last-child {
    width: 120px;
    text-align: center;
    padding-right: 3rem !important;
}
.customization-header {
  margin: 0;
  background-color: #181a1d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.2rem;
}
.customization-header h3 {
  font-size: 14px;
  opacity: 0.5;
}

.other-customization-card-root {
  padding: 1rem;
  background-color: #191b1d;
  margin-top: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.add-other-input-wrapper {
  display: flex;
  margin-top: inherit;
}
.add-other-input-wrapper input {
  opacity: 0.5;
  background: black !important;
  border-color: #3c3a3a;
  margin-right: 0.5rem;
}

.add-other-input-wrapper button svg {
  margin: 0px;
}
.add-other-input-wrapper button:disabled {
  opacity: 0.6 !important;
}

.customizations-option-box {
  min-height: 80px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid #3c3a3a;
  border-radius: 4px;
}
.cutomization-text li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cutomization-text li p {
  margin-bottom: 0;
}
.box-height {
  min-height: 80px;
}
.customizations-option-box .options-group {
  max-height: 180px;
  min-height: 180px;
  overflow: auto;
}
.options-item {
  background: inherit;
  padding: 0.3rem;
  color: #d8d8d8;
  font-size: 0.9em;
}
.selected {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #80b602;
}
.options-item:hover {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #80b602;
  cursor: pointer;
}

.custom-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.custom-btn-arrows {
  font-size: 12px;
}
.custom-btn-arrows svg {
  width: 12px;
  height: 12px;
  margin: 0px;
}
.custom-btn-arrows:disabled {
  background-color: #191b1d !important;
}
.custom-btn-arrows:disabled svg {
  color: white !important;
}
.left-right-arrows button svg {
  margin: 0;
  padding: 0;
}
.left-right-arrows button.btn.custom-btn:hover {
  border: none !important;
}

@media screen and (max-width: 768px) {
  .row {
    justify-content: center;
  }
}
/* delete requests styles*/

.tabs {
  border: none;
}
.delete-request-root .nav .nav-item {
  background: none !important;
  border: none;
  color: #838383;
  padding-right: 0;
  padding-left: 0;
  margin-left: 1rem;
  margin-right: 1rem;

}
.delete-request-root .nav .nav-item.active {
  background: none !important;
  border: none;
  border-bottom: 1px #80b602 solid !important;
  color: #80b602
}

/*update section styles*/

.custom-btn-arrows {
  font-size: 12px;
}
.custom-btn-arrows svg {
  width: 12px;
  height: 12px;
  margin: 0px;
}
.left-right-arrows button svg {
  margin: 0;
  padding: 0;
}
.custom-btn-arrows:disabled {
  background-color: #191b1d !important;
}
.custom-btn-arrows:disabled svg {
  color: white !important;
}
.left-right-arrows button.btn.custom-btn:hover {
  border: none !important;
}

/*more customization section styles*/

/* .more-customizations-wrapper {
  height: 65px;
} */
/* .more-customizations-wrapper label {
  font-size: 12px;
}*/
.more-customizations-wrapper div {
  display: flex;
}
.more-customizations-wrapper button {
  border: none !important;
  background: none !important;
  padding: 0.2rem 0.5rem;
} 
.more-customizations-wrapper button svg {
  color: #80b602;
}
/* Email template styling */

.overlay-emailtemplate .icon_backdrop {
  max-height: inherit;
  position: relative;
  right: 9rem;
}
.overlay-emailtemplate .message_backdrop {
  font-size: 1rem;
}

.template-editor {
  margin: -1rem !important;
}

.cutomization-text button{
  margin-right: 5px; 
}

.cutomization-text button svg{
  margin: 0px
}
/* .custom-btn svg {
  padding: 0;
  margin: 0 !important; 
} */
.webform-email-association-controls {
  margin-left: 3rem;
}
.webform-email-association-controls .btn.btn-secondary {
  background: none !important;
  padding: 0.2rem !important;
  color: #666 !important;
  border: none!important;
}
.webform-email-association-controls .btn.btn-secondary:hover {
  color: #80b602 !important;
}
#admin-email-associations-root  .custom-btn svg {
  margin: 0;
}
.list-item {
  padding: 0.1rem 0.5rem !important;
}
.webform-status-selection label {
  opacity: 1;
  font-size: 12px;
  margin-bottom: 0;
  cursor: pointer;
}

.customization-box {
  margin: 1em 0;
  background-color: #181a1d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1.5rem;
}
.table-compact {
  margin: 1.5rem 0 2rem 0;
}
.table-compact .table-root table thead tr td {
  background: none;
  border-bottom: 2px solid #333;
  padding: 0.5rem 0.2rem;
  border-top: none !important;
  opacity: 0.5;
}
.table-compact .table-root table tr td  {
  padding: 0.2rem !important;
  padding-bottom: 0.5rem !important;
  vertical-align: middle !important;
}
.customization-box .label-selected {
  border: 1px solid #444;
    border-radius: 4px;
    padding: 0.3rem 0.5rem;
    background: black;
}
.customization-box .label-selected h3 {
  font-size: 12px;
    margin: 0 0.5rem 0 0;
    color: #999;
    opacity: 1;
}
.osc-selector {
  opacity: 0.7;
}
.list-popover .list-item {
  padding: 0.2rem 0.5rem;
}
.auto-reply-customization .other-customization-card-root {
  padding: 0;
}
/* .auto-reply-customization .other-customization-card-root #all-other-customization-col {
  width: 100% !important;
} */
.other-customization-card-root .options-item {
  border: none;
  border-radius: 0;
  padding: 0.3rem;
}

.scrollable-table {
  max-height: 200px;
  overflow: auto;
  margin-bottom: 0.5rem;
}

.custom-btn.sources-add-btn svg {
  margin-right: 0px;
}

.customization-box.customer-source-tag {
  padding: 1rem;
}

.category-header {
  font-size: 18px;
  line-height: 19px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 4px;
  margin: 27px 0px 17px 0px;
  border-bottom: 1.2px solid #73A405
}

.dropzone-container {
  border: 1px dashed #3C3A3A;
  border-radius: 5px;
  background-color: #121415;
}

.dropzone-container .content {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropzone-container .icon {
  margin: 20px 40px;
  width: 71px;
}

 .content .title{
  font-size: 16px;
  color: #908F8F;
  line-height: 19px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.add-btn {
  width: 170px;
  margin-bottom: 5px;
}

.sample-btn {
    width: 170px;
    margin-bottom: 15px;

}

.dropzone-container .sub {
  margin: 0px;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.3;
}

.action-container {
  display: flex;
  justify-content: flex-start;
  margin: 32px 0px;
}

.dropzone-container .selected-content {
  width: 224px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background: black;
  border: 1px solid #3E494E;
  border-radius: 4px;
}

.dropzone-container .selected-content .file-name {
  font-size: 14px;
  color: #80b602;
  line-height: 17px;
  margin-left: 8px;
  word-break: break-all;
}

.dropzone-container .selected-title {
  align-items: center;
}

.read-only-item {
  padding-top: 0.2rem;
}

@media screen and (max-width: 480px) {
  .tabs {
    display: block;
    border: none;
  }
}

.activity-history .accordion-title {
  background: #212121;
  border: 1px solid #3f4244;
}
.activity-history .accordion-body {
    border-color: #3f4244;
}
.activity-history-text p {
  line-height: 1.5;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.2px;
  margin-bottom: 1rem;
  word-break: break-word;
}
.table-webform td:first-child, .webform-others-table td:first-child {
    width: 36px;
    padding-right: 0;
  }
  .table-webform tr td:nth-child(2), .webform-others-table tr td:nth-child(2) {
    width: 24px;
    padding-left: 0;
    padding-right: 0;
  }
  .table-webform tr td:nth-child(2) svg, .webform-others-table tr td:nth-child(2) svg  {
      font-size: 9px;
  }
  .table-webform tr th:nth-child(3),
  .table-webform tr td:nth-child(3) {
    padding-left: 0.3;
  }
  .table-webform tr td:nth-child(6) {
    width: 280px;
  }
.webformDetailCard{
  font-size: 14px !important;
}
.webformDetailCard button{
  font-size: 14px !important;
  color: #dedede !important;
}
.webformDetailCard:hover button:hover{
  font-size: 14px !important;
  color: #80b602 !important;
  cursor: pointer;
}
.webformDetailCard a{
  color:#dedede !important;
}
.webformDetailCard:hover a:hover{
  color: #80b602 !important;
  cursor: pointer;
}
.togglebutton{
  opacity: 1;
  border: none !important;
}
.togglebutton.btn.btn-secondary:not(:disabled):not(.disabled).active{
  color: #222;
  background: #80b602;
  border-color: #80b602;
}
.dropdown_button {
  margin: 0 0 0 1rem;
}
.RichEditor-root {
  background: #efefef;
  padding: 3em 10em;
  font-family: inherit;
  font-size: 14px;
  word-break: break-word;
}

.RichEditor-editor-mass-email {
  display: flex;
  cursor: text;
  font-size: 14px;
  min-height: 250px;
  margin-top: 15px;
  word-break: break-word;
}

.RichEditor-editor {
  display: flex;
  cursor: text;
  font-size: 14px;
  min-height: 480px;
  max-width: none;
  margin-top: -5px;
}
.RichEditor-editor *,
.editor-sections-panel * {
  color: black;
}
.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls-container {
  min-height: 30px;
  position: relative;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
          user-select: none;
}
.RichEditor-controls .dropdown-menu.show {
  width: 100%;
}
.RichEditor-styleButton {
  color: #999;
  margin: 0rem 0rem 0rem 0.25rem !important;
  height: 20px !important;
  border-radius: 15% !important;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  width: 100% !important;
  background: none !important;
  text-align: start !important;
}

.RichEditor-activeButton {
  color: #5890ff;
}
.custom-popover {
  color: #999;
  background-color: #191b1d;
  width: 270px;
  height: 131px;
  padding: 0.5rem !important;
}

.text-preview-panel {
  display: flex;
}

.text-editor-panel {
  min-height: 95.2%;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
  /* padding: 10px; */
  background: white;
  color: #292d30;
  position: relative;
}

div.vertical-line {
  border-left: 1px solid #80b602;
  height: 20px;
  margin-right: 8px;
  margin-left: 8px;
}

.DraftEditor-root ol li,
.DraftEditor-root ul li {
  list-style: inherit;
  padding-left: 0.2rem;
  line-height: 1rem;
}

.DraftEditor-root ol,
.DraftEditor-root ul {
  padding-left: 1.5rem;
}
.DraftEditor-root ol {
  padding-left: 1.85rem;
}
.DraftEditor-root ul {
  list-style: inherit;
}

code {
  color: inherit !important;
}

.code-preview-panel {
  background: white;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.public-DraftEditor-content > div {
  line-height: 1.2rem;
}
.html-editor-switch {
  z-index: 10;
  position: relative;
  right: 0;
  /* margin-left: 1rem; */
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
}

.html-editor-switch button {
  border: none;
  padding: 0.5rem;
  border-radius: 50px;
  font-size: 18px;
  width: 32px;
  height: 32px;
  line-height: 0;
  text-align: center;
}
.html-editor-switch button svg {
  color: #666;
}
.rtd-wrapper {
  padding-top: 0.5rem;
}
.CodeMirror {
  height: 530px;
  margin-top: 2.5rem;
  border-top: 1px solid #eee;
}

.alignment--left .public-DraftStyleDefault-block {
  text-align: left;
}

.alignment--center .public-DraftStyleDefault-block {
  text-align: center;
}
.alignment--right {
  text-align: right;
}

.align-right div {
  text-align: right;
}
.align-center div {
  text-align: center;
}
.align-left div {
  text-align: left;
}
.override .test {
  /*   flex: unset;
  max-width: initial; */
}
.editor-sections-panel {
  padding: 10px;
  min-height: 620px;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
  background: white;
  /*  margin-left: 30px; */
}

.section-wrapper {
  height: inherit;
  border: 2px dashed rgb(209, 208, 208);
}

.section-wrapper:hover {
  border: 2px solid #80b602;
}
.RichEditor-controls-container .RichEditor-styleButton {
  background: none !important;
  text-align: center;
  line-height: 12px;
  /* color: #666; */
}
.email-templates .btn.btn-primary {
  background: none;
  border: none;
}
.email-templates .btn.btn-primary:hover,
.RichEditor-controls-container .RichEditor-styleButton:hover {
  color: #80b602;
}
.email-templates .btn.btn-primary:focus {
  color: #666;
  box-shadow: none;
}
.DraftEditor-root {
  padding: 0 1rem;
}
#logo-image,
#body-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
}
.email-templates .dialog {
  padding: 0 !important;
}
.email-templates .modal-lg.modal-dialog-centered {
  width: 100%;
  max-width: 100%;
  margin: 0;
}
.email-templates .modal-body * {
  color: inherit;
}
/* .email-templates .dialog .subject-section-preview {
  display: none !important;
} */
.modal-dialog.modal-email-preview {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}
.modal-dialog.modal-email-preview .modal-content
{
  padding: 0;
  background: 0;
  box-shadow: none;
  border: none;
}
.modal-dialog.modal-email-preview .modal-body {
  padding: 0;
}
.subject-section-preview p{
  line-height: 1;
} 
.email-templates .dialog {
  background: rgba(0, 0, 0, 0.7);
}
.email-templates .text-preview-wrapper {
  margin: 0 auto;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
}
#body-text,
#body-header,
#body-link {
  /* padding: 1rem; */
}
.email-templates .overlay-backdrop {
  background: rgba(0, 0, 0, 0.8) !important;
}
.email-templates .temp-controls button.btn-secondary {
  background: #d4d4d4;
  border: 1px solid #bbb;
  color: black;
}
.email-templates .temp-controls button.btn-secondary:hover {
  border: 1px solid #80b602;
  background: #80b602;
}
.email-templates .temp-controls button.btn-icon {
  background: rgba(0, 0, 0, 0.2);
  color: #666;
}
.email-templates .temp-controls button.btn-icon:hover {
  background: #80b602;
  color: black;
}
.email-templates .temp-controls button.btn-icon svg {
  font-size: 16px;
  color: black;
}
.email-templates .btn-group .btn.btn-secondary {
  border-color: rgba(255, 255, 255, 0.15);
}
.section-header {
  font-size: medium;
  color: #6c757d;
}

.selected-section {
  border: 2px solid #80b602;
}
.section-wrapper .subject p {
  margin: 0.4rem 0;
}

.error-section {
  border: 1px solid red;
}

.bullets-li {
  list-style: square !important;
}

.tooltip-description {
  text-align: left !important;
}
.active-allignment {
  background-color: black !important;
}
button.btn-tokens {
  background: orange !important;
  font-weight: bold;
  margin-left: 1rem;
  margin-right: 0.5rem;
}
button.btn-tokens:hover {
  color: black !important;
}
div.list-tokens a.dropdown-item {
  padding: 0.2rem 0.5rem;
}
div.list-tokens a.dropdown-item span {
  color: #ddd;
  white-space: normal;
  display: flex;
  align-items: center;
}
div.list-tokens a.dropdown-item:hover span {
  color: orange !important;
}
button.btn-tokens:focus {
  color: black !important;
}
.public-DraftEditor-content {  
  caret-color: black;
}

.overlay-backdrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  /*   height: 100%; 
  background: rgba(0, 0, 0, 0.6);*/
  z-index: 100;
}

.overlay-message {
  position: absolute;
  margin-top: 2rem;
}

.icon_backdrop {
  max-height: 120px;
}

.content-empty-empty {
  margin-top: 5rem;
}
.content-empty img {
  right: 0 !important;
}
.dropzone-container {
  border: 1px dashed #3C3A3A;
  border-radius: 5px;
  background-color: #121415;
}

.dropzone-container .content {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropzone-container .icon {
  margin: 20px 40px;
  width: 71px;
}

.dropzone-container .sub {
  margin: 0px;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.3;
}

.dropzone-container .selected-content {
  width: 300px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background: black;
  border: 1px solid #3E494E;
  border-radius: 4px;
}

.dropzone-container .selected-content .file-name {
  font-size: 14px;
  color: #80b602;
  line-height: 17px;
  margin-left: 8px;
  word-break: break-all;
}

.dropzone-container .selected-content .error-file-name {
  margin-top: 2px;
  margin-left: 8px;
  color: red;
  line-height: 17px;
}

.dropzone-container .selected-title {
  align-items: center;
}

.content .title{
  font-size: 16px;
  color: #908F8F;
  line-height: 19px;
}

.action-container {
  display: flex;
  justify-content: flex-start;
  margin: 32px 0px;
}
.accordion-title {
    background: #2b2b2d;
    padding: 0.6rem;
    letter-spacing: 0.5px;
    margin-top: 1px;
    color: inherit;
    border-left: 2px solid #2b2b2d;
    border-radius: unset !important;
    font-size: 12px;
    font-weight: 300;
}
.accordion-body {
    border: 1px solid #2b2b2d;
    border-top: none;
    padding: 0 1rem;
}
.accordion-body-inner {
    padding: 1rem 0;
}
.accordion-body-inner p {
    font-size: 12px;
    line-height: 1.3;
}
/* .accordion-body.collapse:before {
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    border-top: 1px solid #464a4c;
} */
.root-row {
  padding: 0.6rem 0;
  border-bottom: 2px solid #191b1d;
  margin-left: 0;
  margin-right: 0px;
}
.row-container {
  margin-left: 0;
  margin-right: 0;
}
.card{
  border-color: transparent;
}
.note-row{
  padding: 0.6rem 0;
  margin-left: 0;
  margin-right: 0px;
}
.details-card{
  border-color: transparent;
 /* width: 40%;
  font-size: 10px;
 */
  position: initial;
  z-index: 20;
}
.details-card h3 {
  margin-left: 0;
}
.root{
  height: 100%;
}
.edit-button{
  margin: 2px 2px 2px 2px ;
  display: flex;
  justify-content: flex-end;
}
.card-section{
  margin: 1.2rem 0.2rem 1rem 0.7rem;
 /* font-size: 20px;
  */
}
.card-title{
  margin: 1.2rem 0.2rem 0rem 1.5rem;
  font-size: 25px;
}
.icon-btn.btn-icon{
  margin: 0rem 0rem 0rem 0.5rem;
  padding: 0 !important;
  border: none;
  margin-top: -2px !important;
  border-radius: 20px;
  cursor: pointer;
  background: rgba(0,0,05,.3);
  width: 16px;
  height: 16px;
  line-height: 12px;
  text-align: center;
  padding-top: 1px !important;
}
.icon-btn.btn-icon:hover {
  width: 16px !important;
  height: 16px !important;
}
.icon-btn.btn-icon:hover svg {
 /* color: black !important;
  */
  position: relative;
  top: -1px;
}
.icon-btn.btn-icon svg{
  margin: 0 !important;
}
.detail-view-side{
  height: 100%;
 /* margin: 0.6rem 0px 1rem 0.5rem;
  */
 /* z-index: 20;
  */
}
.details-community{
  background-color: #191b1d;
  width: unset !important;
  height: unset !important;
  padding: 0.5rem !important 
}
.details-community ul li:first-child{
  color: #80B602;
  margin: 0 
}
.details-community ul {
  margin-left: 1.2rem;
  margin-top: 0;
  margin-bottom: 0;
}
.details-community ul li{
  padding: 0.2rem;
  color: white;
  font-size: 12px;
  list-style: circle;
  padding-left: 0;
  padding-right: 0.5rem;
}
.past-activities-wrapper .caption {
 /* opacity: 0.6;
  color: inherit;
  */
  white-space: nowrap;
}
.past-activities-wrapper {
  max-height: 480px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 1rem;
  padding-right: 0;
 /* background: #222;
  */
  border-radius: 4px;
  margin-bottom: 0;
}
.notes-card {
  margin-bottom: 0.5rem;
}
.noted-descr-body {
  padding: 1rem 0 0.5rem 0;
  font-size: 12px;
  margin-bottom: 0;
}
.noted-descr-body label {
  margin-bottom: 0;
}
.noted-descr-body > div:not(:last-child) {
  margin-bottom: 1rem;
  line-height: 1rem;
}
.noted-descr-body li {
  display: flex;
  color: #999;
  margin-bottom: 5px;
}
.noted-descr-body li label {
  margin-right: 0.5rem;
}
.noted-descr-body li span {
  color: #888;
}
.notes-list-row {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}
.btn-toolbar .btn.btn-icon {
  background: none;
  color: #80B602;
  padding: 0;
  margin: 0;
  height: 18px;
}
.btn-toolbar .btn.btn-icon svg {
  margin: 0;
}
.btn-toolbar .btn.btn-icon:hover {
  background: none;
  color: inherit;
  opacity: 1;
}
.btn-toolbar .btn.btn-icon:hover svg {
  top: 0;
}
.mass-emails-icn{
  margin-right: 0px !important;
  fill: "#9400ff";
  margin-left: -0.4rem;
}
.video-icon {
  margin-left: 10px;
}
.video-icon:hover {
  color: #80b602;
  cursor: pointer;
}
.notes {
  white-space: pre-line;
}
.attachment {
  flex-wrap: wrap;
  margin-bottom: 0px;
}
.attachment-name {
  cursor: pointer;
  margin: 0px 3px 5px 0px;
  padding: 5px 5px;
  border: 1px solid #3E494E;
  border-radius: 4px;
  color: #80b602;
  word-break: break-all;
}
.creator-name {
  font-style: italic;
  letter-spacing: 0.5px;
  text-align: right;
  opacity: 0.8;
  margin: 5px 2px 0px 0px;
}
.empty-message-wrapper {
 width: 100%;
 height: calc(100vh - 20vh);
 display: flex;
  justify-content: center;
  align-items: center;
}
.empty-message-inner {
  text-align: center;
  opacity: 0.8;
}
.empty-image {
  height: 160px;
}
.no-data-heading {  
margin: 2rem 0 1rem 0;
font-size: 24px;
font-weight: 400;
letter-spacing: 0.5px;
}
.content {

}

.root-row {
  padding: 0.6rem 0;
  border-bottom: 2px solid #191b1d;
  margin-left: 0;
  margin-right: 0px;
}
.row-container {
  margin-left: 0;
  margin-right: 0;
}
.notes-list-row {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}
.past-activity-dropdown {
  margin: 0rem 0.5rem 0 0;
}
.communities-collection{
  display:flex;
  float:right;
  padding-right: 3rem;
  padding-top: 2px;
}
.popover-group-events-calendar {
  padding: 1rem;
  width: 400px;
}
@media screen and (max-width: 480px) {
  .popover-group-events-calendar {
    max-width: 300px;
  }
}
.not_found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.code_status {
  font-size: 8.17em;
  font-weight: 400;
}

.page {

}
.msg {

}

.dash_btn {

}

.archivePhone button{
    color: #888 !important;
}
.archivePhone:hover button:hover{
    color: #80b602 !important;
    cursor: pointer;
}
/* .table tr td a, .table tr td .td-link {
    color: inherit;
  }
  .table tr:hover td a, .table tr:hover td .td-link {
    color: #80b602;
    cursor: pointer;
  } */
  .archiveCardTitle
  {display: flex;
  flex-direction: column;
  justify-content:center
}
.archiveEmail a{
    color: #888 !important;
}
.archiveEmail:hover a:hover{
    color: #80b602 !important;
    cursor: pointer;
}
.archiveDetailsCard{
    font-family: "Roboto", sans-serif !important;
    border: none !important;
    background: none !important;
    padding: 0 !important;
    opacity: 1 !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    text-transform: none !important;
    color: #888;
}
.flex-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.version-number {
    flex: 0.25 1
  }

.version-date {
    text-transform: uppercase;
    font-size: 12px;
}

.version-details {
    flex: 0.6 1;
}

.group-icon {
    margin-left: 0%;
    padding-right: 1em;
}
.list-header {
    margin-left: 0.1rem;
    font-weight: 400 !important;
}
.list-body {
    padding-top: 8px !important;
    align-items: flex-start;
}
.list-body .list-item {
    color: inherit;
    margin: 0;
    padding: 0 !important;
    line-height: 1.5rem;
}
.list-title {
    font-size: 0.8rem !important;
    font-weight: 500;
    min-width: 80px;
    display: flex;
    align-items: center;
}
.list-title span {
    opacity: 0.4;
}
.versions-wrapper .list-title {
    padding-left: 0;
}
.versions-wrapper {
    margin: 3rem 0 0 -1rem;
    border-bottom: 1px solid #222;
    padding-bottom: 3rem
}
.version-details .list-header {
    margin-top: 2rem;
  }
  .version-details .list-header:first-child {
      margin-top: 0;
  }
.version-title .caption {
    opacity: 0.6;
    font-size: 12px;
    text-transform: none !important;
    letter-spacing: 0;
    font-weight: 500;
}
.recoding-view {
    padding: 20px;
}

@media screen and (max-width: 480px) {
    .version-number {
        width: 100%;
    }
    .recoding-view {
        padding: 20px;
    }
}
.details {
    white-space: pre-wrap;
    padding: 1rem;
    background: #333;
    line-height: 1.2;
    font-size: 12px;
    border-left: 3px solid red;
    margin-top: 1rem;
    font-family: monospace;
}
h1 {
    color: white;
}
.nav-link {
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
/* .nav-link.active {
  background: #191b1d;
} */
.nav-item-direct {
  margin-left: 0px;
}
.nav-item-nested {
  margin-left:16px;
}
.nav-sub-items {
  display: flex;
  justify-items: center;
  align-items: center;
  font-size: 13px;
}
.nav-icon {
  padding-right: 1rem;
  font-size: 1rem;
  color: #838383;
}
.nav-icon svg {
  color: inherit;
  font-size: 16px;
}
.active .nav-icon svg {
  color: #80b602;
}
.webForms-count {
  background: #a90a0a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 0px 5px;
  font-size: 10px;
  font-weight: bold;
  height: 18px;
  min-width: 18px;
}

.notification .alert {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: inline-block;
    padding: 0.5rem;
    font-size: 14px;
    background-color: rgb(214, 214, 214) !important;
    color: black !important;
    border-color: #ddd !important;
    z-index: 99999;
}

.notification .alert p {
    margin: 0.5rem;
    padding-right: 2rem;
    color: black !important;
}
.top-caption {
    font-size: 12px;
    opacity: 0.7;
    letter-spacing: 0.5px;
    text-align: right;
    margin-bottom: 1rem;
  }

.header-table {
    margin: 1rem 0 0.5rem 0; 
    background: rgb(20, 20, 20);
    padding: 0.5rem;
    border-radius: 0.2rem;
    color: inherit;
    letter-spacing: 0.5px;
}
.header-table svg {
    font-size: 14px;
}

.header-icon {
    margin-right: 10px;
    opacity: 0.8 !important;
    color: #94d300
}

.header-text {
    opacity: 0.7;
}
.results-table-search .table-wrapper-dark {
    background: none;
    min-height: auto;
    padding-bottom: 1rem;
}
.results-table-search table tbody td,
.results-table-search table thead th {
    background: black;
    border-color: #1f1f28;
    padding: 0.5rem;
}
.results-table-search table thead th {
    border-bottom: 2px solid #2b2b32;
}
.results-table-search table tbody tr:hover td {
    background: rgb(40, 44, 47);
}
.sb-navbar-user-profile svg {
    opacity: 1;
}
.results-table-search td span {
    color: inherit;
}
.results-table-search table tr td:nth-child(1) {
    padding: 0 0 0 0.5em;
    width: 23px;
}
.results-table-search table td:nth-child(2) {
    width: 25%;
}
.results-table-search table td:nth-child(3) {
    width: 20%;
}
.results-table-search table td:nth-child(4) {
    width: 20%;
}
.results-table-search table tbody td a {
    word-break: break-all;
    white-space: normal;
}
.results-table-search .data-actions button.btn.custom-btn{
    color: #222 !important;
    background: #80b602;
    border-color: #80b602;
    font-weight: 600;
    width: auto;
    padding: 0.25rem 0.5rem !important;
}
.results-table-search .data-actions button.btn.custom-btn:hover {
    background: #80b602 !important;
    border-color: #80b602 !important;
    color: #222 !important;
}
.warning-icon {
    margin-right: 5px;
    opacity: 0.5;
}
.warning-container {
    display: flex;
    color: #94d300;
    line-height: 1.2;
    max-width: 50%;
    position: relative;
}
.warning-container svg {
    position: absolute;
    top: -3px;
}
.warning-container p {
    margin-left: 1.5rem;
}
.dialog-merge {
    margin: 0 -10px;
    display: flex;
}
.dialog-merge .details-card.card {
    flex-direction: column;
    background: none;
    border: 1px solid #444;
    border-radius: 6px;
    margin: 0 10px;
}
.dialog-merge .card-header {
    padding: 1rem;
    font-size: 20px;
    border-bottom: 1px solid #444;
    display: flex;
}
.dialog-merge .card-header svg
{
    margin-right: 0.5rem;
    color: #80B602;
}
.dialog-merge .details-table thead tr th
{
    padding: 0 !important;
    font-size: 14px;
    color: white;
    font-weight: 400;
    margin-bottom: 1rem;
    padding: 0.5rem 0 0.8rem 0 !important;
    position: relative;
}
.dialog-merge .details-table td {
    border-bottom: 1px solid rgb(37, 37, 37) !important;
    padding: 0.5rem 0 !important;
    font-size: 12px !important;
}
.merge-form {
    width: 800px;
}
.merge-form .form-horizontal {
    background: black;
    border-radius: 8px;
    border: 1px solid #444;
    height: 100%;
}
.merge-form .form_TitleContainer {
    min-height: auto;
    background: none;
    border-bottom: 1px solid #444;
    position: relative !important;
    padding: 0.55rem 1rem;
    flex-direction: row;
    justify-content: space-between;
}
.merge-form .form_TitleContainer > div {
    margin: 0 !important;
}
.merge-form .form_TitleContainer h2 svg {
    font-size: 28px;
    color: #80B602;
    margin-right: 0.5rem;
}
.merge-form .card {
    border-width: 1px !important;
    background: #121415 !important;
}
.merge-form .form-group {
    outline: none;
}
.action-buttons {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 20px;
    right: 20px;
}
.dialog-merge .details-card {
    width: 350px;
}
.dialog-merge {
  margin: 0 -10px;
  display: flex;
}
.dialog-merge .details-card.card {
  flex-direction: column;
  background: none;
  border: 1px solid #444;
  border-radius: 6px;
  margin: 0 10px;
}
.dialog-merge .card-header {
  padding: 1rem;
  font-size: 20px;
  border-bottom: 1px solid #444;
  display: flex;
}
.dialog-merge .card-header svg
{
  margin-right: 0.5rem;
  color: #80B602;
}
.dialog-merge .details-table thead tr th
{
  padding: 0 !important;
  font-size: 14px;
  color: white;
  font-weight: 400;
  margin-bottom: 1rem;
  padding: 0.5rem 0 0.8rem 0 !important;
  position: relative;
}
.dialog-merge .details-table td {
  border-bottom: 1px solid rgb(37, 37, 37) !important;
  padding: 0.5rem 0 !important;
  font-size: 12px !important;
}
.merge-form {
  width: 800px;
}
.merge-form .form-horizontal {
  background: black;
  border-radius: 8px;
  border: 1px solid #444;
  height: 100%;
}
.merge-form .form_TitleContainer {
  min-height: auto;
  background: none;
  border-bottom: 1px solid #444;
  position: relative !important;
  padding: 0.55rem 1rem;
  flex-direction: row;
  justify-content: space-between;
}
.merge-form .form_TitleContainer > div {
  margin: 0 !important;
}
.merge-form .form_TitleContainer h2 svg {
  font-size: 28px;
  color: #80B602;
  margin-right: 0.5rem;
}
.merge-form .card {
  border-width: 1px !important;
  background: #121415 !important;
}
.merge-form .form-group {
  outline: none;
}
.action-buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 20px;
  right: 20px;
}
.dialog-merge .details-card {
  width: 350px;
}
/* @import url("https://fonts.googleapis.com/css?family=Lato:200,300,400,400i,700&display=swap"); */

#root,
html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1;
  text-rendering: optimizeLegibility;
  background-color: black;
  color: #dedede;
  font-size: 14px;
  caret-color: white;
}

p {
  color: #b1b1b1;
}

/* Specifies the size of the audio container */
audio {
  height: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: white; */
}

h1 {
  font-size: 32px;
  font-weight: 300;
}

h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

h3 {
  font-size: 16px;
  font-weight: 400;
  margin: 0.5rem;
  line-height: 18px;
  /* letter-spacing: 0.5; */
}

h4,
h5,
h6 {
  opacity: 1;
  font-weight: 500;
}

ul,
li {
  list-style: none;
  padding-left: 0;
}

a:link {
  text-decoration: none !important;
  color: #80b602;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

a:hover {
  text-decoration: none;
  color: #80b602;
}

a:active {
  text-decoration: none;
  color: #80b602;
}

.z4 {
  position: relative;
  z-index: 120;
}

pre {
  white-space: pre-wrap;
}

.btn-icon svg {
  /* transition: all .2s ease-in-out; */
  color: inherit;
}

.btn-icon:hover svg {
  /* transform: scale(1.1); */
  color: #80b602;
}

.btn-icon,
.btn-icon:hover {
  width: 24px;
  height: 24px;
  padding: 0;
}

.b-none {
  background: none !important;
}

.dropdown-toggle[disabled],
input[type="radio"][disabled] {
  cursor: not-allowed;
  opacity: 0.3;
  background: none;
}

.dropdown-toggle[disabled]:hover {
  background: none !important;
}

.table-top-actions .btn.btn-icon {
  width: 32px;
  height: 32px;
  background: none;
  margin: 0;
}

.table-top-actions .btn.btn-icon svg {
  width: 17px !important;
  height: 18px !important;
}

.table-top-actions .btn.btn-icon:hover {
  background: #3f4244;
}

.table-top-actions .btn-icon[disabled]:hover {
  background: none;
}

.btn-toolbar button {
  margin-left: 0.4rem;
}

.custom-btn {
  background: none;
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  transition: background 0.25s ease-in-out;
  border: none;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  text-align: center;
  /* white-space: nowrap; */
}

.custom-btn.custom-add-btn {
  padding: 0px !important;
  margin-left: 15px !important;
  background: none !important;
}

.btn.btn-secondary {
  background: none;
}

.show>.btn-secondary.dropdown-toggle:focus,
.show>.btn-secondary.dropdown-toggle {
  background: #000;
  color: inherit;
  border: none;
  box-shadow: none;
}

.btn.btn-secondary:hover {
  color: inherit;
  background: #3f4244;
}

.btn.btn-back:hover {
  color: #80b602 !important;
}

.custom-btn.custom-btn-black,
.btn.custom-btn-dark,
.btn.btn-secondary {
  background: #4e4e4e;
  color: inherit;
}

.custom-btn.custom-btn-black:hover,
.btn.custom-btn-dark:hover {
  color: inherit;
}

.custom-btn.btn-sm {
  font-size: 0.7rem;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus,
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #000;
}

.custom-btn.custom-btn-green,
.btn.btn-primary {
  color: #222;
  background: #80b602;
  border-color: #80b602;
  font-weight: 600;
}

.custom-btn-green:not(:disabled):not(.disabled):active:focus {
  background: #689400;
  color: #222;
}

.custom-btn.custom-btn-green:hover,
.btn.btn-primary:hover {
  color: #222;
  /* border: 1px solid #b2ff00; */
  background: rgb(179, 255, 2);
}

/* .custom-btn:active,
.custom-btn:visited {
  color: #80b602;
} */
.custom-btn svg {
  margin: -3px 6px 0 0;
  /* font-size: 18px; */
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none;
}

.btn.btn-icon {
  margin-left: 5px;
  border: none;
  line-height: 100%;
  background: #000;
}

.btn.custom-btn.custom-btn-dark {
  margin-left: 5px;
}

.btn.btn-icon:hover {
  color: #80b602;
  background-color: #000;
}

.btn-secondary[disabled] {
  /* background: #111;
  border-color: transparent; */
  color: inherit;
}

.btn-secondary[disabled] svg {
  color: inherit;
}

.btn-secondary[disabled]:hover svg {
  -webkit-transform: none;
          transform: none;
}

.btn.custom-btn.disabled,
.btn.custom-btn:disabled,
.btn-icon[disabled] {
  opacity: 0.3;
}

.btn.custom-btn.custom-btn-green:disabled {
  color: black;
}

.btn.custom-btn.custom-btn-green:disabled:hover {
  background: #80b602 !important;
  color: black !important;
}

.btn.custom-btn.disabled:hover,
.btn.custom-btn:disabled:hover,
.btn-icon[disabled]:hover {
  cursor: not-allowed;
  text-decoration: line-through;
  background: none;
  color: inherit;
}

.btn-icon:focus,
.btn-icon:active {
  box-shadow: none;
}

/* .btn.disabled svg,
.btn:disabled svg {
  color: grey;
} */

/* = Bootstrap classes overided
------------------------------------------------ */

.tooltip {
  /* font-size: 12px;
  pointer-events: none;
  border: 1px solid #444;
  background: black;
  border-radius: 4px; 
  box-shadow: 0 5px 10px #111;*/
}

.tooltip * {
  color: #bbb;
}

.text-muted {
  color: #888 !important;
}

.email-templates #side-container {
  display: none;
}

.email-templates .main-container {
  margin-left: 0 !important;
  min-width: 100%;
}

.side-dialog {
  background: #000 !important;
}

.bg-dark {
  background: black !important;
}

.btn {
  color: #d8d8d8;
  font-size: 12px;
}

.btn.btn-link:hover {
  color: #94d300;
  text-decoration: none;
}

.btn.btn-link:hover svg {
  opacity: 1;
}

a.text-light:focus,
a.text-light:hover,
a.text-light:focus .nav-icon,
a.text-light:hover .nav-icon {
  color: #94d300 !important;
  opacity: 1 !important;
}

.table-dark {
  color: #d8d8d8;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
  color: inherit;
  background-color: #000;
}

.dropdown-menu {
  background-color: #212426;
  color: inherit;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.9);
}

.dropdown-item {
  color: inherit !important;
  font-size: 0.75rem;
  padding: 0.4rem 1rem;
}

.dropdown-item a {
  color: inherit;
}

.dropdown-item svg {
  margin-right: 0.5rem;
  opacity: 0.5;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #80b602;
  background: rgba(0, 0, 0, 0.5);
}

.page-item.disabled .page-link {
  background-color: transparent;
  border-color: #4e555b;
  color: #d8d8d8;
}

.dropdown-toggle {
  line-height: 150% !important;
}

.page-item.active .page-link,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: #80b602;
  border-color: #80b602;
}

.table-dark {
  background-color: transparent;
}

/* .actions-table table tr td:last-child {
  position: absolute;
} */
.data-actions {
  justify-content: flex-end;
  padding-right: 0.5rem;
  padding-left: 1rem;
  display: flex;
  visibility: hidden;
  /* margin-top: 0.8rem;
  margin-right: 1rem; */
}

.data-actions svg {
  width: 14px;
  height: 14px;
  margin: 0 !important;
}

.data-actions button.btn.custom-btn,
.data-actions a {
  background: none;
  height: auto;
  border: none;
  box-shadow: none;
  padding: 0 !important;
  margin: 0;
  width: 24px;
  display: inline-block;
  text-align: center;
}

.data-actions button.btn.custom-btn:hover {
  border: none !important;
  background: none !important;
}

.data-actions button.btn.custom-btn:hover svg,
.data-actions a:hover svg {
  color: rgb(179, 255, 2) !important;
}

.data-actions.always-visible {
  visibility: visible;
}

/* .actions-table table tr td:last-child, .actions-table table tr th:last-child {
  display: none;
} 
.actions-table table tr:hover td:last-child {
  display: table;
  border: none;
}*/
.actions-table table tr:hover td:last-child .data-actions {
  visibility: visible;
}

.actions-table table tr td:last-child {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.actions-table table tr:hover td:last-child {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  background: rgb(40, 44, 47);
}

.table tbody td {
  color: #a5a5a5;
  border-bottom: 1px solid rgba(255, 255, 255, 0.075);
  white-space: nowrap;
  font-weight: 300;
  letter-spacing: 0.2px;
}

.table tbody td span.center {
  display: flex;
  justify-content: center;
}

.table thead th {
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.3);
  border-top: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.075);
  background: rgba(0, 0, 0, 0.4);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 6;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  outline: none;
  font-weight: 400;
  font-size: 12px;
}

.table-hover tbody tr:hover td {
  color: #fff;
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgb(40, 44, 47);
}

.table-dark.table-hover tbody tr:hover td {
  color: #fff;
}

/* .react-bootstrap-table {
  overflow: hidden;
  overflow: auto;
  min-height: 460px;
}
.react-bootstrap-table table {
  table-layout: auto;
}
.react-bootstrap-table table tbody {
  background: none !important;
}
*/
.table {
  margin-bottom: 0px;
}

/* .data-actions {
  position: absolute;
  right: 0;
  background: #000
} */

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #383b3e;
}

.table-hover tbody tr:hover {
  background-color: rgb(50, 56, 62);
}

.table-hover tbody tr:hover .a-tag {
  color: #80b602;
}

.table tr td a.disabled {
  color: #a5a5a5;
  opacity: 0.6;
  cursor: default;
}

.table tr td a,
.table tr td .td-link {
  color: inherit;
}

.table tr:hover td a.disabled {
  color: #888
}

.table tr:hover td a,
.table tr:hover td .td-link {
  color: #80b602;
  cursor: pointer;
}

.table tr td button,
.table tr td .td-link {
  color: #888 !important;
}

.table tr:hover td:hover button:hover,
.table tr:hover td .td-link {
  color: #80b602 !important;
  cursor: pointer;
}

.td-warning {
  color: red;
  margin-right: 4px;
}

.message-warning {
  color: rgb(185, 146, 72);
  font-size: 12px;
  white-space: nowrap;
  position: relative;
}

.message-warning svg {
  margin-right: 0.2rem;
  margin-top: -1px;
}

.table td,
.table th {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  font-size: 12px;
  letter-spacing: 0.2px;
}

.table td p {
  margin: 0;
  white-space: normal;
  word-break: break-all;
  /* min-width: 180px;
  max-width: 260px; */
  font-size: 12px;
  opacity: 0.8;
  line-height: 1.3;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #191b1d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form {
  background: none;
}

.form-label {
  /* opacity: 0.7; */
}

label {
  opacity: 0.5;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.div-error-focus:focus {
  outline: 0 !important;
}

.form-control {
  border-color: #242527;
  background: #121415;
  color: inherit;
  font-size: 14px;
  letter-spacing: 0.4px;
  -webkit-appearance: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #121415;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #444;
  border-left: 4px solid #80b602 !important;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-left: 4px solid #80b602 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-left: 42px solid #dc3545 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-color: #242527;
  font-size: 13px;
  -webkit-text-fill-color: #bbb;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.border-dark {
  border-color: #232427 !important;
}

.form-control:required {
  border-left-color: red;
  /* background: none !important; */
  color: #999;
}

.form-control:focus {
  color: #d8d8d8;
  background-color: transparent;
  border-color: rgb(122, 119, 119);
  outline: 0;
  box-shadow: none;
}

/* select option {
  color: #333;
} */

caption {
  color: #908f8f;
}

/* .sortable > span {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -2px;
    top: 5px;
    align-items: flex-end;
} 
.sortable > span svg:first-child {
  position: relative;
  top: 2px;
}
.sortable > span svg:last-child {
  position: relative;
  top: -2px;
}
*/
.sortable>span svg {
  height: 12px;
  opacity: 0.5;
}

.sortable>span .asc,
.sortable>span .desc {
  opacity: 1;
  color: #80b602;
}

.form_body h5 {
  font-size: 14px;
  margin-left: 0.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

hr.light {
  background: rgba(255, 255, 255, 0.2);
}

hr.dark {
  background: #000;
}

/* select-1 {
  border: 1px solid #565656;
  box-shadow: inset -1px -1px 3px #000;
  background-color: #191b1d;
  color: inherit;
  height: 36px;
} */
select {
  background: #121415 url(/static/media/icon_point-down.1cd75f34.svg) no-repeat 97% 51% !important;
  -webkit-appearance: none;
  appearance: none;
  color: inherit !important;
  padding-left: 0.35rem !important;
}

select option {
  color: inherit !important;
}

.btn.nav-toggle {
  display: none;
}

.table-extras {
  display: flex;
  justify-content: space-between;
}

.table-extras .table-controls {
  display: flex;
  align-items: center;
}

.btn-toolbar .form_CancelButton,
.btn-toolbar .form_SubmitButton {
  padding: 0.5rem 2rem !important;
}

.form-merge {
  width: 750px;
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  z-index: 1019;
  background: black;
  /* box-shadow: 0 2px 6px rgba(0,0,0,.8); */
}

.alert,
.alert-danger {
  padding: 1rem;
  margin: 1rem 0;
  background: none;
  border-color: rgba(255, 0, 0, 0.4);
  color: red;
  position: relative;
}

.alert svg {
  font-size: 16px;
  margin-right: 0.5rem;
}

button.btn-close.btn.btn-primary {
  background: none;
  color: inherit;
  border: none;
  padding: 0 0.5rem;
}

button.btn-close.btn.btn-primary:focus {
  background: none;
  box-shadow: none;
  outline: none;
  color: #80b602;
  border: none;
}

.details-side-section2 p,
.details-side-section4 p {
  line-height: 1.4 !important;
  font-size: 12px !important;
  opacity: 0.5 !important;
  color: white;
}

.webform-response-medium .btn-icon[disabled] {
  opacity: 1 !important;
  width: auto;
  height: auto;
  padding: 0rem 0.2rem !important;
}

.webform-response-medium .btn-icon[disabled] svg {
  width: 16px;
  height: 16px;
}

.message-reportBug {
  line-height: 1.5;
}

.message-reportBug .btn {
  margin-top: 0.5rem;
}

.pagesizeSm {
  display: none;
}

.sidebar-details-table {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.sidebar-details-table .details-table th {
  border-width: 2px;
  font-weight: 500;
  font-size: 13px;
}

.sidebar-details-table .details-table th,
.sidebar-details-table .details-table td {
  border-bottom: 1px solid #222 !important;
}

.form_deleteButtonRow button.custom-btn-green {
  width: auto;
  padding: 1rem !important;
  background: none !important;
}

.form_deleteButtonRow button.custom-btn-green:hover svg {
  right: 0.85rem;
  top: 0;
}

.d-sm-hidden {
  visibility: visible;
}

.d-md-hidden {
  display: none;
}

/* = Making Application Responsive 
------------------------------------------------------------ */
@media screen and (max-width: 1281px) {
  .main-responsive.col-xl-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .email-templates .RichEditor-root {
    padding: 1rem;
    height: 100%;
    min-height: 100vh;
  }
}

@media screen and (max-width: 768px) {

  .results-table-search table tbody td,
  .results-table-search table thead th {
    text-align: left;
  }

  .dashboard-header-wrapper {
    flex-wrap: wrap;
  }

  .results-table-search table tbody td a {
    word-break: normal;
    white-space: nowrap;
  }

  .email-templates .sb-navbar-top {
    margin-left: 0;
    background: black !important;
  }

  .sb-navbar-top {
    /* position: relative; */
    top: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* background: none !important; */
    border: none;
    padding: 0.4rem 0;
  }

  .sb-navbar-user-profile li {
    margin-bottom: 0 !important;
  }

  .sb-navbar-user-profile li a,
  .sb-navbar-user-profile li button {
    padding: 0.5rem;
  }

  .sb-navbar-user-profile li,
  .sb-navbar-user-profile li button {
    background: none !important;
  }

  .sticky-header {
    background: #222;
  }

  .sticky-header .custom-btn.custom-btn-black,
  .sticky-header .btn.custom-btn-dark {
    background: #4e4e4e;
  }

  .sticky-header .btn.btn-back {
    background: none !important;
    border: none !important;
  }

  .btn.nav-toggle {
    display: block;
    color: inherit;
    border: none;
    border-bottom: 1px solid #444;
    border-radius: 0;
    background: black;
    padding: 0.5rem;
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
    cursor: default;
    padding-left: 1rem;
  }

  .btn.nav-toggle svg {
    font-size: 16px;
    opacity: 0.8;
  }

  .sidebar {
    position: fixed;
    top: 0;
    bottom: auto;
    max-width: 100%;
    flex: 0 0 100%;
    padding-top: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1060;
    background: none !important;
  }

  .sidebar .nav-item svg {
    width: 20px;
    height: 20px;
  }

  .sidebar-sticky {
    height: 0;
    line-height: 0;
    color: transparent;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    background: black;
  }

  .sidebar-sticky.open {
    height: 100vh;
    min-height: 280px;
  }

  .sb-navbar-user-profile li .search-global {
    width: auto;
  }

  .sb-navbar-user-profile li.search-bar {
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-radius: 100px;
  }

  .main-container {
    top: 40px;
    width: 100%;
    margin: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .side-drawer.side-dialog {
    max-width: 100%;
    width: 100% !important;
    right: 0;
    left: 0;
    top: 30px;
    bottom: 0;
  }

  /* #activity-header {
    display: none;
  } */
  .row-sm-padding {
    padding-right: 0;
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
  }

  #activity-card-body {
    display: none;
  }

  #activity-card-root .counter {
    width: 60%;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    opacity: 0.5;
  }

  .tab-content .tab-pane>div {
    padding: 0 !important;
    margin-top: 1rem;
  }

  .btn-toolbar .icon-btn.btn-icon {
    width: 24px !important;
    height: 14px !important;
    line-height: 11px;
    background: black;
  }

  .detail-view-main,
  .detail-view-side {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
  }

  .detail-view-side {
    border-top: 1px solid #333;
    margin-top: 0.5rem;
  }

  .detail-view-main>div {
    padding: 0 !important;
    border: none !important;
    margin-bottom: 0 !important;
  }

  .details-side-responsive {
    display: flex;
    flex-wrap: wrap;
  }

  .details-side-section1,
  .details-side-section2,
  .details-side-section3,
  .details-side-section4 {
    flex-basis: 100%;
  }

  .details-side-section2 label {
    opacity: 0.8;
  }

  .past-activities-wrapper {
    padding-right: 0;
    margin-top: 0.5rem;
    border-bottom: 1px solid #333;
    margin-bottom: 0.5rem;
  }

  .email-templates .RichEditor-root {
    padding: 1rem;
    height: 100%;
    min-height: 100vh;
  }

  .RichEditor-controls {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .email-templates .modal-lg.modal-dialog-centered {
    overflow: scroll;
  }

  .customization-arrows {
    margin-top: 0.5rem;
  }

  .customization-arrows button.btn.custom-btn,
  .customization-arrows button.btn.custom-btn:hover {
    padding: 0.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    border: 1px solid #999 !important;
    border-radius: 0;
  }

  .customization-arrows button.btn.arrow-down {
    margin-top: -4px;
    margin-right: 1rem;
  }
}

/* ------------------------ 480px----------------------------*/

@media screen and (max-width: 480px) {
  .d-sm-hidden {
    visibility: hidden;
  }

  .d-md-hidden {
    display: block;
  }

  .d-md-hidden .navbar-brand {
    margin: 0;
    padding-top: 0;
    position: absolute;
    top: 0.2rem;
    left: 40%;
  }

  .d-md-hidden .navbar-brand span {
    color: #80b602;
  }

  .custom-btn.custom-btn-green,
  .btn.btn-primary {
    background: #313821;
    color: #80b602;
    letter-spacing: 0.5px;
  }

  .sticky-header {
    position: relative;
  }

  .table-responsive .table tbody td {
    color: inherit;
    font-size: 14px;
    padding: 0.5rem;
  }

  .table-responsive .table thead th {
    padding: 0.5rem;
  }

  .details-side-responsive .table tbody td {
    color: #999;
    font-weight: 400;
  }

  .details-side-responsive .table tbody td:first-child {
    opacity: 0.8;
    font-weight: 400;
    font-size: 12px !important;
  }

  .sticky-header {
    top: 0;
  }

  .sidebar {
    position: fixed;
    top: 0;
    bottom: auto;
    z-index: 1061;
  }

  .sidebar .btn.btn.nav-toggle {
    border-color: #333;
  }

  .sidebar .sidebar-sticky {
    background: black;
  }

  .sidebar .sidebar-sticky.open {
    width: 100%;
    border-bottom: 1px solid #444;
  }

  .sidebar .sidebar-sticky .nav-sub-items {
    font-size: 16px;
    font-weight: 300;
  }

  .sidebar .sidebar-sticky .nav-sub-items .text-muted {
    color: inherit !important;
  }

  div.activity-cards-wrapper {
    flex-direction: column;
  }

  div.activity-cards-wrapper #activity-card-root {
    margin-bottom: 0.5rem;
  }

  .side-drawer.side-dialog {
    width: 100%;
    top: 2rem;
    padding-bottom: 2rem;
  }

  .sb-navbar-top .navbar-brand {
    text-align: center;
    margin: 0;

  }

  .activity-card-inner {
    padding: 0;
    margin: 0 1px;
  }

  .sb-navbar-top {
    flex-direction: column;
    justify-content: center;
    top: 0;
    padding: 0;
  }

  .main-container {
    top: 0;
  }

  #activity-card-root .counter,
  .activity-indicator-counter .counter {
    font-size: 14px;
    opacity: 1;
    font-weight: 300;
  }

  .activity-indicator-counter .counter {
    color: inherit;
  }

  #activity-card-root .count,
  .activity-indicator-counter .count {
    font-size: 32px;
  }

  .circle-content {
    display: none;
  }

  .table-title-n-search {
    flex-direction: column;
  }

  .table-extras {
    flex-direction: column;
  }

  /* #paginationLg {
    display: none;
  }
  #paginationSm {
    display: block;
  } */
  /* .custom-btn.custom-btn-green,
  .custom-btn.custom-btn-green:hover,
  .custom-btn.custom-btn-green:focus
  .btn.btn-primary,
  .btn.btn-primary:hover,
  .btn.btn-primary:focus {
    border: 1px solid #80b602 !important;
    background: none !important;
    color: #94d300 !important;
    width: auto !important;
    padding: 0.5rem !important;
    font-weight: 400 !important;
  }
   .btn.custom-btn.custom-btn-green:disabled {
    color: #94d300;
    opacity: 0.3 !important;
  }
  
  .side-drawer .sticky-header .custom-btn.custom-btn-black, 
  .side-drawer .custom-btn.custom-btn-black
   {
    border: 1px solid #666 !important;
    background: none !important;
    padding: 0.45rem 0.5rem !important;
    font-weight: 400;
  } */
  .table-title-n-search .search-bar {
    width: 100%;
    border: none;
    border-bottom: 1px solid #444;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-top: 0.5rem;
  }

  .custom-btn.btn-sm {
    font-size: 0.8rem;
  }

  .count {
    color: #f5f5f5;
  }

  .navbar-brand,
  .sb-navbar-user-profile {
    /* flex: 0 0 100%; */
    max-width: 100%;
    position: relative;
  }

  .user-text {
    display: none;
  }

  .sb-navbar-user-profile {
    /* justify-content: center;
    position: absolute;
    right: 0; */
    background: black !important;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #222;
    padding-top: 0.5rem;
    margin-top: -3px;
  }

  .sb-navbar-user-profile li {
    border: none;
    background: #222;
    border-radius: 4px;
    margin: 0 0.2rem 0.5rem 0.2rem;
  }

  .delete-request-root .nav-tabs {
    padding: 0 !important;
    /* display: flex;
    flex-direction: column; */
    flex-wrap: nowrap;
    /* border-bottom: 1px solid #444444; */
  }

  .delete-request-root .nav-tabs .nav-item {
    padding: 0.3rem !important;
    background: none !important;
    font-size: 12px;
    text-align: center;
    color: #999;
    line-height: 1rem;
    margin: 0;
  }

  .delete-request-root .nav-tabs .nav-item svg {
    display: none;
  }

  .delete-request-root .nav-tabs .nav-item.active {
    border-radius: 0;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  #prospect-detail-header {
    border-bottom: 2px solid #444;
  }

  .selectWrap {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .selectWrap button.custom-btn-green {
    width: 100%;
    /* padding: 1rem; */
    margin-top: 0.5rem;
  }

  .selectWrap>div {
    max-width: 100%;
    justify-content: flex-start;
    margin: 0 !important;
  }

  .selectWrap .btn-container {
    margin-top: 1rem !important;
  }

  .form_deleteButtonRow button.custom-btn-green {
    width: auto;
    padding: 1rem !important;
    background: none !important;
  }

  /* .selectWrap .btn-container button {
    width: 100% !important;
    padding: 0.5rem;
  } */
  .rbc-toolbar-custom {
    flex-direction: row;
    margin-top: 0;
  }

  .calendar-header-filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid #333;
    padding-top: 0.5rem;
    border-bottom: 1px solid #333;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.1rem;
  }

  .detail-view-side .card-body {
    padding: 0 !important;
  }

  .detail-view-side table th {

    font-weight: 800 !important;
    border-bottom: 1px solid #222 !important;
  }

  .details-side-section1 td:first-child,
  .details-side-section2 td:first-child,
  .details-side-section3 td:first-child,
  .details-side-section4 td:first-child {
    padding: 0.6rem 0.5rem 0.2rem 0 !important;
    color: #888 !important;
    font-size: 14px !important;
    letter-spacing: 0;
  }

  .details-side-section1 td,
  .details-side-section2 td,
  .details-side-section3 td,
  .details-side-section4 td {
    display: block;
    width: 100% !important;
    padding: 0.2rem 0.5rem 0.2rem 0 !important;
    font-size: 14px !important;
  }

  .past-activities-header-controls {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between !important;
    flex-direction: row-reverse;
  }

  .past-activities-filters {
    display: flex;
  }

  .notes-header {
    flex-direction: column;
    border-bottom: 2px solid #444;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .notes-header .notes-controls {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: flex-start !important;
  }

  .notes-header .notes-controls>div {
    justify-content: flex-start;
  }

  .custom-table-title {
    width: 100% !important;
  }

  .btn-toolbar {
    flex-wrap: inherit;
    /* align-items: center; */
  }

  .btn-toolbar .custom-btn-green {
    /* padding: 0.4rem 1rem !important; */
    margin-top: 0 !important;
  }

  #other-customization-btn-col {
    /* width: 25px; */
  }

  .customization-arrows {
    margin-top: 0.5rem;
  }

  .customization-arrows button.btn.custom-btn,
  .customization-arrows button.btn.custom-btn:hover {
    padding: 0.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    border: 1px solid #999 !important;
    border-radius: 0;
  }

  .customization-arrows button.btn.arrow-down {
    margin-top: -4px;
    margin-right: 1rem;
  }

  #admin-other-customizations-root .m-3,
  #admin-other-customizations-root .m-3 .toolbarSelect {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .email-templates .overlay-backdrop {
    justify-content: flex-start;
  }

  .email-templates .selectWrap {
    flex-direction: row;
  }

  .email-templates .selectWrap .col-padding {
    padding-left: 0 !important;
  }

  /* .email-templates .modal-dialog {
    margin: 0;
  }
  .email-templates .modal-content, .email-templates .modal-body {
    padding: 0;
  } */
  .dialog {
    z-index: 9999;
  }

  .modal-content {
    width: 100%;
    box-shadow: 0 10px 10px rgba(40, 40, 40, 1);
  }

  .flex-sm-column-custom {
    flex-direction: column;
  }

  .flex-sm-column-custom #text-editor-panel {
    margin-top: 1rem;
  }

  .table-wrapper-dark {
    padding-bottom: 6.2rem;
    min-height: auto;
  }

  .pagination-wrapper {
    justify-content: center !important;
    margin-bottom: 0.5rem;
    flex-direction: column-reverse;
  }

  .card-web-form .card-inner {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .data-actions button.btn.custom-btn,
  .data-actions a {
    width: auto;
    padding: 0.1rem 0.4rem !important;
  }

  .data-actions svg {
    width: 18px;
    height: 18px;
  }

  .paginationLg {
    display: none !important;
  }

  .pagesizeSm {
    display: inline-block;
    position: absolute;
    right: 0.5rem;
  }

  .html-editor-switch {
    right: 0.5rem;
  }

  .version-details {
    margin-left: 1.3rem;
  }

  .list-body {
    line-height: 1.2rem;
    justify-content: start;
    align-items: self-start;
  }

  .flex-parent {
    flex-direction: column;
  }

  .version-title {
    margin-left: 1.4rem;
    margin-top: 1rem;
  }

  .list-title {
    padding-left: 0;
    min-width: 80px;
    display: flex;
    align-items: center;
  }

  .versions-wrapper {
    margin-top: 1rem;
  }

  /* merge feature */
  .merge-form {
    width: auto;
    margin: 5px 0 !important;
  }

  .modal-98w {
    width: auto;
  }

  .dialog-merge .details-card {
    width: auto;
    margin: 5px 0;
  }

  .dialog-merge {
    flex-direction: column;
    margin: 0;
    width: auto !important;
  }

  .merge-form .form_TitleContainer h2,
  .dialog-merge .card-header {
    font-size: 16px;
  }

  .merge-form .form_TitleContainer {
    flex-direction: column;
  }

  .dialog-merge .card {
    margin: 5px 0;
  }

  /* = CSM availability */

  .schedule-card table td {
    display: block !important;
  }

  .chip-body .chip-text-align {
    flex-direction: column;
  }

  .schedule-card .table-root tr td:first-child,
  .schedule-card .table-root tr td:last-child {
    width: 100%;
  }
}

/* Responsive ENDS */

.drawer-open {
  overflow: hidden;
}

.form-group {
  outline: none;
}

