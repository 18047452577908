.align_Select {
  align-items: flex-end;
}

.home-nav {
  white-space: nowrap;
  color: #80b602;
  font-weight: bold;
  font-size: 20px;
  margin: 0px 20px !important;
}

.page-container {
  background-color: black;
  color: white;
  height: auto;
}

/* = Navbar Branding 
------------------------------- */
.navbar-brand {
  /* color: #80b602; */
  font-weight: 800;
}

.navbar-brand:hover,
.navbar-brand:active,
.navbar-brand:visited {
  opacity: 1;
  color: #94d300 !important;
}

.navbar-light span {
  color: #94d300;
}

.navbar-brand {
  border-right: 1px solid #191b1d;
  text-align: center;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.navbar-brand img {
  max-width: 80px;
  width: 100%;
}

/* = Sidebar 
---------------------------- */
.sb-navbar-user-profile li {
  border-left: 1px solid #191b1d;
}

.sb-navbar-user-profile li button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.1rem 1rem;
}

.sb-navbar-user-profile {
  /* position: absolute;
  right: 0;
  top: 0.1rem;
  height: 100%;
  line-height: 2rem; */
  display: flex;
  align-items: center;
}

.sb-navbar-user-profile svg {
  font-size: 1.1rem;
  opacity: 0.5;
}

[role="main"] {
  padding-top: 10px;
  height: 100%;
}

.custom-navitem {
  /*border-bottom: 1px solid #191b1d;	
   background-color: #191b1d;	
  box-shadow: inset 0 0 13px 0 #000000; */
  width: 100%;
}

.custom-navitem .nav-link {
  color: #b9b9b9;
}

.nav-link.active .text-muted {
  color: inherit !important;
}

.custom-navitem .nav-link.active {
  position: relative;
}

.custom-navitem .nav-link:hover,
.custom-navitem .nav-link.active:hover,
.custom-navitem .nav-link.active {
  color: #80b602;
  background: #191b1d;
}

.custom-navitem .nav-link:hover {
  background: none;
}

.custom-navitem .nav-link:hover svg {
  color: #80b602;
}

/* .custom-navitem .nav-link.active::after {
  content: ' ';
  display: block;
  position: absolute;
  right: -24px;
  top: 25%;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid transparent;
  border-left: 12px solid #191b1d;
  z-index: 9999;
} */
.main-con {
  margin-left: 0px;
  margin-right: 0px;
}

.home-panel {
  border-bottom: 1px solid #191b1d;
}

.sb-navbar-user-profile li {
  border-left: 1px solid #191b1d;
}

.sb-navbar-user-profile li button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.1rem 1rem;
}

.sb-navbar-user-profile {
  /* position: absolute;
  right: 0;
  top: 0.1rem; 
  height: 100%; 
  line-height: 2rem; */
}

.sb-navbar-user-profile svg {
  font-size: 1.1rem;
  opacity: 0.5;
}

[role="main"] {
  padding-top: 10px;
  height: 100%;
}

.form_SearchBar {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #323437;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.form_SearchBar .icon-search {
  width: 22px;
  height: 22px;
}

.form_SearchBar input {
  font-size: 18px;
  font-weight: 300 !important;
}

.view-as-warning {
  text-align: center;
  width: 100%;
  background-color: rgb(184, 119, 0);
  opacity: 0.9;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 2px;
}

.view-as-warning span {
  color: white;
  font-weight: 200;
  font-size: large;
  margin-left: 2px;
}

.view-as-btn {
  min-width: fit-content;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.version {
  display: inline-block;
  font-size: 12px;
}

.custom-control label {
  opacity: 1 !important;
}

.custom-control-label::before {
  background-color: #4e4e4e;
  border: none;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #80b602;
}

.notification-icon {
  color: white;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.notification-icon svg {
  margin-right: 5px;
}

.notification-icon:hover {
  color: #94d300;
}

.notification-icon .badge {
  position: absolute;
  top: -10px;
  right: -7px;
  padding: 3px 6px;
  border-radius: 50%;
  background: #a90a0a;
  color: white;
}

.notification-drawer {
  width: 30% !important;
}

.notification-drawer .notification-bar-container {
  height: 100%;
  overflow-y: auto;
}

.notification-drawer .notification-bar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
  min-height: 90px;
  background: #111315;
  padding: 0.5rem 1.1rem 0 1.1rem;
  border-bottom: 1px solid #232427;
}

.notification-drawer .notification-bar-sub {
  display: flex;
  justify-content: center;
  padding: 10px 10px 10px 0;
  color: rgb(150, 150, 150);
  font-size: 12px;
  z-index: 10;
  cursor: pointer;
}

.notification-drawer .notification-bar-sub:hover {
  color: rgb(150, 150, 150);
  background-color: #222224;
}

.notification-drawer .notification-bar-subtext {
  color: rgb(150, 150, 150);
  font-size: 12px;
  margin: 0 0.5rem 0 0;
}

.notification-drawer .notification-bar-button {
  color: #94D300;
  font-size: 12px;
  margin: 0 0.5rem 0 0;
}

.notification-drawer .notification-bar-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.notification-drawer .notification-close-btn {
  display: none;
}

.notification {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  margin: 10px 10px 10px 5px;
}

.new-notification {
  border-left: 5px solid #94D300;
}

.notification:hover {
  background-color: #222224;
}

.notification span {
  /* first 3 lines might not work on IE */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: start;
  color: #cdcdcd;
  max-width: 90%;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.notification svg {
  margin-right: 0.5rem;
  color: #94d300;
}

.notification img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.notification .notification-img {
  margin: auto 10px auto 0;
}

.notification .notification-desc {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-items: center;
  line-height: 1.1rem;
  width: 100%;
}

.notification .notification-date {
  display: block;
  margin-top: 0.5rem;
  font-size: 10px;
  letter-spacing: 0.5px;
  color: #b7b4b4 !important;
}

@media screen and (max-width: 768px) {
  .notification-drawer .notification-close-btn {
    display: inline-block;
    cursor: pointer;
  }

  .notification .notification-date {
    margin-right: auto;
  }
}