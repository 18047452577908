.root-row {
  padding: 0.6rem 0;
  border-bottom: 2px solid #191b1d;
  margin-left: 0;
  margin-right: 0px;
}

.row-container {
  margin-left: 0;
  margin-right: 0;
}
.card {
  border-color: transparent;
}
.note-row {
  padding: 0.6rem 0;
  margin-left: 0;
  margin-right: 0px;
}
.details-card {
  border-color: transparent;
  /* width: 40%;
    font-size: 10px;*/
  position: initial;
  z-index: 20;
}
.details-card h3 {
  margin-left: 0;
}
.root {
  height: 100%;
}
.edit-button {
  margin: 2px 2px 2px 2px;
  display: flex;
  justify-content: flex-end;
}
.card-section {
  margin: 1.2rem 0.2rem 1rem 0.7rem;
  /* font-size: 20px; */
}
.card-title {
  margin: 1.2rem 0.2rem 0rem 1.5rem;
  font-size: 25px;
}
.icon-btn.btn-icon {
  margin: 0rem 0rem 0rem 0.5rem;
  padding: 0 !important;
  border: none;
  margin-top: -2px !important;
  border-radius: 20px;
  cursor: pointer;
  background: rgba(0, 0, 05, 0.3);
  width: 16px;
  height: 16px;
  line-height: 12px;
  text-align: center;
  padding-top: 1px !important;
}
.icon-btn.btn-icon:hover {
  width: 16px !important;
  height: 16px !important;
}
.icon-btn.btn-icon:hover svg {
  /* color: black !important; */
  position: relative;
  top: -1px;
}
.icon-btn.btn-icon svg {
  margin: 0 !important;
}
.detail-view-side {
  height: 100%;
  /* margin: 0.6rem 0px 1rem 0.5rem; */
  /* z-index: 20; */
}
.details-community {
  background-color: #191b1d;
  width: unset !important;
  height: unset !important;
  padding: 0.5rem !important;
}
.details-community ul li:first-child {
  color: #80b602;
  margin: 0;
}
.details-community ul {
  margin-left: 1.2rem;
  margin-top: 0;
  margin-bottom: 0;
}
.details-community ul li {
  padding: 0.2rem;
  color: white;
  font-size: 12px;
  list-style: circle;
  padding-left: 0;
  padding-right: 0.5rem;
}

.past-activities-wrapper .caption {
  /* opacity: 0.6;
  color: inherit; */
  white-space: nowrap;
}
.past-activities-wrapper {
  max-height: 480px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 1rem;
  padding-right: 0;
  /* background: #222; */
  border-radius: 4px;
  margin-bottom: 0;
}
.details-side-responsive table tr th:first-child,
.details-side-responsive table tr td:first-child {
  padding-left: 0 !important;
}
/* = Advanced Filters
--------------------------------------- */
.filters-advanced .score-label {
  flex-direction: column !important;
}
.filters-advanced label {
  white-space: nowrap !important;
}
.filter-flag label {
  opacity: 1;
}

.csm-option {
  color: white !important;
}

.csm-optiongrp{
  background-color:#191b1d
}
.popover-group-events-calendar {
  padding: 1rem;
  width: 400px;
}
.details-form-check-label{
  color: #888 !important;
  letter-spacing: 0.2px;
  opacity: inherit;
}
@media screen and (max-width: 480px) {
.popover-group-events-calendar {
  max-width: 300px;
}
}

.email-message div table{
  width: -moz-fit-content !important
}
