input[type="file"] {
  position: relative;
}
input[type="file"]::before {
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 16px;
  height: 20px;
  width: 20px;
  content: "";
  margin-top: 3px;
  color: #222;
}
input[type="file"]::after {
  margin-top: 5px;
  position: absolute;
  pointer-events: none;
  top: 11px;
  left: 30px;
  color: #222;
  font-weight: 600;
  content: "Upload File";
}
input[type="file"]::file-selector-button {
  width: 136px;
  height: 40px;
  margin-top: 5px;
  margin-right: 16px;
  padding: 0 16px;
  border-radius: 4px;
  color: transparent;
  border-color:#80b602;
  background-color: #80b602;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 200ms;
}
input[type="file"]::file-selector-button:hover {
  background-color: rgb(179, 255, 2);
}
input[type="file"]::file-selector-button:active {
  background-color: rgb(179, 255, 2);
}
.error-file-name {
  margin-top: 2px;
  margin-left: 8px;
  color: red;
  line-height: 17px;
}