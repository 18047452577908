.list-header {
    font-size: 1.1rem;
    font-weight: bold;
}

.list-body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 10px;
}

.list-title {
    padding-left: 10px;
    font-size: 1.1em;
    font-weight: 500;
}

.list-text {
    padding-left: 1em;
    font-size: 1.1em;
}

.chip-body {
  display: inline-block;
  padding: 0 25px;
  margin: 0.5em;
  height: 30px;
  font-size: 11px;
  line-height: 30px;
  border-radius: 5px;
  background-color: #191b1d;
}

.chip-body:hover {
    background-color: #800000;
    cursor: pointer;
}

.chip-error-body {
    display: inline-block;
    padding: 0 0.5rem;
    height: 30px;
    font-size: 11px;
    line-height: 30px;
    width: 250px;
    text-align: left;
    border-radius: 5px;
    background-color: #800000;
  }

.chip-body .chip-text-align {
    display: flex;
    flex-direction: row;
    width: 250px;
}

.chip-title {
    margin-right: 1em;
    color: white;
}

.chip-subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
}