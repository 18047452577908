.data-list-col-card {
  display: flex;
}
.data-list-col {
  background-color: rgb(18, 19, 21);
  width: unset !important;
  height: unset !important;
  padding: 0.5rem !important;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}
.data-list-col .popover {
  width: unset;
  height: unset;
}

/* .data-list-col div:first-child{
    background-color: rgb(18, 19, 21);
    color: #80B602;
    margin: 0rem 0px 0.5rem 0;
  } */

.data-list-col div {
  padding: 0.1rem 0.5rem;
  font-size: 12px;
}

.all-community-card {
  display: flex;
}
.details-all-community {
  background-color: rgb(18, 19, 21);
  width: unset !important;
  height: unset !important;
  padding: 0.5rem !important;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}
.details-all-community .popover {
  width: unset;
  height: unset;
}

.details-all-community div {
  /* padding: 0.1rem 0.5rem; */
  font-size: 12px;
}
.caption {
  margin-top: 0px;
}
.PhoneButton{
  font-family: "Roboto", sans-serif !important;
  border: none !important;
  background: none !important;
  padding: 0 !important;
  opacity: 1 !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  text-transform: none !important

}
.icon-img{
  height: 18px;
  width: 18px;
}
.icon-unsubscribe {
  width: 20px;
  height: 20px;
  opacity: 0.5;
  margin-right: 0.5rem;
}
