.nav-link {
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
/* .nav-link.active {
  background: #191b1d;
} */
.nav-item-direct {
  margin-left: 0px;
}
.nav-item-nested {
  margin-left:16px;
}
.nav-sub-items {
  display: flex;
  justify-items: center;
  align-items: center;
  font-size: 13px;
}
.nav-icon {
  padding-right: 1rem;
  font-size: 1rem;
  color: #838383;
}
.nav-icon svg {
  color: inherit;
  font-size: 16px;
}
.active .nav-icon svg {
  color: #80b602;
}
.webForms-count {
  background: #a90a0a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 0px 5px;
  font-size: 10px;
  font-weight: bold;
  height: 18px;
  min-width: 18px;
}
