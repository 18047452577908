
.dialog, .modal{
    background: rgba(93, 93, 93, 0.5);
    z-index: 2023;
}
.modal-link {
    color: #222 !important;
}
.modal-body {
    position: initial;
    padding: 0.5rem;
    opacity: 0.85;
}
.modal-content {
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.75);
    background: #191b1d;
    padding: 0.5rem;
}
.modal-body p {
    line-height: 1.5;
    opacity: 0.8;
}
.modal-header, .modal-footer{
    border-color: rgba(255, 255, 255, 0.1);
    border: none;
}
.modal-title {
    font-size: 1rem;
}
.modal-header {
    /* background: #292929; */
    padding-bottom: 0;
}
.modal-footer {
    padding-top: 0;
}
.modal-header .close {
    color: white;
}

.footer-hr{
    margin-top: 7px;
}

.permanent-delete-body{
    padding: 0px 12px 12px
}
.modal-98w {
    width: 98%;
    position: relative;
    max-width: none!important;
}