.dialog-merge {
  margin: 0 -10px;
  display: flex;
}
.dialog-merge .details-card.card {
  flex-direction: column;
  background: none;
  border: 1px solid #444;
  border-radius: 6px;
  margin: 0 10px;
}
.dialog-merge .card-header {
  padding: 1rem;
  font-size: 20px;
  border-bottom: 1px solid #444;
  display: flex;
}
.dialog-merge .card-header svg
{
  margin-right: 0.5rem;
  color: #80B602;
}
.dialog-merge .details-table thead tr th
{
  padding: 0 !important;
  font-size: 14px;
  color: white;
  font-weight: 400;
  margin-bottom: 1rem;
  padding: 0.5rem 0 0.8rem 0 !important;
  position: relative;
}
.dialog-merge .details-table td {
  border-bottom: 1px solid rgb(37, 37, 37) !important;
  padding: 0.5rem 0 !important;
  font-size: 12px !important;
}
.merge-form {
  width: 800px;
}
.merge-form .form-horizontal {
  background: black;
  border-radius: 8px;
  border: 1px solid #444;
  height: 100%;
}
.merge-form .form_TitleContainer {
  min-height: auto;
  background: none;
  border-bottom: 1px solid #444;
  position: relative !important;
  padding: 0.55rem 1rem;
  flex-direction: row;
  justify-content: space-between;
}
.merge-form .form_TitleContainer > div {
  margin: 0 !important;
}
.merge-form .form_TitleContainer h2 svg {
  font-size: 28px;
  color: #80B602;
  margin-right: 0.5rem;
}
.merge-form .card {
  border-width: 1px !important;
  background: #121415 !important;
}
.merge-form .form-group {
  outline: none;
}
.action-buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 20px;
  right: 20px;
}
.dialog-merge .details-card {
  width: 350px;
}