.rbc-toolbar-custom {
  justify-content: space-between;
  display: flex;
  margin: 1rem 0 0.8rem 0;
  flex-wrap: wrap;
}
.calendar-header-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: rgba(255, 255, 255, .75);
  flex-basis: 100%;
}
.calendar-header-controls .dropdown-toggle, .calendar-header-controls .section-today {
  margin-right: 0.5rem;
}
.calendar-month {
  position: absolute;
  margin-left: 42%;
}
.calendar-header-controls p {
  margin: 0 !important;
  margin-right: 0.5rem !important;
  /* width: 157px; 
  text-align: right;*/
  font-size: 13px;
  opacity: 0.75;
}
.section-today {
  padding: 0.1rem 0;
  border-radius: 4px;
  white-space: nowrap;
}
.arrowLeft, .arrowRight {
  border: none !important;
  width: auto !important;
  padding: 0 !important;
  background: none !important;
}
.arrowLeft svg, .arrowRight svg {
  font-size: 12px !important;
}
.nav-btn {
  border: none !important;
  font-size: x-large;
  color: #DEDEDE;
  background: none;
  outline: none;
  cursor: pointer;
}

.calendar-controls {
  position: absolute;
  right: 0px;
}
.rbc-off-range-bg {
  background: #151617;
}
.rbc-day-bg + .rbc-day-bg,
.rbc-header + .rbc-header,
.rbc-time-header-content,
.rbc-time-content > * + * > *,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #191b1d;
}

.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #191b1d;
  margin-right: 7px !important;
}
.rbc-month-row + .rbc-month-row,
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #191b1d;
}
.rbc-month-view,
.rbc-time-view,
.rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid #191b1d;
}
.rbc-agenda-view table.rbc-agenda-table {
  table-layout: fixed;
}
.rbc-agenda-view table.rbc-agenda-table tr > td:first-child,
.rbc-agenda-view table.rbc-agenda-table tr > th:first-child,
.rbc-agenda-view table.rbc-agenda-table tr > th:nth-child(2),
.rbc-agenda-view table.rbc-agenda-table tr > td:nth-child(2) {
  width: 120px !important;
}
.rbc-header,
.rbc-timeslot-group,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td,
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  border-bottom: 1px solid #2e2c2c;
  padding: 0.5rem 0.5rem 0.4rem 0.5rem;
  width: auto !important;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr {
  background: none !important;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  border-bottom: 2px solid #2e2c2c;
  color: #bbb;
  border: 1px solid #2e2c2c;
  
}
.rbc-show-more {
  color: inherit !important;
}
.rbc-time-content {
  border-top: 2px solid #191b1d;
}
.rbc-time-content,
.rbc-agenda-content {
  font-size: 12px;
  color: rgba(255, 255, 255, .75);
}
.rbc-event {
  padding: 4px 0 4px 0;
  background-color: rgb(102, 154, 152);
  font-size: 12px;
  color: white;
  border-radius: 0;
  margin-left: 1px;
  width: 99.8%;
}
.rbc-today {
  background-color: transparent;
}
.rbc-date-cell > a{
  color: inherit !important;
  text-decoration: none !important;
}
.rbc-date-cell > a:hover{
  color: #80b602 !important; 
}
.rbc-date-cell.rbc-now a,
.rbc-today a {
  color: #80B602 !important;
}
.rbc-date-cell.rbc-now a:hover,
.rbc-today a:hover {
  color: black;
}
.rbc-date-cell.rbc-now{
  color: #80B602
}
.rbc-date-cell {
  text-align: center;
  padding-top: 5px;
  font-size: 12px;
}
.rbc-month-header {
  display: flex;
  flex-direction: row;
  height: 2rem;
  line-height: 2rem;
}
.rbc-header {
  font-weight: 200;
  font-size: 90%;
  color: rgba(255, 255, 255, .4);
}
.rbc-overlay {
  border: none;
  background-color: #24272a;
  max-height: 280px;
  overflow-y: scroll;
  max-width:85%;
  z-index: 100;
}
.rbc-overlay-header {
  border-bottom: 1px solid #565555;
  padding: 0.5rem;
  font-size: 12px;
}
.rbc-event.rbc-selected {
  background-color: #80b602;
  outline: none;
}
.rbc-event:focus {
  outline: none;
}
.rbc-event-label {
  font-size: 90%;
}
.rbc-day-slot .rbc-event {
  border: 1px solid black;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
}
.rbc-show-more {
  background-color: transparent;
  font-weight: 300;
  line-height: normal;
  margin-left: 0.2rem;
  color: #666;
}
.calendar-legend {
  color: #666;
  font-size: 12px;
  margin-top: 5px;
}
#legend-appointments {
  margin-right: 1rem;
}
#legend-followups {
  margin-right: 1rem;
}
#legend-appointments i,
#legend-realtor-appointment i,
#legend-followups i,
#legend-realtor-followups i,
#legend-phonecall i,
#legend-email i,
#legend-massemail i,
#legend-ebomb i,
#legend-text i,
#legend-webform i,
#legend-walkins i {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 2px;
  margin-left: 10px;
}
#legend-appointments i { background: #92af00; }
#legend-realtor-appointment i {background:#d4ba2a;}
#legend-followups i { background: #4c7ef9; }
#legend-realtor-followups i { background: #A84264; }
#legend-phonecall i { background: #50E3C2; }
#legend-text i { background: #C48E48; }
#legend-email i { background: #FF7A00; }
#legend-massemail i { background: #9400ff; }
#legend-ebomb i { background: #D0021B; }
#legend-webform i { background: #FF5E5E;}
#legend-walkins i { background: #a84264;}

.popover{
  /* width: 30%; 
   height: 40%; */
   max-width: none;
}
.popover-body{
  height: 100%;
  padding: 0 !important;
  color: inherit;
}
.popover .arrow{
  display: none
}
.calendar-popover-button{
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-align: left;
  padding: 0;
  font-size: 10px;
  height: 15px;
  font-weight: 400 !important;
  letter-spacing: 0.3px;
}
.rbc-month-view, .rbc-time-content, .rbc-agenda-view {
  height: 530px;
  flex: 0 0 auto;
}
.rbc-agenda-view {
  flex: none;
}
.rbc-agenda-empty {
  display: flex;
    background: #111;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #666;
}
.rbc-time-content, .rbc-agenda-view {
  max-height: 530px;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
}
.rbc-header {
  padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rbc-agenda-view > .rbc-agenda-table {
  margin-left: 1px;
}
.rbc-agenda-view .rbc-header {
  display: table-cell;
  padding: 0.5rem !important;
}
.wrapper-content {
  background: none !important;
}
.email-message div{
  color: inherit !important;
}
.email-content .email-message * , .email-message * font, .email-message * div{
  /* color: #000 !important; */
  word-break: break-word;
  /* text-decoration: none;
  font-size: inherit !important;
  background: #212426; */
}
.gmail_quote * {
  background: none !important;
}
/* .email-content .email-message * .gmail_quote * div {
  color: #ddd !important;
} */
.email-message * p {
  margin-bottom: 1rem;
}
.email-message * hr {
  background: #444;
}
.email-message [style*="border"] {
  border-color: #444 !important;
  padding-top: 0.4rem !important;
}
.email-message * b {
  text-transform: uppercase !important;
  font-weight: normal !important;
  opacity: 0.5 !important;
  min-width: 60px !important;
  display: inline-block !important;
}
.email-message * a {
  color: #b9b9b9 !important;
  word-break: break-all;
}
.popover_Title {
   border-bottom: 1px solid #444;
    padding-bottom: 0.3rem;
    margin-bottom: 0.6rem;
}
.popover_Title strong {
  font-weight: 400;
}
/* = Event Details Popover 
----------------------------------- */
.event-details-popover {
  background: #191b1d;
  max-width: 400px ;
}
.group-events-calendar button{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 350px !important;
  border-radius: 0 !important;
  background: #222 !important;
}
.event-details-popover .popover-body {
  min-width: 300px;
}
.event-details-popover h2 {
  font-weight: 400;
  font-size: 22px;
  text-transform: capitalize;
}
.event-details-popover .popover_Title_small {
  /* opacity: 0.5; 
  margin-bottom: 0.2rem; 
  text-transform: uppercase; */
  font-size: 12px;
  display: flex;
  align-items: center;
}
.event-details-popover .popover_Title_small svg {
  margin-right: 0.3rem;
}
.popover-body-row{
  width: 100%;
  height: 100px;
  margin-right: 0; 
  margin-left: 0;
}

.popover-icon-btn{
  margin: 0rem 0rem 0rem 0.5rem;
  padding:0 !important;
  border: none !important;
  box-shadow: none !important
}
.popover-icon-btn svg{
  margin: 0 !important;
  color: rgb(167, 167, 167);
}
.popover-details-title-row{
  margin: 1rem 0rem 0rem 1.7rem;
  color: rgb(167, 167, 167);
}
.popover-details-value-row{
  margin: 0rem 0rem 1rem 1.7rem;
  color: rgb(221, 221, 221);
}
.event-details-list li {
  margin: 0.2rem 0;
  display: flex;
}
.event-details-list li label {
  opacity: 0.4;
  text-transform: uppercase;
  font-size: 11px;
  display: block;
  margin: 0;
  min-width: 94px;
  display: inline-block;
}
.event-details-list li > span, .event-details-list li a {
  display: inline-block;
  font-size: 12px;
  max-width: 500px;
  max-height: 250px;
  overflow: auto;
  opacity: 0.9;
  word-break: break-word;
}
.event-details-list li a {
  color: #80b602;
}
.event-details-list li > span svg {
  opacity: 0.4;
  margin-right: 0.2rem;
  margin-top: -2px;
}
.group-events-calendar {
  margin-bottom: 2px !important;
}
.group-events-calendar .dropdown-menu {
  padding: 0;
}

.email-preview-btn{
  display: flex;
    flex-direction: row-reverse;
}

.email-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.email-data-table{
  padding: 1rem 0;
}

.email-content *{
  /* background-color: white;
  padding: 1rem; */
  color: inherit !important;
}

.relative-position{
  position: relative;
}

.email-title{
  position: relative;
  padding-right: 2em; 
  font-size: 15px;
}

.email-title-small{
  font-size: 12px;
  display: flex;
  align-items: center;
}

.modal-dialog.modal-email-event {
  min-width: 300px;
  max-width: fit-content !important;
}
.modal-dialog.modal-email-event .modal-content
{
  padding: 0;
  background: 0;
  box-shadow: none;
  border: none;
}
.modal-dialog.modal-email-event .modal-body {
  padding: 0;
}
.dialog-email-details {
  width: auto;
  max-width: 96.5vw;
  height: fit-content;
  background: #191b1d;
  margin: 0 1vw 1vh 0;
  padding: 1rem;
  border-radius: 6px;
  /* box-shadow: 0 0 10px black;
  border: 1px solid #333; */
}
@media screen and (max-width: 480px) {
  .event-details-popover {
    max-width: 300px !important;
  }
  .group-events-calendar button{
  max-width: 295px !important;
}
.dialog-email-details {
  width: auto;
  margin: 0;
  position: relative;
  left: -0.5rem;
}
}
