.form_TitleBig h2 {
  font-size: 20px;
  color: inherit;
  margin: 0;
}
.form_Title {
  color: rgb(150, 150, 150);
  font-size: 12px;
}
.form_body {
  padding: 1rem 3rem 2rem 3rem;
  height: 100%;
}

.padding-less {
  padding-left: 0px;
  padding-right: 0px;
}
.my-options {
  color: grey;
  background: #dcdcdc;
  opacity: 0.2;
}
.form-community {
  margin: 0 0 1rem 0;
  padding: 0 1rem 0 1rem;
  background: #101215;
  border-radius: 6px;
  position: relative;
  border: 1px solid #232427;
}
.form_deleteButtonRow {
  justify-content: flex-end;
}
.form_deleteButtonRow button.btn-close.btn.btn-primary {
  background: #333;
  padding: 0.3rem 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.7rem;
}
.form-horizontal div.form_TitleContainer {
  position: -webkit-sticky; 
  position: sticky;
  top: 0;
}

.detail-partition{
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #232427;
}

.detail-background{
  background-color: black;
}
.filter-select-fix {
  width: 150px;
}
.form-sub-heading {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  margin-bottom: 0.8rem;
}

.is-invalid {
  background-image: none;
}
.invalid-feedback {
  display: contents;
}
.filter-flag .form-control {
  font-size: 13px;
  padding: 0.5rem;
  line-height: 1.3;
}
.filter-flag-controls .btn {
  margin-left: 0.5rem;
}
.filter-flag-controls .btn:first-child {
  margin-left: 0;
}
