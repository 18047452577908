.overlay-backdrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  /*   height: 100%; 
  background: rgba(0, 0, 0, 0.6);*/
  z-index: 100;
}

.overlay-message {
  position: absolute;
  margin-top: 2rem;
}

.icon_backdrop {
  max-height: 120px;
}

.content-empty-empty {
  margin-top: 5rem;
}
.content-empty img {
  right: 0 !important;
}