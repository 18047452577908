.warning-icon {
    margin-right: 5px;
    opacity: 0.5;
}
.warning-container {
    display: flex;
    color: #94d300;
    line-height: 1.2;
    max-width: 50%;
    position: relative;
}
.warning-container svg {
    position: absolute;
    top: -3px;
}
.warning-container p {
    margin-left: 1.5rem;
}