.schedule-card {
    margin-bottom: 1rem;
    min-height: 100px;
}
.schedule-card .table-root {
    overflow: visible;
    overflow-x: visible;
}
.schedule-card .table-root tr td {
    border: 1px solid #222 !important;
    vertical-align: top;
    padding: 0.1rem !important;
    white-space: normal;
}
.profile-img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 0.5rem;
    border-radius: 50%;
}
.chip-body .chip-text-align {
    width: auto;
}
.schedule-card .table-root tr td:first-child {
    width: 20%;
    padding: 0.4rem !important;
}
.schedule-card .table-root tr td:last-child {
    text-align: left !important;
    width: 80%;
}
.schedule-card .chip-body {
    padding: 0.5rem;
    margin: 0.1rem 0;
    width: 99.6%;
    border-radius: 2px;
}
.chip-error-body {
    width: 99.6%;
    margin-top: 0.1rem;
}
.chip-body {
    height: auto;
    font-size: 11px;
    line-height: 1.1rem;
    margin: 3.5px !important;
    max-width: fit-content;
}
.chip-body:hover {
    background-color: #80B602;
    cursor: pointer;
}

.chip-body:hover .chip-title {
    color: black;
    font-weight: bold;
}
.chip-title {
    margin-right: 1em;
    color: #f5f5f5;
    display: block;
    min-width: 110px;
    font-size: 11px;
}
.chip-subtitle {
    overflow: visible;
}

.title-container {
    justify-content: space-between;
    align-items: center;
}

.chip-body-selected { 
    background-color: #80B602;
}

.chip-body-selected .chip-title {
    color: black;
    font-weight: bold;
}

.chip-body-disabled {
    display: inline-block;
    padding: 0 25px;
    margin: 0.5em;
    height: 30px;
    font-size: 11px;
    line-height: 30px;
    border-radius: 5px;
    width: 100%;
    opacity: 0.7;
    background-color: #191b1d;
}

.chip-body-disabled .chip-text-align {
    display: flex;
    flex-direction: row;
    width: 250px;
}

.profile-img-container {
    margin-bottom: 10px;
    margin-left: 10px;
}