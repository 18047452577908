.top-caption {
    font-size: 12px;
    opacity: 0.7;
    letter-spacing: 0.5px;
    text-align: right;
    margin-bottom: 1rem;
  }

.header-table {
    margin: 1rem 0 0.5rem 0; 
    background: rgb(20, 20, 20);
    padding: 0.5rem;
    border-radius: 0.2rem;
    color: inherit;
    letter-spacing: 0.5px;
}
.header-table svg {
    font-size: 14px;
}

.header-icon {
    margin-right: 10px;
    opacity: 0.8 !important;
    color: #94d300
}

.header-text {
    opacity: 0.7;
}
.results-table-search .table-wrapper-dark {
    background: none;
    min-height: auto;
    padding-bottom: 1rem;
}
.results-table-search table tbody td,
.results-table-search table thead th {
    background: black;
    border-color: #1f1f28;
    padding: 0.5rem;
}
.results-table-search table thead th {
    border-bottom: 2px solid #2b2b32;
}
.results-table-search table tbody tr:hover td {
    background: rgb(40, 44, 47);
}
.sb-navbar-user-profile svg {
    opacity: 1;
}
.results-table-search td span {
    color: inherit;
}
.results-table-search table tr td:nth-child(1) {
    padding: 0 0 0 0.5em;
    width: 23px;
}
.results-table-search table td:nth-child(2) {
    width: 25%;
}
.results-table-search table td:nth-child(3) {
    width: 20%;
}
.results-table-search table td:nth-child(4) {
    width: 20%;
}
.results-table-search table tbody td a {
    word-break: break-all;
    white-space: normal;
}
.results-table-search .data-actions button.btn.custom-btn{
    color: #222 !important;
    background: #80b602;
    border-color: #80b602;
    font-weight: 600;
    width: auto;
    padding: 0.25rem 0.5rem !important;
}
.results-table-search .data-actions button.btn.custom-btn:hover {
    background: #80b602 !important;
    border-color: #80b602 !important;
    color: #222 !important;
}