.search-bar {
  margin: 0;
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 0 0 0 0.5rem;
  height: 36px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.4);
  min-width: auto;
}
.search-global {
  width: 380px;
  height: 40px;
}
.search-bar input {
  border: none;
  padding-left: 0 !important;
  font-size: 14px;
  margin-left: 0.5rem;
  background: none !important;
}
.search-bar .input-group-append button.btn {
  background: none;
  border: none;
  opacity: 0.5;
  font-size: 16px;
  font-weight: 300;
}
.search-bar .input-group-append button.btn:active {
  color: #80b602; 
  background: none;
}
.search-bar .icon-search {
  font-size: 12px;
  width: 16px;
  height: 16px;
  opacity: 0.4;
}
.dropdown .btn-secondary {
  border: none;
  box-shadow: none;
  background: #454548;
  /* height: 32px; */
}
.dropdown .btn-secondary:hover {
  color: black;
  background-color: #80b602;
  border: none;
}
