.loader-backdrop {
  min-height: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.loader-backdrop div {
  min-height: inherit;
  display: flex;
  justify-content: center;
  height: inherit;
}
.loader-backdrop div svg {
  position: relative;
  top: 40%;
  height: 80px;
  width: 80px;
}