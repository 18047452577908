.notification .alert {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: inline-block;
    padding: 0.5rem;
    font-size: 14px;
    background-color: rgb(214, 214, 214) !important;
    color: black !important;
    border-color: #ddd !important;
    z-index: 99999;
}

.notification .alert p {
    margin: 0.5rem;
    padding-right: 2rem;
    color: black !important;
}