.form_body {
  padding: 0rem 1.2rem 1rem 1.2rem;
  height: 100%;
}
.form_TitleContainer {
  min-height: 90px;
  background: #111315;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10;
  padding: 0;
  border-bottom: 1px solid #232427;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
}
.form_TitleBig h2 {
  font-size: 20px;
  color: inherit;
}
.form_TitleBig label {
  color: inherit;
}
.form_Title {
  color: rgb(150, 150, 150);
  font-size: 12px;
  margin: 0;
}
.form_addCommunityButton {
  width: auto !important
}
.filters-my-saved button {
  border: 1px solid #242527 !important;
  background: #121415 !important;
}
.form_SubmitButton[disabled] {
  background: #80b602 !important;
  border-color: transparent !important;
}
.filter-error {
  border: solid;
  border-width: thin;
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.2)
}
.filter-error-text {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  color: red;
  opacity: unset;
}