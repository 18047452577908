/* = checkbox field
  ---------------------- ------------------ */
.form-check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.form-check-input {
  margin-top: 0;
}

.form-check-label {
  opacity: 1;
}

.form-check-label-text {
  opacity: 0.5;
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: inherit;
}

/* = add-email-field
  ---------------------- ------------------ */
.secondary-email-wrapper {
  display: flex;
  align-items: center;
  font-weight: 300;
  padding-bottom: 0.2rem;
}

.secondary-email-wrapper .btn-close {
  border: none;
  background: none;
  padding: 0 0.2rem;
}

.secondary-email-wrapper .green {
  color: #80b602;
}

.btn-close:focus,
.btn-close:hover,
.btn-close:active {
  background: #222;
  box-shadow: none;
  outline: none;
  border: transparent;
}

.add-email-container {
  display: flex;
  flex-direction: row;
}

/* .add-email-container button {
  color: #000 !important;
  background-color: #80b602 !important;
  border-color: #80b602;
}

.add-email-container button:disabled {
  opacity: 0.4 !important;
  background: #80b602 !important;
} */

/* = Date picker field
  ---------------------- ------------------ */
.react-calendar {
  background: #080808 !important;
  border-color: #444 !important;
  box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.8) !important;
}

.react-calendar button {
  color: inherit !important;
}

.react-calendar__tile:disabled,
.react-calendar__navigation button[disabled] {
  background-color: #0e0e0e !important;
  opacity: 0.4 !important;
}

.react-calendar__tile--active {
  background: #80b602 !important;
  color: black !important;
}

.date {
  display: block !important;
  height: calc(2rem + 2px);
  border: 1px solid !important;
  border-radius: 0.25rem !important;
  border-color: #242527 !important;
  background: #121415 !important;
  /* margin: 0rem 0.5rem 1rem 0 !important; */
  width: 100% !important;
}

.date-required {
  display: block !important;
  height: calc(2.25rem + 2px) !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  border-color: #3c3a3a !important;
  background: none !important;
  color: #999 !important;
  width: 100% !important;
}

.date-required .react-date-picker--enabled {
  height: 36px !important;
  border-left: 2px solid #80b602 !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.date-required .react-date-picker {
  border-left: 2px solid red;
}

.react-calendar .react-calendar__tile:enabled:hover,
.react-calendar .react-calendar__tile:enabled:focus,
.react-calendar .react-calendar__tile--hasActive {
  background-color: #94d300 !important;
  color: black !important;
}

.date-required .react-date-picker input {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  color: inherit !important;
  height: 32px !important;
  font-size: 13px !important;
}

.date-required .react-date-picker .react-date-picker__inputGroup__leadingZero {
  margin-right: 2px !important;
}

.date-required .react-date-picker input.react-date-picker__inputGroup__month,
.date-required .react-date-picker input.react-date-picker__inputGroup__day {
  width: 18px !important;
  text-align: center !important;
  padding: 0 !important;
}

.date-required .react-date-picker__inputGroup__year {
  width: 34px !important;
  text-align: center !important;
  padding: 0 !important;
}

.date-required .react-date-picker span {
  font-size: 13px !important;
}

.date .react-date-picker input {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  color: inherit !important;
  height: 32px !important;
  font-size: 13px !important;
}

.date .react-date-picker .react-date-picker__inputGroup__leadingZero {
  margin-right: 2px !important;
  font-size: 13px;
}

.date .react-date-picker input.react-date-picker__inputGroup__month,
.date .react-date-picker__inputGroup__day {
  width: 18px !important;
  text-align: center !important;
  padding: 0 !important;
}

.date .react-date-picker__inputGroup__year {
  width: 34px !important;
  text-align: center !important;
  padding: 0 !important;
}

.react-date-picker__calendar {
  width: 300px !important;
}

@media screen and (max-height: 675px) {
  .react-calendar {
    line-height: 0.4em !important;
  }
}

.react-date-picker__wrapper {
  border: none !important;
  margin-left: 0.4rem !important;
}

.react-calendar button.react-calendar__tile--active {
  color: black !important;
}

.react-date-picker--disabled {
  background-color: inherit !important;
  opacity: 0.5;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: rgb(27, 27, 27) !important;
}

/* = select field
  ---------------------- ------------------ */
option {
  color: inherit !important;
}

select:disabled {
  opacity: 0.7 !important;
  /* background-color: blue ; */
}

/* .filter-select-field {
  background-color: #303438 !important;
  color: inherit !important;
  min-width: 150px;
  max-width: fit-content;
  line-height: 1;
} */
select option:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #80b602;
}

div.css-w8afj7-Input {
  color: inherit;
}

/* = Time Area Field
  ---------------------- ------------------ */
.time {
  display: block;
  height: calc(2.25rem + 2px);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-color: #3c3a3a;
  background: none !important;
  color: #999;
  padding: 0 0.5rem;
  /* border-left-color: red;
    border-left-width: 2px; */
  width: 100%;
}

.time .react_times_button {
  background: none !important;
  height: 100%;
  /* margin-left:1px;  */
}

.time .time_picker_container {
  background: none !important;
  /* margin-left:1px;  */
  height: 100%;
}

.time .time_picker_container {
  background: none !important;
  /* margin-left:1px;  */
  height: 100%;
}

.time .preview_container {
  position: relative;
  height: 100%;
  top: 0;
  color: #999;
  line-height: inherit;
  font-size: 13px;
  padding: 0;
  padding-top: 12px;
  padding-left: 12px;
}

.time .modal_container {
  top: 17px;
}

.time input {
  color: #999;
  font-size: 9px;
  /* top: 100%; */
}

.time span {
  color: #999;
  top: 100%;
}

.time.is-invalid,
.was-validated .time:invalid {
  border: 1px solid #dc3545 !important;
}

.invalid-time-feedback {
  white-space: nowrap;
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

/* = Score select field
  ---------------------- ------------------ */
/* .form-group.score-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
} */
.form-group select#score-select {
  padding: 0px;
  width: 64px;
  height: 28px;
  font-size: 12px;
  color: #bbb;
}

.rating-red {
  border-left: 3px solid red;
}

.rating-orange {
  border-left: 3px solid orange;
}

.rating-green {
  border-left: 3px solid green;
}

.rating-dropdown {
  border-radius: 4px;
  background: #121415;
}

.rating-dropdown a:hover {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
  cursor: pointer;
}

.rating-dropdown button {
  background: none !important;
  border-radius: 0 !important;
}

.rating-dropdown .dropdown-menu {
  min-width: 80px;
}

.dropdown-menu[aria-labelledby="filter-score-select"] {
  min-width: 80px;
}

.filter-rating-dropdown {
  background: #121415 !important;
  border-right: 1px solid #242527;
  border-top: 1px solid #242527;
  border-bottom: 1px solid #242527;
  border-radius: 4px;
}

.filter-rating-dropdown .btn-secondary {
  border: none;
  box-shadow: none;
  background: #121415 !important;
}

.filter-rating-dropdown .btn-secondary:hover {
  color: #80b602;
}

.time-toggle {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.time-dropdown-item {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-bottom: 1px solid #242527;
}

.time-dropdown-menu {
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  width: 80px !important;
  min-width: 80px !important;
}

.secondary-email-box {
  min-height: 80px;
  overflow-y: scroll;
  padding-top: 0.5em;
}

#associated_emails-form-group .secondary-email-box {
  min-height: 180px;
}

/* .time option {
  display: flex;
  justify-content: center;
  align-content: center;
  vertical-align: middle;
  border-bottom: 1px solid #242527;
}
.time.select::-ms-expand {
  display: none;
  -webkit-appearance: none; /*Removes default chrome and safari style*/
/* -moz-appearance: none; */
/* } */
/* .select::-ms-expand { */
/* display: none; */
/* -webkit-appearance: none; Removes default chrome and safari style */
/* -moz-appearance: none; */
/* } */
.position-fix .dropdown-menu {
  transform: translate(0, 30px) !important;
}