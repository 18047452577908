.backdrop{
    position: fixed;
    width:100%;
    height:100%;
    left:0;
    top:0;
    background: rgba(93, 93, 93, 0.5);
    z-index: 2020;
}
.backdrop-dark .loader-gif svg {
    width: auto !important;
    height: 48px !important;
}