.customization-header {
  margin: 0;
  background-color: #181a1d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.2rem;
}
.customization-header h3 {
  font-size: 14px;
  opacity: 0.5;
}

.other-customization-card-root {
  padding: 1rem;
  background-color: #191b1d;
  margin-top: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.add-other-input-wrapper {
  display: flex;
  margin-top: inherit;
}
.add-other-input-wrapper input {
  opacity: 0.5;
  background: black !important;
  border-color: #3c3a3a;
  margin-right: 0.5rem;
}

.add-other-input-wrapper button svg {
  margin: 0px;
}
.add-other-input-wrapper button:disabled {
  opacity: 0.6 !important;
}

.customizations-option-box {
  min-height: 80px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid #3c3a3a;
  border-radius: 4px;
}
.cutomization-text li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cutomization-text li p {
  margin-bottom: 0;
}
.box-height {
  min-height: 80px;
}
.customizations-option-box .options-group {
  max-height: 180px;
  min-height: 180px;
  overflow: auto;
}
.options-item {
  background: inherit;
  padding: 0.3rem;
  color: #d8d8d8;
  font-size: 0.9em;
}
.selected {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #80b602;
}
.options-item:hover {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #80b602;
  cursor: pointer;
}

.custom-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.custom-btn-arrows {
  font-size: 12px;
}
.custom-btn-arrows svg {
  width: 12px;
  height: 12px;
  margin: 0px;
}
.custom-btn-arrows:disabled {
  background-color: #191b1d !important;
}
.custom-btn-arrows:disabled svg {
  color: white !important;
}
.left-right-arrows button svg {
  margin: 0;
  padding: 0;
}
.left-right-arrows button.btn.custom-btn:hover {
  border: none !important;
}

@media screen and (max-width: 768px) {
  .row {
    justify-content: center;
  }
}