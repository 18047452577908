.flex-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.version-number {
    flex: 0.25
  }

.version-date {
    text-transform: uppercase;
    font-size: 12px;
}

.version-details {
    flex: 0.6;
}

.group-icon {
    margin-left: 0%;
    padding-right: 1em;
}
.list-header {
    margin-left: 0.1rem;
    font-weight: 400 !important;
}
.list-body {
    padding-top: 8px !important;
    align-items: flex-start;
}
.list-body .list-item {
    color: inherit;
    margin: 0;
    padding: 0 !important;
    line-height: 1.5rem;
}
.list-title {
    font-size: 0.8rem !important;
    font-weight: 500;
    min-width: 80px;
    display: flex;
    align-items: center;
}
.list-title span {
    opacity: 0.4;
}
.versions-wrapper .list-title {
    padding-left: 0;
}
.versions-wrapper {
    margin: 3rem 0 0 -1rem;
    border-bottom: 1px solid #222;
    padding-bottom: 3rem
}
.version-details .list-header {
    margin-top: 2rem;
  }
  .version-details .list-header:first-child {
      margin-top: 0;
  }
.version-title .caption {
    opacity: 0.6;
    font-size: 12px;
    text-transform: none !important;
    letter-spacing: 0;
    font-weight: 500;
}
.recoding-view {
    padding: 20px;
}

@media screen and (max-width: 480px) {
    .version-number {
        width: 100%;
    }
    .recoding-view {
        padding: 20px;
    }
}