.dropzone-container {
  border: 1px dashed #3C3A3A;
  border-radius: 5px;
  background-color: #121415;
}

.dropzone-container .content {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropzone-container .icon {
  margin: 20px 40px;
  width: 71px;
}

.dropzone-container .sub {
  margin: 0px;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.3;
}

.dropzone-container .selected-content {
  width: 300px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background: black;
  border: 1px solid #3E494E;
  border-radius: 4px;
}

.dropzone-container .selected-content .file-name {
  font-size: 14px;
  color: #80b602;
  line-height: 17px;
  margin-left: 8px;
  word-break: break-all;
}

.dropzone-container .selected-content .error-file-name {
  margin-top: 2px;
  margin-left: 8px;
  color: red;
  line-height: 17px;
}

.dropzone-container .selected-title {
  align-items: center;
}

.content .title{
  font-size: 16px;
  color: #908F8F;
  line-height: 19px;
}

.action-container {
  display: flex;
  justify-content: flex-start;
  margin: 32px 0px;
}