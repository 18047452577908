.align_Select {
  align-items: flex-end;
}
.home-nav {
  white-space: nowrap;
  color: #80b602;
  font-weight: bold;
  font-size: 20px;
  margin: 0px 20px !important;
}

.page-container {
  background-color: black;
  color: white;
  height: auto
}

.main-con {
  margin-left: 0px;
  margin-right: 0px;
}

.home-panel {
  border-bottom: 1px solid #191b1d;
}
.root-row {
  padding: 0.6rem 0;
  margin-left: 0;
  margin-right: 0px;
}

.row-container {
  margin-left: 0;
  margin-right: 0;
}

.tasks-container {
  height: 50%;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
}
#bootstrap-overrides div {
  margin-right: 0px; /* priority score, yay! */
}


.btn-container {
  display: flex;
  flex :1;
  justify-content:flex-end;
}

button.btn-back {
  width: 34px;
    height: 32px;
    /* text-align: center; */
    /* display: flex; */
    background: rgba(255, 255, 255, .2) !important;
    padding: 0 !important;
    border-radius: 50px;
    margin-right: 0.5rem !important;
    border: none !important;
    box-shadow: none !important;
    margin-left: 0 !important;
}
button.btn-back svg {
  margin: 0 0 0 -2px !important;
}
.select-bar-back-btn{
  border-radius: 50%;
}
.activity-root {
  height: 100%;
  margin: 0.6rem 0px 0.6rem 0;
}
.filter-item button{
  background-color: black;
  line-height: 1;
  font-size: 0.7rem; 
  margin-left: -15px;
  text-align: left;
}
.table-upcoming-tasks td:first-child {
  width: 36px;
  padding-right: 0;
}
.table-upcoming-tasks td:nth-child(2) {
  width: 24px;
  padding-right: 0;
  padding-left: 0;
}
.table-upcoming-tasks th:nth-child(3),
.table-upcoming-tasks td:nth-child(3) {
  padding-left: 0.3rem;
}
.table-upcoming-tasks td:nth-child(5) {
  width: 36px;
}
.table-upcoming-tasks td:nth-child(6) {
  width: 260px;
}

.react-calendar__tile--now {
  background: #80b602;
}

.toggle-button {
  margin-bottom: 0.6rem;
}