.flag-icon {
  color: inherit;
}

.red-flag-icon {
  color: red;
}

.flag-btn {
  background-color: #19191b;
  border: none;
}
