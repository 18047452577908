.form {
  /* overflow: scroll */
}
.form_body {
  padding: 0rem 1.2rem 1rem 1.2rem;
  height: 100%;
  /* overflow: scroll */
}
.form_TitleContainer {
  min-height: 90px;
  background: #111315;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10;
  padding: 0;
  border-bottom: 1px solid #232427;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
}
.form_TitleBig h2 {
  font-size: 20px;
  color: inherit;
}
.form_TitleBig label {
  color: inherit;
}
.form_Title {
  color: rgb(150, 150, 150);
  font-size: 12px;
  margin: 0;
}
.form_RequiredInput {
  /* border-left: 3px solid red !important; */
  /* background: black */
  /* color:black */
}
/* .form_RequiredInput::before{
  display: block;
  content: '';
  height: 36px;
  width: 3px;
  background-color: red;
  left: -12px;
  position: relative;
  border-radius: 50px;
} */
.form_CellRequiredInput {
  width: 200px;
  /* border-left: 3px solid red !important; */
  /* background:black */
}
.form_CellInput {
  width: 200px;
  /* background:black */
}
.form_BiggerInput {
  height: 120px;
  /* background:black */
}
.form_MediumInput {
  height: 90px;
  /* background:black */
}

.form_addCommunityButton {
  width: auto !important
}
.form_ranking {
  background: "black";
}

.form-submit-button {
  color: #000 !important;
  background: #80b602 !important;
  border-color: #80b602 !important;
  padding: 0.4rem 0.8rem !important;

  border-radius: 0.25rem !important;
}

.lead-to-prospect-form {
  height: inherit;
}
.filters-my-saved button {
  border: 1px solid #242527 !important;
  background: #121415 !important;
}

.form_SubmitButton[disabled] {
  background: #80b602 !important;
  border-color: transparent !important;
}
.filter-error{
  border: solid;
  border-width: thin;
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.2)
}
.filter-error-text{
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  color: red;
  opacity: unset;
}