/* @import url("https://fonts.googleapis.com/css?family=Lato:200,300,400,400i,700&display=swap"); */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

#root,
html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1;
  text-rendering: optimizeLegibility;
  background-color: black;
  color: #dedede;
  font-size: 14px;
  caret-color: white;
}

p {
  color: #b1b1b1;
}

/* Specifies the size of the audio container */
audio {
  height: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: white; */
}

h1 {
  font-size: 32px;
  font-weight: 300;
}

h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

h3 {
  font-size: 16px;
  font-weight: 400;
  margin: 0.5rem;
  line-height: 18px;
  /* letter-spacing: 0.5; */
}

h4,
h5,
h6 {
  opacity: 1;
  font-weight: 500;
}

ul,
li {
  list-style: none;
  padding-left: 0;
}

a:link {
  text-decoration: none !important;
  color: #80b602;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

a:hover {
  text-decoration: none;
  color: #80b602;
}

a:active {
  text-decoration: none;
  color: #80b602;
}

.z4 {
  position: relative;
  z-index: 120;
}

pre {
  white-space: pre-wrap;
}

.btn-icon svg {
  /* transition: all .2s ease-in-out; */
  color: inherit;
}

.btn-icon:hover svg {
  /* transform: scale(1.1); */
  color: #80b602;
}

.btn-icon,
.btn-icon:hover {
  width: 24px;
  height: 24px;
  padding: 0;
}

.b-none {
  background: none !important;
}

.dropdown-toggle[disabled],
input[type="radio"][disabled] {
  cursor: not-allowed;
  opacity: 0.3;
  background: none;
}

.dropdown-toggle[disabled]:hover {
  background: none !important;
}

.table-top-actions .btn.btn-icon {
  width: 32px;
  height: 32px;
  background: none;
  margin: 0;
}

.table-top-actions .btn.btn-icon svg {
  width: 17px !important;
  height: 18px !important;
}

.table-top-actions .btn.btn-icon:hover {
  background: #3f4244;
}

.table-top-actions .btn-icon[disabled]:hover {
  background: none;
}

.btn-toolbar button {
  margin-left: 0.4rem;
}

.custom-btn {
  background: none;
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  transition: background 0.25s ease-in-out;
  border: none;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  text-align: center;
  /* white-space: nowrap; */
}

.custom-btn.custom-add-btn {
  padding: 0px !important;
  margin-left: 15px !important;
  background: none !important;
}

.btn.btn-secondary {
  background: none;
}

.show>.btn-secondary.dropdown-toggle:focus,
.show>.btn-secondary.dropdown-toggle {
  background: #000;
  color: inherit;
  border: none;
  box-shadow: none;
}

.btn.btn-secondary:hover {
  color: inherit;
  background: #3f4244;
}

.btn.btn-back:hover {
  color: #80b602 !important;
}

.custom-btn.custom-btn-black,
.btn.custom-btn-dark,
.btn.btn-secondary {
  background: #4e4e4e;
  color: inherit;
}

.custom-btn.custom-btn-black:hover,
.btn.custom-btn-dark:hover {
  color: inherit;
}

.custom-btn.btn-sm {
  font-size: 0.7rem;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus,
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #000;
}

.custom-btn.custom-btn-green,
.btn.btn-primary {
  color: #222;
  background: #80b602;
  border-color: #80b602;
  font-weight: 600;
}

.custom-btn-green:not(:disabled):not(.disabled):active:focus {
  background: #689400;
  color: #222;
}

.custom-btn.custom-btn-green:hover,
.btn.btn-primary:hover {
  color: #222;
  /* border: 1px solid #b2ff00; */
  background: rgb(179, 255, 2);
}

/* .custom-btn:active,
.custom-btn:visited {
  color: #80b602;
} */
.custom-btn svg {
  margin: -3px 6px 0 0;
  /* font-size: 18px; */
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none;
}

.btn.btn-icon {
  margin-left: 5px;
  border: none;
  line-height: 100%;
  background: #000;
}

.btn.custom-btn.custom-btn-dark {
  margin-left: 5px;
}

.btn.btn-icon:hover {
  color: #80b602;
  background-color: #000;
}

.btn-secondary[disabled] {
  /* background: #111;
  border-color: transparent; */
  color: inherit;
}

.btn-secondary[disabled] svg {
  color: inherit;
}

.btn-secondary[disabled]:hover svg {
  transform: none;
}

.btn.custom-btn.disabled,
.btn.custom-btn:disabled,
.btn-icon[disabled] {
  opacity: 0.3;
}

.btn.custom-btn.custom-btn-green:disabled {
  color: black;
}

.btn.custom-btn.custom-btn-green:disabled:hover {
  background: #80b602 !important;
  color: black !important;
}

.btn.custom-btn.disabled:hover,
.btn.custom-btn:disabled:hover,
.btn-icon[disabled]:hover {
  cursor: not-allowed;
  text-decoration: line-through;
  background: none;
  color: inherit;
}

.btn-icon:focus,
.btn-icon:active {
  box-shadow: none;
}

/* .btn.disabled svg,
.btn:disabled svg {
  color: grey;
} */

/* = Bootstrap classes overided
------------------------------------------------ */

.tooltip {
  /* font-size: 12px;
  pointer-events: none;
  border: 1px solid #444;
  background: black;
  border-radius: 4px; 
  box-shadow: 0 5px 10px #111;*/
}

.tooltip * {
  color: #bbb;
}

.text-muted {
  color: #888 !important;
}

.email-templates #side-container {
  display: none;
}

.email-templates .main-container {
  margin-left: 0 !important;
  min-width: 100%;
}

.side-dialog {
  background: #000 !important;
}

.bg-dark {
  background: black !important;
}

.btn {
  color: #d8d8d8;
  font-size: 12px;
}

.btn.btn-link:hover {
  color: #94d300;
  text-decoration: none;
}

.btn.btn-link:hover svg {
  opacity: 1;
}

a.text-light:focus,
a.text-light:hover,
a.text-light:focus .nav-icon,
a.text-light:hover .nav-icon {
  color: #94d300 !important;
  opacity: 1 !important;
}

.table-dark {
  color: #d8d8d8;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
  color: inherit;
  background-color: #000;
}

.dropdown-menu {
  background-color: #212426;
  color: inherit;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.9);
}

.dropdown-item {
  color: inherit !important;
  font-size: 0.75rem;
  padding: 0.4rem 1rem;
}

.dropdown-item a {
  color: inherit;
}

.dropdown-item svg {
  margin-right: 0.5rem;
  opacity: 0.5;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #80b602;
  background: rgba(0, 0, 0, 0.5);
}

.page-item.disabled .page-link {
  background-color: transparent;
  border-color: #4e555b;
  color: #d8d8d8;
}

.dropdown-toggle {
  line-height: 150% !important;
}

.page-item.active .page-link,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: #80b602;
  border-color: #80b602;
}

.table-dark {
  background-color: transparent;
}

/* .actions-table table tr td:last-child {
  position: absolute;
} */
.data-actions {
  justify-content: flex-end;
  padding-right: 0.5rem;
  padding-left: 1rem;
  display: flex;
  visibility: hidden;
  /* margin-top: 0.8rem;
  margin-right: 1rem; */
}

.data-actions svg {
  width: 14px;
  height: 14px;
  margin: 0 !important;
}

.data-actions button.btn.custom-btn,
.data-actions a {
  background: none;
  height: auto;
  border: none;
  box-shadow: none;
  padding: 0 !important;
  margin: 0;
  width: 24px;
  display: inline-block;
  text-align: center;
}

.data-actions button.btn.custom-btn:hover {
  border: none !important;
  background: none !important;
}

.data-actions button.btn.custom-btn:hover svg,
.data-actions a:hover svg {
  color: rgb(179, 255, 2) !important;
}

.data-actions.always-visible {
  visibility: visible;
}

/* .actions-table table tr td:last-child, .actions-table table tr th:last-child {
  display: none;
} 
.actions-table table tr:hover td:last-child {
  display: table;
  border: none;
}*/
.actions-table table tr:hover td:last-child .data-actions {
  visibility: visible;
}

.actions-table table tr td:last-child {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.actions-table table tr:hover td:last-child {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  background: rgb(40, 44, 47);
}

.table tbody td {
  color: #a5a5a5;
  border-bottom: 1px solid rgba(255, 255, 255, 0.075);
  white-space: nowrap;
  font-weight: 300;
  letter-spacing: 0.2px;
}

.table tbody td span.center {
  display: flex;
  justify-content: center;
}

.table thead th {
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.3);
  border-top: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.075);
  background: rgba(0, 0, 0, 0.4);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 6;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  outline: none;
  font-weight: 400;
  font-size: 12px;
}

.table-hover tbody tr:hover td {
  color: #fff;
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgb(40, 44, 47);
}

.table-dark.table-hover tbody tr:hover td {
  color: #fff;
}

/* .react-bootstrap-table {
  overflow: hidden;
  overflow: auto;
  min-height: 460px;
}
.react-bootstrap-table table {
  table-layout: auto;
}
.react-bootstrap-table table tbody {
  background: none !important;
}
*/
.table {
  margin-bottom: 0px;
}

/* .data-actions {
  position: absolute;
  right: 0;
  background: #000
} */

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #383b3e;
}

.table-hover tbody tr:hover {
  background-color: rgb(50, 56, 62);
}

.table-hover tbody tr:hover .a-tag {
  color: #80b602;
}

.table tr td a.disabled {
  color: #a5a5a5;
  opacity: 0.6;
  cursor: default;
}

.table tr td a,
.table tr td .td-link {
  color: inherit;
}

.table tr:hover td a.disabled {
  color: #888
}

.table tr:hover td a,
.table tr:hover td .td-link {
  color: #80b602;
  cursor: pointer;
}

.table tr td button,
.table tr td .td-link {
  color: #888 !important;
}

.table tr:hover td:hover button:hover,
.table tr:hover td .td-link {
  color: #80b602 !important;
  cursor: pointer;
}

.td-warning {
  color: red;
  margin-right: 4px;
}

.message-warning {
  color: rgb(185, 146, 72);
  font-size: 12px;
  white-space: nowrap;
  position: relative;
}

.message-warning svg {
  margin-right: 0.2rem;
  margin-top: -1px;
}

.table td,
.table th {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  font-size: 12px;
  letter-spacing: 0.2px;
}

.table td p {
  margin: 0;
  white-space: normal;
  word-break: break-all;
  /* min-width: 180px;
  max-width: 260px; */
  font-size: 12px;
  opacity: 0.8;
  line-height: 1.3;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #191b1d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form {
  background: none;
}

.form-label {
  /* opacity: 0.7; */
}

label {
  opacity: 0.5;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.div-error-focus:focus {
  outline: 0 !important;
}

.form-control {
  border-color: #242527;
  background: #121415;
  color: inherit;
  font-size: 14px;
  letter-spacing: 0.4px;
  -webkit-appearance: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #121415;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #444;
  border-left: 4px solid #80b602 !important;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-left: 4px solid #80b602 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-left: 42px solid #dc3545 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-color: #242527;
  font-size: 13px;
  -webkit-text-fill-color: #bbb;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.border-dark {
  border-color: #232427 !important;
}

.form-control:required {
  border-left-color: red;
  /* background: none !important; */
  color: #999;
}

.form-control:focus {
  color: #d8d8d8;
  background-color: transparent;
  border-color: rgb(122, 119, 119);
  outline: 0;
  box-shadow: none;
}

/* select option {
  color: #333;
} */

caption {
  color: #908f8f;
}

/* .sortable > span {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -2px;
    top: 5px;
    align-items: flex-end;
} 
.sortable > span svg:first-child {
  position: relative;
  top: 2px;
}
.sortable > span svg:last-child {
  position: relative;
  top: -2px;
}
*/
.sortable>span svg {
  height: 12px;
  opacity: 0.5;
}

.sortable>span .asc,
.sortable>span .desc {
  opacity: 1;
  color: #80b602;
}

.form_body h5 {
  font-size: 14px;
  margin-left: 0.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

hr.light {
  background: rgba(255, 255, 255, 0.2);
}

hr.dark {
  background: #000;
}

/* select-1 {
  border: 1px solid #565656;
  box-shadow: inset -1px -1px 3px #000;
  background-color: #191b1d;
  color: inherit;
  height: 36px;
} */
select {
  background: #121415 url("../images/icon_point-down.svg") no-repeat 97% 51% !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: inherit !important;
  padding-left: 0.35rem !important;
}

select option {
  color: inherit !important;
}

.btn.nav-toggle {
  display: none;
}

.table-extras {
  display: flex;
  justify-content: space-between;
}

.table-extras .table-controls {
  display: flex;
  align-items: center;
}

.btn-toolbar .form_CancelButton,
.btn-toolbar .form_SubmitButton {
  padding: 0.5rem 2rem !important;
}

.form-merge {
  width: 750px;
}

.sticky-header {
  position: sticky;
  top: 50px;
  z-index: 1019;
  background: black;
  /* box-shadow: 0 2px 6px rgba(0,0,0,.8); */
}

.alert,
.alert-danger {
  padding: 1rem;
  margin: 1rem 0;
  background: none;
  border-color: rgba(255, 0, 0, 0.4);
  color: red;
  position: relative;
}

.alert svg {
  font-size: 16px;
  margin-right: 0.5rem;
}

button.btn-close.btn.btn-primary {
  background: none;
  color: inherit;
  border: none;
  padding: 0 0.5rem;
}

button.btn-close.btn.btn-primary:focus {
  background: none;
  box-shadow: none;
  outline: none;
  color: #80b602;
  border: none;
}

.details-side-section2 p,
.details-side-section4 p {
  line-height: 1.4 !important;
  font-size: 12px !important;
  opacity: 0.5 !important;
  color: white;
}

.webform-response-medium .btn-icon[disabled] {
  opacity: 1 !important;
  width: auto;
  height: auto;
  padding: 0rem 0.2rem !important;
}

.webform-response-medium .btn-icon[disabled] svg {
  width: 16px;
  height: 16px;
}

.message-reportBug {
  line-height: 1.5;
}

.message-reportBug .btn {
  margin-top: 0.5rem;
}

.pagesizeSm {
  display: none;
}

.sidebar-details-table {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.sidebar-details-table .details-table th {
  border-width: 2px;
  font-weight: 500;
  font-size: 13px;
}

.sidebar-details-table .details-table th,
.sidebar-details-table .details-table td {
  border-bottom: 1px solid #222 !important;
}

.form_deleteButtonRow button.custom-btn-green {
  width: auto;
  padding: 1rem !important;
  background: none !important;
}

.form_deleteButtonRow button.custom-btn-green:hover svg {
  right: 0.85rem;
  top: 0;
}

.d-sm-hidden {
  visibility: visible;
}

.d-md-hidden {
  display: none;
}

/* = Making Application Responsive 
------------------------------------------------------------ */
@media screen and (max-width: 1281px) {
  .main-responsive.col-xl-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .email-templates .RichEditor-root {
    padding: 1rem;
    height: 100%;
    min-height: 100vh;
  }
}

@media screen and (max-width: 768px) {

  .results-table-search table tbody td,
  .results-table-search table thead th {
    text-align: left;
  }

  .dashboard-header-wrapper {
    flex-wrap: wrap;
  }

  .results-table-search table tbody td a {
    word-break: normal;
    white-space: nowrap;
  }

  .email-templates .sb-navbar-top {
    margin-left: 0;
    background: black !important;
  }

  .sb-navbar-top {
    /* position: relative; */
    top: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* background: none !important; */
    border: none;
    padding: 0.4rem 0;
  }

  .sb-navbar-user-profile li {
    margin-bottom: 0 !important;
  }

  .sb-navbar-user-profile li a,
  .sb-navbar-user-profile li button {
    padding: 0.5rem;
  }

  .sb-navbar-user-profile li,
  .sb-navbar-user-profile li button {
    background: none !important;
  }

  .sticky-header {
    background: #222;
  }

  .sticky-header .custom-btn.custom-btn-black,
  .sticky-header .btn.custom-btn-dark {
    background: #4e4e4e;
  }

  .sticky-header .btn.btn-back {
    background: none !important;
    border: none !important;
  }

  .btn.nav-toggle {
    display: block;
    color: inherit;
    border: none;
    border-bottom: 1px solid #444;
    border-radius: 0;
    background: black;
    padding: 0.5rem;
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
    cursor: default;
    padding-left: 1rem;
  }

  .btn.nav-toggle svg {
    font-size: 16px;
    opacity: 0.8;
  }

  .sidebar {
    position: fixed;
    top: 0;
    bottom: auto;
    max-width: 100%;
    flex: 0 0 100%;
    padding-top: 0;
    height: fit-content;
    z-index: 1060;
    background: none !important;
  }

  .sidebar .nav-item svg {
    width: 20px;
    height: 20px;
  }

  .sidebar-sticky {
    height: 0;
    line-height: 0;
    color: transparent;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    background: black;
  }

  .sidebar-sticky.open {
    height: 100vh;
    min-height: 280px;
  }

  .sb-navbar-user-profile li .search-global {
    width: auto;
  }

  .sb-navbar-user-profile li.search-bar {
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-radius: 100px;
  }

  .main-container {
    top: 40px;
    width: 100%;
    margin: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .side-drawer.side-dialog {
    max-width: 100%;
    width: 100% !important;
    right: 0;
    left: 0;
    top: 30px;
    bottom: 0;
  }

  /* #activity-header {
    display: none;
  } */
  .row-sm-padding {
    padding-right: 0;
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
  }

  #activity-card-body {
    display: none;
  }

  #activity-card-root .counter {
    width: 60%;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    opacity: 0.5;
  }

  .tab-content .tab-pane>div {
    padding: 0 !important;
    margin-top: 1rem;
  }

  .btn-toolbar .icon-btn.btn-icon {
    width: 24px !important;
    height: 14px !important;
    line-height: 11px;
    background: black;
  }

  .detail-view-main,
  .detail-view-side {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
  }

  .detail-view-side {
    border-top: 1px solid #333;
    margin-top: 0.5rem;
  }

  .detail-view-main>div {
    padding: 0 !important;
    border: none !important;
    margin-bottom: 0 !important;
  }

  .details-side-responsive {
    display: flex;
    flex-wrap: wrap;
  }

  .details-side-section1,
  .details-side-section2,
  .details-side-section3,
  .details-side-section4 {
    flex-basis: 100%;
  }

  .details-side-section2 label {
    opacity: 0.8;
  }

  .past-activities-wrapper {
    padding-right: 0;
    margin-top: 0.5rem;
    border-bottom: 1px solid #333;
    margin-bottom: 0.5rem;
  }

  .email-templates .RichEditor-root {
    padding: 1rem;
    height: 100%;
    min-height: 100vh;
  }

  .RichEditor-controls {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .email-templates .modal-lg.modal-dialog-centered {
    overflow: scroll;
  }

  .customization-arrows {
    margin-top: 0.5rem;
  }

  .customization-arrows button.btn.custom-btn,
  .customization-arrows button.btn.custom-btn:hover {
    padding: 0.5rem;
    transform: rotate(90deg);
    border: 1px solid #999 !important;
    border-radius: 0;
  }

  .customization-arrows button.btn.arrow-down {
    margin-top: -4px;
    margin-right: 1rem;
  }
}

/* ------------------------ 480px----------------------------*/

@media screen and (max-width: 480px) {
  .d-sm-hidden {
    visibility: hidden;
  }

  .d-md-hidden {
    display: block;
  }

  .d-md-hidden .navbar-brand {
    margin: 0;
    padding-top: 0;
    position: absolute;
    top: 0.2rem;
    left: 40%;
  }

  .d-md-hidden .navbar-brand span {
    color: #80b602;
  }

  .custom-btn.custom-btn-green,
  .btn.btn-primary {
    background: #313821;
    color: #80b602;
    letter-spacing: 0.5px;
  }

  .sticky-header {
    position: relative;
  }

  .table-responsive .table tbody td {
    color: inherit;
    font-size: 14px;
    padding: 0.5rem;
  }

  .table-responsive .table thead th {
    padding: 0.5rem;
  }

  .details-side-responsive .table tbody td {
    color: #999;
    font-weight: 400;
  }

  .details-side-responsive .table tbody td:first-child {
    opacity: 0.8;
    font-weight: 400;
    font-size: 12px !important;
  }

  .sticky-header {
    top: 0;
  }

  .sidebar {
    position: fixed;
    top: 0;
    bottom: auto;
    z-index: 1061;
  }

  .sidebar .btn.btn.nav-toggle {
    border-color: #333;
  }

  .sidebar .sidebar-sticky {
    background: black;
  }

  .sidebar .sidebar-sticky.open {
    width: 100%;
    border-bottom: 1px solid #444;
  }

  .sidebar .sidebar-sticky .nav-sub-items {
    font-size: 16px;
    font-weight: 300;
  }

  .sidebar .sidebar-sticky .nav-sub-items .text-muted {
    color: inherit !important;
  }

  div.activity-cards-wrapper {
    flex-direction: column;
  }

  div.activity-cards-wrapper #activity-card-root {
    margin-bottom: 0.5rem;
  }

  .side-drawer.side-dialog {
    width: 100%;
    top: 2rem;
    padding-bottom: 2rem;
  }

  .sb-navbar-top .navbar-brand {
    text-align: center;
    margin: 0;

  }

  .activity-card-inner {
    padding: 0;
    margin: 0 1px;
  }

  .sb-navbar-top {
    flex-direction: column;
    justify-content: center;
    top: 0;
    padding: 0;
  }

  .main-container {
    top: 0;
  }

  #activity-card-root .counter,
  .activity-indicator-counter .counter {
    font-size: 14px;
    opacity: 1;
    font-weight: 300;
  }

  .activity-indicator-counter .counter {
    color: inherit;
  }

  #activity-card-root .count,
  .activity-indicator-counter .count {
    font-size: 32px;
  }

  .circle-content {
    display: none;
  }

  .table-title-n-search {
    flex-direction: column;
  }

  .table-extras {
    flex-direction: column;
  }

  /* #paginationLg {
    display: none;
  }
  #paginationSm {
    display: block;
  } */
  /* .custom-btn.custom-btn-green,
  .custom-btn.custom-btn-green:hover,
  .custom-btn.custom-btn-green:focus
  .btn.btn-primary,
  .btn.btn-primary:hover,
  .btn.btn-primary:focus {
    border: 1px solid #80b602 !important;
    background: none !important;
    color: #94d300 !important;
    width: auto !important;
    padding: 0.5rem !important;
    font-weight: 400 !important;
  }
   .btn.custom-btn.custom-btn-green:disabled {
    color: #94d300;
    opacity: 0.3 !important;
  }
  
  .side-drawer .sticky-header .custom-btn.custom-btn-black, 
  .side-drawer .custom-btn.custom-btn-black
   {
    border: 1px solid #666 !important;
    background: none !important;
    padding: 0.45rem 0.5rem !important;
    font-weight: 400;
  } */
  .table-title-n-search .search-bar {
    width: 100%;
    border: none;
    border-bottom: 1px solid #444;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-top: 0.5rem;
  }

  .custom-btn.btn-sm {
    font-size: 0.8rem;
  }

  .count {
    color: #f5f5f5;
  }

  .navbar-brand,
  .sb-navbar-user-profile {
    /* flex: 0 0 100%; */
    max-width: 100%;
    position: relative;
  }

  .user-text {
    display: none;
  }

  .sb-navbar-user-profile {
    /* justify-content: center;
    position: absolute;
    right: 0; */
    background: black !important;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #222;
    padding-top: 0.5rem;
    margin-top: -3px;
  }

  .sb-navbar-user-profile li {
    border: none;
    background: #222;
    border-radius: 4px;
    margin: 0 0.2rem 0.5rem 0.2rem;
  }

  .delete-request-root .nav-tabs {
    padding: 0 !important;
    /* display: flex;
    flex-direction: column; */
    flex-wrap: nowrap;
    /* border-bottom: 1px solid #444444; */
  }

  .delete-request-root .nav-tabs .nav-item {
    padding: 0.3rem !important;
    background: none !important;
    font-size: 12px;
    text-align: center;
    color: #999;
    line-height: 1rem;
    margin: 0;
  }

  .delete-request-root .nav-tabs .nav-item svg {
    display: none;
  }

  .delete-request-root .nav-tabs .nav-item.active {
    border-radius: 0;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  #prospect-detail-header {
    border-bottom: 2px solid #444;
  }

  .selectWrap {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .selectWrap button.custom-btn-green {
    width: 100%;
    /* padding: 1rem; */
    margin-top: 0.5rem;
  }

  .selectWrap>div {
    max-width: 100%;
    justify-content: flex-start;
    margin: 0 !important;
  }

  .selectWrap .btn-container {
    margin-top: 1rem !important;
  }

  .form_deleteButtonRow button.custom-btn-green {
    width: auto;
    padding: 1rem !important;
    background: none !important;
  }

  /* .selectWrap .btn-container button {
    width: 100% !important;
    padding: 0.5rem;
  } */
  .rbc-toolbar-custom {
    flex-direction: row;
    margin-top: 0;
  }

  .calendar-header-filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid #333;
    padding-top: 0.5rem;
    border-bottom: 1px solid #333;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.1rem;
  }

  .detail-view-side .card-body {
    padding: 0 !important;
  }

  .detail-view-side table th {

    font-weight: 800 !important;
    border-bottom: 1px solid #222 !important;
  }

  .details-side-section1 td:first-child,
  .details-side-section2 td:first-child,
  .details-side-section3 td:first-child,
  .details-side-section4 td:first-child {
    padding: 0.6rem 0.5rem 0.2rem 0 !important;
    color: #888 !important;
    font-size: 14px !important;
    letter-spacing: 0;
  }

  .details-side-section1 td,
  .details-side-section2 td,
  .details-side-section3 td,
  .details-side-section4 td {
    display: block;
    width: 100% !important;
    padding: 0.2rem 0.5rem 0.2rem 0 !important;
    font-size: 14px !important;
  }

  .past-activities-header-controls {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between !important;
    flex-direction: row-reverse;
  }

  .past-activities-filters {
    display: flex;
  }

  .notes-header {
    flex-direction: column;
    border-bottom: 2px solid #444;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .notes-header .notes-controls {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: flex-start !important;
  }

  .notes-header .notes-controls>div {
    justify-content: flex-start;
  }

  .custom-table-title {
    width: 100% !important;
  }

  .btn-toolbar {
    flex-wrap: inherit;
    /* align-items: center; */
  }

  .btn-toolbar .custom-btn-green {
    /* padding: 0.4rem 1rem !important; */
    margin-top: 0 !important;
  }

  #other-customization-btn-col {
    /* width: 25px; */
  }

  .customization-arrows {
    margin-top: 0.5rem;
  }

  .customization-arrows button.btn.custom-btn,
  .customization-arrows button.btn.custom-btn:hover {
    padding: 0.5rem;
    transform: rotate(90deg);
    border: 1px solid #999 !important;
    border-radius: 0;
  }

  .customization-arrows button.btn.arrow-down {
    margin-top: -4px;
    margin-right: 1rem;
  }

  #admin-other-customizations-root .m-3,
  #admin-other-customizations-root .m-3 .toolbarSelect {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .email-templates .overlay-backdrop {
    justify-content: flex-start;
  }

  .email-templates .selectWrap {
    flex-direction: row;
  }

  .email-templates .selectWrap .col-padding {
    padding-left: 0 !important;
  }

  /* .email-templates .modal-dialog {
    margin: 0;
  }
  .email-templates .modal-content, .email-templates .modal-body {
    padding: 0;
  } */
  .dialog {
    z-index: 9999;
  }

  .modal-content {
    width: 100%;
    box-shadow: 0 10px 10px rgba(40, 40, 40, 1);
  }

  .flex-sm-column-custom {
    flex-direction: column;
  }

  .flex-sm-column-custom #text-editor-panel {
    margin-top: 1rem;
  }

  .table-wrapper-dark {
    padding-bottom: 6.2rem;
    min-height: auto;
  }

  .pagination-wrapper {
    justify-content: center !important;
    margin-bottom: 0.5rem;
    flex-direction: column-reverse;
  }

  .card-web-form .card-inner {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .data-actions button.btn.custom-btn,
  .data-actions a {
    width: auto;
    padding: 0.1rem 0.4rem !important;
  }

  .data-actions svg {
    width: 18px;
    height: 18px;
  }

  .paginationLg {
    display: none !important;
  }

  .pagesizeSm {
    display: inline-block;
    position: absolute;
    right: 0.5rem;
  }

  .html-editor-switch {
    right: 0.5rem;
  }

  .version-details {
    margin-left: 1.3rem;
  }

  .list-body {
    line-height: 1.2rem;
    justify-content: start;
    align-items: self-start;
  }

  .flex-parent {
    flex-direction: column;
  }

  .version-title {
    margin-left: 1.4rem;
    margin-top: 1rem;
  }

  .list-title {
    padding-left: 0;
    min-width: 80px;
    display: flex;
    align-items: center;
  }

  .versions-wrapper {
    margin-top: 1rem;
  }

  /* merge feature */
  .merge-form {
    width: auto;
    margin: 5px 0 !important;
  }

  .modal-98w {
    width: auto;
  }

  .dialog-merge .details-card {
    width: auto;
    margin: 5px 0;
  }

  .dialog-merge {
    flex-direction: column;
    margin: 0;
    width: auto !important;
  }

  .merge-form .form_TitleContainer h2,
  .dialog-merge .card-header {
    font-size: 16px;
  }

  .merge-form .form_TitleContainer {
    flex-direction: column;
  }

  .dialog-merge .card {
    margin: 5px 0;
  }

  /* = CSM availability */

  .schedule-card table td {
    display: block !important;
  }

  .chip-body .chip-text-align {
    flex-direction: column;
  }

  .schedule-card .table-root tr td:first-child,
  .schedule-card .table-root tr td:last-child {
    width: 100%;
  }
}

/* Responsive ENDS */

.drawer-open {
  overflow: hidden;
}

.form-group {
  outline: none;
}
