/* simple popup list styles*/

.toolbarSelect {
  /* width: 200px; */
  color: white;
  padding: 0;
}
.list-card {
  display: flex;
}
.details-list {
  background-color: rgb(18, 19, 21);
  width: unset !important;
  height: unset !important;
  padding: 0.5rem !important;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}
.details-list .popover {
  width: unset;
  height: unset;
}

/* .details-list div:first-child{
  background-color: rgb(18, 19, 21);
  color: #80B602;
  margin: 0rem 0px 0.5rem 0;
} */

.details-list div {
  padding: 0.1rem 0.5rem;
  font-size: 12px;
}

/* .list-first-item {
  padding: 0 !important;
  display: flex;
} */

/* selection list styles*/

.list-popover {
  position: relative;
  background: black;
  border: 1px solid #444;
  margin-top: 5px;
  /*   margin-top: 0.9rem;
  margin-left: 0.2rem;*/
  z-index: 9999;
}
.list-popover .popover-body {
  padding: 0;
}
.list-group {
  max-height: 200px;
  overflow: auto;
}

.list-group-padding .list-item{
  padding-left: 1.5rem !important;
}

.list-item {
  background: black;
  padding: 0.3rem 0.8rem;
  color: #d8d8d8;
  font-size: 0.9em;
}

.list-item-label{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  opacity: 0.5;
}

.list-group .list-item:hover {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #80b602;
  cursor: pointer;
}
.label-selected {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  width: fit-content;
  margin-top: 0.4rem;
}
.label-selected h3 {
  margin-left: 0;
  margin-right: 0.5rem;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0.3rem;
  opacity: 0.8;
}
.label-selected svg {
  font-size: 16px;
  position: relative;
  align-self: flex-end;
}
