/* delete requests styles*/

.tabs {
  border: none;
}
.delete-request-root .nav .nav-item {
  background: none !important;
  border: none;
  color: #838383;
  padding-right: 0;
  padding-left: 0;
  margin-left: 1rem;
  margin-right: 1rem;

}
.delete-request-root .nav .nav-item.active {
  background: none !important;
  border: none;
  border-bottom: 1px #80b602 solid !important;
  color: #80b602
}

/*update section styles*/

.custom-btn-arrows {
  font-size: 12px;
}
.custom-btn-arrows svg {
  width: 12px;
  height: 12px;
  margin: 0px;
}
.left-right-arrows button svg {
  margin: 0;
  padding: 0;
}
.custom-btn-arrows:disabled {
  background-color: #191b1d !important;
}
.custom-btn-arrows:disabled svg {
  color: white !important;
}
.left-right-arrows button.btn.custom-btn:hover {
  border: none !important;
}

/*more customization section styles*/

/* .more-customizations-wrapper {
  height: 65px;
} */
/* .more-customizations-wrapper label {
  font-size: 12px;
}*/
.more-customizations-wrapper div {
  display: flex;
}
.more-customizations-wrapper button {
  border: none !important;
  background: none !important;
  padding: 0.2rem 0.5rem;
} 
.more-customizations-wrapper button svg {
  color: #80b602;
}
/* Email template styling */

.overlay-emailtemplate .icon_backdrop {
  max-height: inherit;
  position: relative;
  right: 9rem;
}
.overlay-emailtemplate .message_backdrop {
  font-size: 1rem;
}

.template-editor {
  margin: -1rem !important;
}

.cutomization-text button{
  margin-right: 5px; 
}

.cutomization-text button svg{
  margin: 0px
}
/* .custom-btn svg {
  padding: 0;
  margin: 0 !important; 
} */
.webform-email-association-controls {
  margin-left: 3rem;
}
.webform-email-association-controls .btn.btn-secondary {
  background: none !important;
  padding: 0.2rem !important;
  color: #666 !important;
  border: none!important;
}
.webform-email-association-controls .btn.btn-secondary:hover {
  color: #80b602 !important;
}
#admin-email-associations-root  .custom-btn svg {
  margin: 0;
}
.list-item {
  padding: 0.1rem 0.5rem !important;
}
.webform-status-selection label {
  opacity: 1;
  font-size: 12px;
  margin-bottom: 0;
  cursor: pointer;
}

.customization-box {
  margin: 1em 0;
  background-color: #181a1d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1.5rem;
}
.table-compact {
  margin: 1.5rem 0 2rem 0;
}
.table-compact .table-root table thead tr td {
  background: none;
  border-bottom: 2px solid #333;
  padding: 0.5rem 0.2rem;
  border-top: none !important;
  opacity: 0.5;
}
.table-compact .table-root table tr td  {
  padding: 0.2rem !important;
  padding-bottom: 0.5rem !important;
  vertical-align: middle !important;
}
.customization-box .label-selected {
  border: 1px solid #444;
    border-radius: 4px;
    padding: 0.3rem 0.5rem;
    background: black;
}
.customization-box .label-selected h3 {
  font-size: 12px;
    margin: 0 0.5rem 0 0;
    color: #999;
    opacity: 1;
}
.osc-selector {
  opacity: 0.7;
}
.list-popover .list-item {
  padding: 0.2rem 0.5rem;
}
.auto-reply-customization .other-customization-card-root {
  padding: 0;
}
/* .auto-reply-customization .other-customization-card-root #all-other-customization-col {
  width: 100% !important;
} */
.other-customization-card-root .options-item {
  border: none;
  border-radius: 0;
  padding: 0.3rem;
}

.scrollable-table {
  max-height: 200px;
  overflow: auto;
  margin-bottom: 0.5rem;
}

.custom-btn.sources-add-btn svg {
  margin-right: 0px;
}

.customization-box.customer-source-tag {
  padding: 1rem;
}

.category-header {
  font-size: 18px;
  line-height: 19px;
  width: fit-content;
  padding-bottom: 4px;
  margin: 27px 0px 17px 0px;
  border-bottom: 1.2px solid #73A405
}

.dropzone-container {
  border: 1px dashed #3C3A3A;
  border-radius: 5px;
  background-color: #121415;
}

.dropzone-container .content {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropzone-container .icon {
  margin: 20px 40px;
  width: 71px;
}

 .content .title{
  font-size: 16px;
  color: #908F8F;
  line-height: 19px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.add-btn {
  width: 170px;
  margin-bottom: 5px;
}

.sample-btn {
    width: 170px;
    margin-bottom: 15px;

}

.dropzone-container .sub {
  margin: 0px;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.3;
}

.action-container {
  display: flex;
  justify-content: flex-start;
  margin: 32px 0px;
}

.dropzone-container .selected-content {
  width: 224px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background: black;
  border: 1px solid #3E494E;
  border-radius: 4px;
}

.dropzone-container .selected-content .file-name {
  font-size: 14px;
  color: #80b602;
  line-height: 17px;
  margin-left: 8px;
  word-break: break-all;
}

.dropzone-container .selected-title {
  align-items: center;
}

.read-only-item {
  padding-top: 0.2rem;
}

@media screen and (max-width: 480px) {
  .tabs {
    display: block;
    border: none;
  }
}