.details {
    white-space: pre-wrap;
    padding: 1rem;
    background: #333;
    line-height: 1.2;
    font-size: 12px;
    border-left: 3px solid red;
    margin-top: 1rem;
    font-family: monospace;
}
h1 {
    color: white;
}