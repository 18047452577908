.root-row {
  padding: 0.6rem 0;
  border-bottom: 2px solid #191b1d;
  margin-left: 0;
  margin-right: 0px;
}

.row-container {
  margin-left: 0;
  margin-right: 0;
}

.tasks-container {
  height: 50%;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
}
#bootstrap-overrides div {
  margin-right: 0px; /* priority score, yay! */
}

button.btn-back {
  width: 34px;
    height: 32px;
    /* text-align: center; */
    /* display: flex; */
    background: rgba(255, 255, 255, .2) !important;
    padding: 0 !important;
    border-radius: 50px;
    margin-right: 0.5rem !important;
    border: none !important;
    box-shadow: none !important;
    margin-left: 0 !important;
}
button.btn-back svg {
  margin: 0 0 0 -2px !important;
  font-size: 14px;
}
.select-bar-back-btn{
  border-radius: 50%;
}
.table-prospects td:first-child {
  width: 36px;
  padding-right: 0;
}
.table-prospects tr td:nth-child(2) {
  width: 24px;
  padding-left: 0;
  padding-right: 0;
}
.table-prospects tr th:nth-child(3),
.table-prospects tr td:nth-child(3) {
  padding-left: 0.3;
}
