.RichEditor-root {
  background: #efefef;
  padding: 3em 10em;
  font-family: inherit;
  font-size: 14px;
  word-break: break-word;
}

.RichEditor-editor-mass-email {
  display: flex;
  cursor: text;
  font-size: 14px;
  min-height: 250px;
  margin-top: 15px;
  word-break: break-word;
}

.RichEditor-editor {
  display: flex;
  cursor: text;
  font-size: 14px;
  min-height: 480px;
  max-width: none;
  margin-top: -5px;
}
.RichEditor-editor *,
.editor-sections-panel * {
  color: black;
}
.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls-container {
  min-height: 30px;
  position: relative;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}
.RichEditor-controls .dropdown-menu.show {
  width: 100%;
}
.RichEditor-styleButton {
  color: #999;
  margin: 0rem 0rem 0rem 0.25rem !important;
  height: 20px !important;
  border-radius: 15% !important;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  width: 100% !important;
  background: none !important;
  text-align: start !important;
}

.RichEditor-activeButton {
  color: #5890ff;
}
.custom-popover {
  color: #999;
  background-color: #191b1d;
  width: 270px;
  height: 131px;
  padding: 0.5rem !important;
}

.text-preview-panel {
  display: flex;
}

.text-editor-panel {
  min-height: 95.2%;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
  /* padding: 10px; */
  background: white;
  color: #292d30;
  position: relative;
}

div.vertical-line {
  border-left: 1px solid #80b602;
  height: 20px;
  margin-right: 8px;
  margin-left: 8px;
}

.DraftEditor-root ol li,
.DraftEditor-root ul li {
  list-style: inherit;
  padding-left: 0.2rem;
  line-height: 1rem;
}

.DraftEditor-root ol,
.DraftEditor-root ul {
  padding-left: 1.5rem;
}
.DraftEditor-root ol {
  padding-left: 1.85rem;
}
.DraftEditor-root ul {
  list-style: inherit;
}

code {
  color: inherit !important;
}

.code-preview-panel {
  background: white;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.public-DraftEditor-content > div {
  line-height: 1.2rem;
}
.html-editor-switch {
  z-index: 10;
  position: relative;
  right: 0;
  /* margin-left: 1rem; */
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
}

.html-editor-switch button {
  border: none;
  padding: 0.5rem;
  border-radius: 50px;
  font-size: 18px;
  width: 32px;
  height: 32px;
  line-height: 0;
  text-align: center;
}
.html-editor-switch button svg {
  color: #666;
}
.rtd-wrapper {
  padding-top: 0.5rem;
}
.CodeMirror {
  height: 530px;
  margin-top: 2.5rem;
  border-top: 1px solid #eee;
}

.alignment--left .public-DraftStyleDefault-block {
  text-align: left;
}

.alignment--center .public-DraftStyleDefault-block {
  text-align: center;
}
.alignment--right {
  text-align: right;
}

.align-right div {
  text-align: right;
}
.align-center div {
  text-align: center;
}
.align-left div {
  text-align: left;
}
.override .test {
  /*   flex: unset;
  max-width: initial; */
}
.editor-sections-panel {
  padding: 10px;
  min-height: 620px;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
  background: white;
  /*  margin-left: 30px; */
}

.section-wrapper {
  height: inherit;
  border: 2px dashed rgb(209, 208, 208);
}

.section-wrapper:hover {
  border: 2px solid #80b602;
}
.RichEditor-controls-container .RichEditor-styleButton {
  background: none !important;
  text-align: center;
  line-height: 12px;
  /* color: #666; */
}
.email-templates .btn.btn-primary {
  background: none;
  border: none;
}
.email-templates .btn.btn-primary:hover,
.RichEditor-controls-container .RichEditor-styleButton:hover {
  color: #80b602;
}
.email-templates .btn.btn-primary:focus {
  color: #666;
  box-shadow: none;
}
.DraftEditor-root {
  padding: 0 1rem;
}
#logo-image,
#body-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
}
.email-templates .dialog {
  padding: 0 !important;
}
.email-templates .modal-lg.modal-dialog-centered {
  width: 100%;
  max-width: 100%;
  margin: 0;
}
.email-templates .modal-body * {
  color: inherit;
}
/* .email-templates .dialog .subject-section-preview {
  display: none !important;
} */
.modal-dialog.modal-email-preview {
  max-width: fit-content !important;
}
.modal-dialog.modal-email-preview .modal-content
{
  padding: 0;
  background: 0;
  box-shadow: none;
  border: none;
}
.modal-dialog.modal-email-preview .modal-body {
  padding: 0;
}
.subject-section-preview p{
  line-height: 1;
} 
.email-templates .dialog {
  background: rgba(0, 0, 0, 0.7);
}
.email-templates .text-preview-wrapper {
  margin: 0 auto;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
}
#body-text,
#body-header,
#body-link {
  /* padding: 1rem; */
}
.email-templates .overlay-backdrop {
  background: rgba(0, 0, 0, 0.8) !important;
}
.email-templates .temp-controls button.btn-secondary {
  background: #d4d4d4;
  border: 1px solid #bbb;
  color: black;
}
.email-templates .temp-controls button.btn-secondary:hover {
  border: 1px solid #80b602;
  background: #80b602;
}
.email-templates .temp-controls button.btn-icon {
  background: rgba(0, 0, 0, 0.2);
  color: #666;
}
.email-templates .temp-controls button.btn-icon:hover {
  background: #80b602;
  color: black;
}
.email-templates .temp-controls button.btn-icon svg {
  font-size: 16px;
  color: black;
}
.email-templates .btn-group .btn.btn-secondary {
  border-color: rgba(255, 255, 255, 0.15);
}
.section-header {
  font-size: medium;
  color: #6c757d;
}

.selected-section {
  border: 2px solid #80b602;
}
.section-wrapper .subject p {
  margin: 0.4rem 0;
}

.error-section {
  border: 1px solid red;
}

.bullets-li {
  list-style: square !important;
}

.tooltip-description {
  text-align: left !important;
}
.active-allignment {
  background-color: black !important;
}
button.btn-tokens {
  background: orange !important;
  font-weight: bold;
  margin-left: 1rem;
  margin-right: 0.5rem;
}
button.btn-tokens:hover {
  color: black !important;
}
div.list-tokens a.dropdown-item {
  padding: 0.2rem 0.5rem;
}
div.list-tokens a.dropdown-item span {
  color: #ddd;
  white-space: normal;
  display: flex;
  align-items: center;
}
div.list-tokens a.dropdown-item:hover span {
  color: orange !important;
}
button.btn-tokens:focus {
  color: black !important;
}
.public-DraftEditor-content {  
  caret-color: black;
}
