.activity{
    /* padding: 0rem 3rem 1rem 3rem; */
    height: 100% ;
    /* overflow: scroll */
  }

   .appointment-button-active {
    background:none !important;
    margin: 0rem 0.5rem 1rem 0rem;
    color: rgb(201, 201, 201) !important;
    border-left:none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom:1px #80B602 solid!important;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
  }
  
   .appointment-button-active svg {
     background:none !important;
     color: rgb(201, 201, 201) !important;  
    }
   .appointment-button {
    background:none !important;
    margin: 0rem 0.5rem 1rem 0rem;
    color:  rgb(105, 105, 105) !important;
    border: none !important
  }
   .appointment-button svg {
     background:none !important;
     color: rgb(105, 105, 105) !important;
   }