.table-root {
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
}
.table-root table {
  margin-bottom: 0;
}
.table-root table tr td {
  padding: 0.5rem !important;
  border: none;
  line-height: 1.3;
  /* vertical-align: top; */
}
.table-row {
  line-height: 0.7rem;
}
.table-col {
  padding: 0.3rem !important;
  min-width: 7rem;
}
.table-activities tr td {
  border-top: 1px solid #272727 !important;
}
/* .table-activities table tr td {
  color: inherit;
} */