.root-row {
  padding: 0.6rem 0;
  border-bottom: 2px solid #191b1d;
  margin-left: 0;
  margin-right: 0px;
}
.row-container {
  margin-left: 0;
  margin-right: 0;
}
.notes-list-row {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}
.past-activity-dropdown {
  margin: 0rem 0.5rem 0 0;
}
.communities-collection{
  display:flex;
  float:right;
  padding-right: 3rem;
  padding-top: 2px;
}
.popover-group-events-calendar {
  padding: 1rem;
  width: 400px;
}
@media screen and (max-width: 480px) {
  .popover-group-events-calendar {
    max-width: 300px;
  }
}