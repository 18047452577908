.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    padding: 0 0.5rem 0.5rem 0.5rem;
    opacity: 0.8;
}
.position-absolute {
    position: absolute;
    bottom: 0;
    right: 0;
}
.pagination-wrapper button {
    background: none;
    color: inherit;
    border: 1px solid #444;
    padding: 0.5rem;
}
.pagination-wrapper button:hover {
    opacity: 1;
    color: #80B602;
    background: rgba(0, 0, 0, .9);
    cursor: pointer;
    border-radius: 4px;
}
.pagination-wrapper span {
    margin: 0 0.2rem;
    display: inline-block;
    white-space: nowrap;
}
.pagination-wrapper input, .pagination-wrapper select {
    background:rgba(0, 0, 0, .9);
    border-radius: 4px;
    color: inherit;
    text-align: center;
    border: 1px solid #444;
    padding: 5px;
}
.pagination-wrapper select {
    max-width: 120px;
    align-self: flex-end;
    border-radius: 0;
    padding: 0.5rem 1rem 0.5rem 0.3rem;
}